import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import Logo from '../images/logo/logo.svg';
import SidebarLinkGroup from "./SidebarLinkGroup";
import { Logo, UserProfileIcon } from "../utils/svg";
import SideBar from "./Common/SideBar";
import { SideBarData } from "./Data/SideBar";
import ButtonComp from "./Ui/Button";
import DropDownComp from "./Ui/DropDown";
import { HiDotsVertical } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { getLanguage,setLanguage, storage } from "../utils/helper";
import { useDispatch } from "react-redux";
import { adminApi } from "../store/admin/admin";
import { authApi } from "../store/auth/authApi";
import { userApi } from "../store/User/userApi";
import { DashBoardApi } from "../store/DashBoard/DashBoardApi";
import { ProductApi } from "../store/Product/ProductApi";
import { OrderApi } from "../store/Order/OrderApi";
import { CustomerApi } from "../store/Customer/CustomerApi";
import { SchedulesApi } from "../store/Schedules/SchedulesApi";
import { EventApi } from "../store/Event/EventApi";
import { MediaApi } from "../store/Media/MediaApi";
import { CallReportApi } from "../store/CallReport/CallReportApi";
import { MedRepApi } from "../store/MedRep/MedRepApi";
import { PlansApi } from "../store/Plans/PlansApi";

// interface SidebarProps {
//   sidebarOpen: boolean;
//   setSidebarOpen: (arg: boolean) => void;
// }

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setShowNotification,
  showNotification,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const userDetails = storage["localStorage"].get("user_details_profile")?.user;
  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  const hide = sidebarOpen ? "!hidden" : "";

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });
  useEffect(() => {
    i18n.changeLanguage(getLanguage());
  }, []);

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <aside
      ref={sidebar}
      className={` left-0 top-0 z-9999 flex h-screen w-[120px] xl:w-[350px] flex-col overflow-y-hidden  duration-300 ease-linear dark:bg-boxdark static lg:translate-x-0 border-[0.5px] border-r ${
        sidebarOpen ? "translate-x-0 !w-[120px]" : "translate-x-0"
      }`}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div
        className={` hidden xl:flex items-center justify-between gap-2 mx-2 px-6 py-10 lg:py-6.5 border-b-[1px]  border-[#f0f0f6] `}
      >
        <NavLink className={hide} to="/overview">
          <Logo />
          {/* <img src={'/Images/noUser.jpg'} alt="Logo" /> */}
        </NavLink>

        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block"
        >
          <svg
            className="fill-current"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear customScrollHorizontal">
        {/* <!-- Sidebar Menu --> */}
        <nav className=" py-4 px-4 lg:mt-9 lg:px-6">
          {/* <!-- Menu Group --> */}
          <div>
            <ul className="mb-6 flex flex-col gap-1.5 p-0 m-0">
              {/* <!-- Menu Item Dashboard --> */}
              {SideBarData()?.map((item, i) =>
                item?.plus ? (
                  <li className="mb-3">
                    <SidebarLinkGroup
                      activeCondition={
                        pathname === item?.path || pathname.includes(item?.path)
                      }
                    >
                      {(handleClick, open) => {
                        return (
                          <React.Fragment>
                            <NavLink
                              to="#"
                              className={`text-[18px] group relative flex items-center  gap-4 rounded-sm py-2 px-xl-4 font-medium text-[#333333] duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                (pathname === item?.path ||
                                  pathname.includes(item?.path)) &&
                                "bg-graydark dark:bg-meta-4"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded
                                  ? handleClick()
                                  : setSidebarExpanded(true);
                              }}
                            >
                              {item?.icon}
                              <span className={`hidden xl:block ${hide}`}>
                                {" "}
                                {item?.name}
                              </span>
                              <span className={`hidden xl:block ${hide}`}>
                                {!open ? (
                                  <svg
                                    className="absolute right-4 top-1/2 -translate-y-1/2 fill-current"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 6H11"
                                      stroke="#333333"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M6 11V1"
                                      stroke="#333333"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    className="absolute right-4 top-1/2 -translate-y-1/2 fill-current"
                                    width="12"
                                    height="2"
                                    viewBox="0 0 12 2"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 1H11"
                                      stroke="black"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                )}
                              </span>
                            </NavLink>
                            {/* <!-- Dropdown Menu Start --> */}
                            {/* hidden lg:block */}
                            <span className="">
                              <div
                                className={`translate transform overflow-hidden xl:ms-5   ${
                                  !open && "hidden"
                                }`}
                              >
                                <ul className="mt-2 !text-[#555555] mb-5.5 flex flex-col gap-2.5 pl-1 border-l-[1px] border-[#f6f6f0]">
                                  {item?.subItem?.map((itemII, i) => (
                                    <li className="">
                                      <NavLink
                                        to={itemII?.path}
                                        className={({ isActive }) =>
                                          "text-[15px] group relative flex items-center gap-2.5 rounded-md px-xl-4 font-medium !text-[#555555] duration-300 ease-in-out hover:text-black py-[8px] hover:bg-[#F5F0F8] " +
                                          (isActive &&
                                            "!text-black bg-[#F5eff8]")
                                        }
                                      >
                                         {itemII?.icon}
                                        <span className="hidden xl:block">{itemII?.name}</span>
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </span>
                            {/* <!-- Dropdown Menu End --> */}
                          </React.Fragment>
                        );
                      }}
                    </SidebarLinkGroup>
                  </li>
                ) : (
                  <li className="mb-3">
                    <NavLink
                      to={item?.path}
                      className={` text-[16px] group relative flex items-center gap-4 rounded-sm py-2  px-xl-4 font-medium text-black duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                        pathname.includes(item?.path) && "bg-[#F5eff8]"
                      }`}
                    >
                      <div className=" ">
                      {item?.icon}
                      </div>

                      <span className={`hidden xl:block ${hide}`}>
                        {" "}
                        {item?.name}
                      </span>
                    </NavLink>
                  </li>
                )
              )}

              {/* <!-- Menu Item Dashboard --> */}

              {/* <!-- Menu Item Calendar --> */}
            </ul>
          </div>

          {/* <!-- Others Group --> */}
          <div className={`pt-[50px] block xl:hidden ${sidebarOpen?'!block':''}`}>
            <div className=" h-[0px] mb-[64px]"></div>
            <div className="flex  items-center gap-4 mb-[32px]">
              <div className="flex  items-center gap-2">
                <img src="/icon/user.png" className="w-[40px]" />
                <div></div>
              </div>
            </div>

            <div className="py-[7px] flex justify-between  gap-3">
              <ButtonComp
              
                text={"English"}
                btnClassName={
                  "w-full bg-[#EDE6F3] text-black col-12 text-[14px] py-[10px]  font-medium"
                }
                wrapper={"flex-1 "}
              />
            </div>

            <div className=" pt-[155px]" style={{ height: "20vh" }}></div>
          </div>


          <div className={`pt-[50px] hidden xl:block ${sidebarOpen?'!hidden':''}`}>
            <div className=" h-[0px] mb-[64px]"></div>
            <div className="flex  items-center gap-4 mb-[32px]">
              <div className="flex  items-center gap-2">
                <img src="/icon/user.png" className="w-[40px]" />
                <div>
                  <div className="text-[14px] text-[#6A6A6A] ">
                    Administrator
                  </div>
                  <div className="font-medium text-ellipsis">{userDetails?.firstName} {userDetails?.lastName}</div>
                </div>
              </div>
              <div
               
                className="flex items-center gap-2 cursor-pointer"
              >
                <div  onClick={() => {
                  scrollTo(0,0);
                  setShowNotification(true)
                }}>
                <svg
                  width="21"
                  height="24"
                  viewBox="0 0 21 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.1689 7.94238V11.1715"
                    stroke="#555555"
                    stroke-width="1.33333"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M10.1882 3.63672C6.61967 3.63672 3.72997 6.52642 3.72997 10.0949V12.1313C3.72997 12.7907 3.45845 13.7797 3.11906 14.3422L1.88755 16.3979C1.13118 17.6682 1.65482 19.084 3.05118 19.5494C7.68633 21.0913 12.6997 21.0913 17.3348 19.5494C18.6439 19.1131 19.2063 17.581 18.4985 16.3979L17.2669 14.3422C16.9275 13.7797 16.656 12.781 16.656 12.1313V10.0949C16.6463 6.54581 13.7372 3.63672 10.1882 3.63672Z"
                    stroke="#555555"
                    stroke-width="1.33333"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M13.3976 19.9468C13.3976 21.7213 11.9431 23.1759 10.1685 23.1759C9.28612 23.1759 8.47157 22.8074 7.88976 22.2256C7.30794 21.6437 6.93945 20.8292 6.93945 19.9468"
                    stroke="#555555"
                    stroke-width="1.33333"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M15.6893 9.10222C18.2028 9.10222 20.2404 7.06462 20.2404 4.55111C20.2404 2.0376 18.2028 0 15.6893 0C13.1758 0 11.1382 2.0376 11.1382 4.55111C11.1382 7.06462 13.1758 9.10222 15.6893 9.10222Z"
                    fill="#3DA84A"
                  />
                </svg>
                </div>
                <DropDownComp
                  dropDownText={<HiDotsVertical />}
                  options={[
                    {
                      name: "Log Out",
                      action: () => {
                      
                        dispatch(adminApi.util.resetApiState());
              dispatch(authApi.util.resetApiState());
              dispatch(userApi.util.resetApiState());
              dispatch(DashBoardApi.util.resetApiState());
              dispatch(ProductApi.util.resetApiState());
              dispatch(OrderApi.util.resetApiState());
              dispatch(CustomerApi.util.resetApiState());
              dispatch(SchedulesApi.util.resetApiState());
              dispatch(EventApi.util.resetApiState());
              dispatch(MediaApi.util.resetApiState());
              dispatch(CallReportApi.util.resetApiState());
              dispatch(MedRepApi.util.resetApiState());
              dispatch(PlansApi.util.resetApiState());
              navigate("/login");
              // dispatch(logout())
                      },
                    },
                  ]}
                />
              </div>
            </div>

            <div className="border-[1px] border-[#E5E5EF] px-[9px] py-[7px] flex justify-between  gap-3">
              <ButtonComp
               onClick={() => {
                changeLanguage("en");
                setLanguage("en");
              }}
                text={"English"}
                btnClassName={
                  "w-full bg-[#EDE6F3] text-black col-12 text-[14px] py-[10px]  font-medium"
                }
                wrapper={"flex-1 "}
              />
              <ButtonComp
               onClick={() => {
                changeLanguage("fr");
                setLanguage("fr");
              }}
                text={"French"}
                btnClassName={
                  "w-full bg-[#ffff] py-[10px] text-[#757575] col-12 text-[14px] font-medium"
                }
                wrapper={"flex-1 "}
              />
            </div>

            <div className=" pt-[155px]" style={{ height: "20vh" }}></div>
          </div>

        </nav>
        {/* <!-- Sidebar Menu --> */}
      </div>
    </aside>
  );
};

export default Sidebar;
