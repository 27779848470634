import { t } from "i18next";
import Cookie from "js-cookie";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("user:accesskey");
  }
};

export const setLanguage = (token) => {
  localStorage.setItem("language", token);
};
export const getLanguage = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("language");
  }
};

export const LanguageName = (name) => {
  return t(`lang.${name}`);
};

export function LanguageNameII() {
  const { t } = useTranslation();
  return t(`lang.${name}`);
}

// export const storage = {
//   localStorage: {
//     set: (key, value) => localStorage?.setItem(key, JSON.stringify(value)),
//     get: (key) =>
//       typeof localStorage?.getItem(key) === "string" &&
//       JSON?.parse(localStorage?.getItem(key) || ""),
//     remove: (key) => localStorage?.removeItem(key),
//   },
//   cookieStorage: {
//     set: (key, value, options) => Cookie?.set(key, value, options),
//     get: (key) => Cookie?.get(key) || "",
//     remove: (key, options) => Cookie.remove(key, options),
//   },
// };
export const storage = {
  localStorage: {
    set: (key, value) => localStorage?.setItem(key, JSON.stringify(value)),
    get: (key) => {
      const item = localStorage?.getItem(key);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
          return null; // or a sensible default
        }
      }
      return null; // or a sensible default if the item doesn't exist
    },
    remove: (key) => localStorage?.removeItem(key),
  },
  cookieStorage: {
    set: (key, value, options) => Cookie?.set(key, value, options),
    get: (key) => Cookie?.get(key) || "",
    remove: (key, options) => Cookie?.remove(key, options),
  },
};

export const setToken = (token) => {
  localStorage.setItem("user:accesskey", token);
};
export const DeleteAuthTokenMaster = (name) => {
  localStorage.removeItem(name);
};

export const YearMonth = () => {
  const { t } = useTranslation();

  return [
    t("lang.Jan"),
    t("lang.Feb"),
    t("lang.Mar"),
    t("lang.Apr"),
    t("lang.May"),
    t("lang.June"),
    t("lang.July"),
    t("lang.Aug"),
    t("lang.Sept"),
    t("lang.Oct"),
    t("lang.Nov"),
    t("lang.Dec"),
  ];
};

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function formatNumber(number) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return (number / billion).toFixed(1) + "b";
  } else if (number >= million) {
    return (number / million).toFixed(1) + "m";
  } else if (number >= thousand) {
    return (number / thousand).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}

export function firstDateOfTheMonth() {
  // Get the current date
  const currentDate = new Date();

  // Subtract 10 days from the current date
  // const

  // Return the new date in international standard format
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
}

export const currentDate = new Date();

export const handleKeyPress = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const SortOrder = (dataset = []) => {
  return dataset?.sort((a, b) => {
    let bDate = new Date(b.createdAt);
    let aDate = new Date(a.createdAt);

    return aDate - bDate;
  });
};

export const NumberTable = (item, name) => {
  // console.log(item,'result')
  const index = item?.findIndex((object) => {
    // console.log(object._id, name,'result')
    return object?.id === name;
  });

  // console.log(index,item,'result'); // 👉️ 1
  return index ? index + 1 : 1;
};

export const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-md capitalize";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] capitalize";
      break;
    default:
      break;
  }
};

export const FormDatedView = (date) => {
  // Input timestamp
  const timestamp = date;

  // Convert timestamp to moment object
  const momentObj = moment(timestamp);

  // Format the moment object as required
  const formattedDate = momentObj.format("h:mma - Do MMMM, YYYY");

  return formattedDate;
};
