import { REGEX_PATTERNS, generateMinLength } from "../../../../utils/constants/errors";
import { LanguageName } from "../../../../utils/helper";

export const FormLabel=[
   
    {
        name:'firstName',
        label:'Company’s First Name',
        className:'col-lg-6',
        type:'',
        placeholder:'Enter Company’s Name'
    },
    {
        name:'email',
        label:'Email Address',
        className:'col-lg-6',
        type:'',
        placeholder:'Enter Email Address',
        pattern:REGEX_PATTERNS?.EMAIL

    },
    {
        name:'lastName',
        label:'Company’s Last Name',
        className:'col-lg-6',
        type:'',
        placeholder:'Enter Company’s Name'
    }, 
    {
        name:'phone',
        label:'Phone Number',
        className:'col-lg-6',
        type:'',
        placeholder:'',
        pattern:REGEX_PATTERNS?.NUMBER,
        minLength:generateMinLength(10)
    },
    {
        name:'address',
        label:'Company’s Full Address',
        className:'col-lg-6',
        type:'',
        placeholder:''
    },
    {
        name:'contactPerson',
        label:'Contact Person’s Name',
        className:'col-lg-6',
        type:'',
        placeholder:''
    },
    {
        name:'country',
        label:'Country',
        className:'col-lg-6',
        type:'',
        placeholder:'Choose Country'
    },
    {
        name:'state',
        label:'State',
        className:'col-lg-6',
        type:'',
        placeholder:'Choose State'
    },
   
   
    



]

export const FormLabelII=()=>{
    return [
   
        {
            name:'firstName',
            label:LanguageName('CFN'),
            className:'col-lg-6',
            type:'',
            placeholder:'Enter Company’s Name'
        },
        {
            name:'email',
            label:LanguageName('EA'),
            className:'col-lg-6',
            type:'',
            placeholder:'Enter Email Address',
            pattern:REGEX_PATTERNS?.EMAIL
    
        },
        {
            name:'lastName',
            label:LanguageName('CLN'),
            className:'col-lg-6',
            type:'',
            placeholder:'Enter Company’s Name'
        }, 
        {
            name:'phone',
            label:LanguageName('PNN'),
            className:'col-lg-6',
            type:'',
            placeholder:'',
            pattern:REGEX_PATTERNS?.NUMBER,
            minLength:generateMinLength(10)
        },
        {
            name:'address',
            label:LanguageName('CFA'),
            className:'col-lg-6',
            type:'',
            placeholder:''
        },
        {
            name:'contactPerson',
            label:LanguageName('CPN'),
            className:'col-lg-6',
            type:'',
            placeholder:''
        },
        {
            name:'country',
            label:LanguageName('COTY'),
            className:'col-lg-6',
            type:'',
            placeholder:'Choose Country'
        },
        {
            name:'state',
            label:LanguageName('S'),
            className:'col-lg-6',
            type:'',
            placeholder:'Choose State'
        },
       
       
        
    
    
    
    ]
}
