import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const CallReportApi = createApi({
  reducerPath: "CallReportApi",
  baseQuery: baseQuery,
  tagTypes:['callReport'],

  endpoints: (builder) => ({
   
    
    getCallReport: builder.query({
      query: (companyId) => ({
        url: `/schedules/company/${companyId}/reports`,
        method: "GET",
      }),
      providesTags:()=>['callReport']
    }),
    getSingleCallReport: builder.query({
      query: (call_report_id) => ({
        url: `/schedules/single/call_report/${call_report_id}`,
        method: "GET",
      }),
      providesTags:()=>['callReport']
    }),
    approveReport: builder.mutation({
      query: (reportId) => ({
        url: `/schedules/reports/${reportId}/approve`,
        method: "POST",
        // body: payload,
      }),
      // transformResponse: (response) => response.data,
      invalidatesTags:['callReport']
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
useGetCallReportQuery,
useApproveReportMutation,
useGetSingleCallReportQuery
} = CallReportApi;
