import { useTranslation } from "react-i18next";
import { ImageComponent } from "../Common/FallbackImage";
import { useEffect } from "react";
import {
  ActivitiesIcon,
  AdminConsole,
  CallReportIcon,
  EventIcon,
  HCOIcon,
  HCpIcon,
  MedRepIcon,
  MediaIcon,
  OverviewIcon,
  PlanIcon,
  ScheduleIcon,
  UserProfileIcon,
} from "../../utils/svg";
import { LanguageName } from "../../utils/helper";

export const SideBarData = () => {
  // const { t,i18n } = useTranslation();

  // useEffect(() => {
  //   const lng = navigator.language;
  //   i18n.changeLanguage(lng);
  // }, [i18n]);

  return [
    {
      name: LanguageName("OV"),
      icon: <OverviewIcon />,
      // icon:<ImageComponent width={26} height={26} src='/Dashboard/dashboard1.png' />,
      path: "/overview",
      activeI: 1,
    },
    {
      name: LanguageName("Activities"),
      // icon1:<ImageComponent width={26} src='/allIcon/activity.svg' />,
      icon: <ActivitiesIcon />,
      path: "/activities",
      activeI: 2,
      plus: true,
      subItem: [
        {
          name: LanguageName("Schedule"),
          path: "/activities/Schedule",
          icon: <ScheduleIcon />,
        },
        {
          name: LanguageName("CR"),
          path: "/activities/call_reports",
          icon: <CallReportIcon />,
        },
        {
          name: LanguageName("EVT"),
          path: "/activities/events",
          icon: <EventIcon />,
        },
      ],
    },
    {
      name: LanguageName("Accounts"),
      icon: <UserProfileIcon />,
      path: "/account",
      activeI: 3,
      plus: true,
      subItem: [
        {
          name: "HCP",
          path: "/account/hcp",
          icon: <HCpIcon />,
        },
        {
          name: "HCO",
          path: "/account/health_care_organization",
          icon: <HCOIcon />,
        },
        {
          name: LanguageName("MR"),
          path: "/account/med_rep",
          icon: <MedRepIcon />,
        },
      ],
    },
    {
      name: `Media`,
      icon: <MediaIcon />,
      path: "/media",
      activeI: 7,
      // name:'Leads',path:'/lead'
    },
    {
      name: `Plan`,
      icon: <PlanIcon />,
      path: "/plan_overview",
      activeI: 4,
      // plus:true
    },
    {
      name: `Admin Console`,
      icon: <AdminConsole />,
      path: "/admin",
      activeI: 5,
      // plus:true
    },
  ];
};
