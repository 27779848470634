import React from "react";
import DropDownComp from "../../../../Components/Ui/DropDown";
import { ArrowDown, TickIcon } from "../../../../utils/svg";
import ButtonComp from "../../../../Components/Ui/Button";
import { FormLabel, FormLabelII } from "./Data";
import FormUi from "../../../../Components/Ui/FormUi";
import { LanguageName } from "../../../../utils/helper";

export default function PasswordPage({
  control
}) {
  return (
    <div className="px-2">
     <div>
     <div className="text-[#333333] text-[18px] font-[500] ">{LanguageName('PW')}</div>
      <div className="text-[#555555] text-[16px] font-normal pb-[30px] border-b-[#EDE5F3] border-b-[1px] mb-[30px]">
        {LanguageName('UPA')}
      </div>
     </div>

     <div className="row gy-5">
     <FormUi
            formData={FormLabelII()}
            // la={item?.label}
            control={control}
            containerClassName={"h-[35px] bg-[#FCFAFF] border-1"}
            labelClassName="!text-[14px] mb-1 text-[#4F008A]"
            />
     </div>
    </div>
  );
}
