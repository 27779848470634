import React from 'react'
import { Form } from 'react-bootstrap'

export default function CheckboxComp({
    label,
    onChange,
    error
}) {
  return (
    <div>
        <Form.Check // prettier-ignore
            type={'checkbox'}
            // id={`default-${type}`}
            label={<div className='text-[#777777]'>{label}</div>}
            onChange={onChange}
          />
          {error && (
        <div className="text-danger text-[13px] text-start ps-1">
         {error}
        </div>
      )}
    </div>
  )
}
