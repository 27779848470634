import React from "react";
import ButtonComp from "../../../Components/Ui/Button";
import Pagination from "react-bootstrap/Pagination";
import { columnsDataIIMed, tableData } from "./Data";
import { Link } from "react-router-dom";
import { TableComp } from "../../../Components/Common/TableComp";
import { LanguageName } from "../../../utils/helper";

export default function PlanMedRepsComp({ Data }) {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  console.log("actuall dataaaaa", Data);
  return (
    <div className="container">
      <div className="row">
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between items-center lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <p className="font-[500] text-[24px]">Medical Representatives</p>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">{LanguageName("SB")}</div>{" "}
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">
                {" "}
                {LanguageName("CTY")}: {LanguageName("All")}
              </div>{" "}
              <div className="">
                <img src="/icon/select-arrows.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                {LanguageName("TMR2")}
              </div>
              <br />
              <div className="text-[32px] text-[#060D1C] font-[600]">
                {Data?.length}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                {LanguageName("AMR")}
              </div>
              <br />
              <div className="flex justify-between items-center">
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.filter((item) => item?.status === "Active")?.length}
                </div>
                <div className="flex items-center bg-[#EFFDF9] rounded-[36px] py-1 px-2">
                  <div className="me-2">
                    <img src="/icon/arrow-up.png" alt="down" />
                  </div>
                  <div className="text-[16px] text-[#3DA84A] font-[700]">
                    +0%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                {LanguageName("IAMR")}
              </div>
              <br />
              <div className="flex justify-between items-center">
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.filter((item) => item?.status === "Inactive")?.length}
                </div>
                <div className="flex items-center bg-[#FDEFEF] rounded-[36px] py-1 px-2">
                  <div className="me-2">
                    <img src="/icon/arrow-down.png" alt="down" />
                  </div>
                  <div className="text-[16px] text-[#D63434] font-[700]">
                    -0%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container border border-1 rounded mb-[32px] w-full ">
        <TableComp Data={Data} columnsComp={columnsDataIIMed()} />
      </div>
    </div>
  );
}
