import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HealthCarePractitioner from "./HealthCarePractitioner";
import { useDisableHcpMutation, useGetHCPQuery } from "../../../store/Schedules/SchedulesApi";
import { toast } from "react-toastify";

function HealthCare() {
  const {data:HCPData,isLoading } =useGetHCPQuery();
  const [disableHCP,{isLoading:DHloading,isError}]=useDisableHcpMutation();

  // console.log(HCPData?.data?.hcps,'HCPData')

  const handleDisableHcp = async(id) =>{
    
    const Response = await disableHCP(id);
    if(!Response?.data?.status){
      toast.error("oops, something went wrong")
    }
    else{
      toast.success(Response?.data?.message)
    }

    console.log(id,Response,'handleDisableHcp')
  }

  return (
    <div>
      {/* <AdminLayout active={1}> */}
        <HealthCarePractitioner
        HCP={HCPData?.data?.hcps}
        hcpLoader={isLoading}
        handleDisableHcp={handleDisableHcp}
        />
      {/* </AdminLayout> */}
    </div>
  );
}

export default HealthCare;
