import { REGEX_PATTERNS } from "../../../../utils/constants/errors";
import { LanguageName } from "../../../../utils/helper";

export const FormLabel=[
   
    {
        name:'firstName',
        label:LanguageName('MFN'),
        className:'col-lg-6',
        type:'',
        placeholder:'Enter Company’s Name'
    },
    {
        name:'email',
        label:'Email Address',
        className:'col-lg-6',
        type:'',
        placeholder:'Enter Email Address'
    },
    {
        name:'lastName',
        label:'Manager’s Last Name',
        className:'col-lg-6',
        type:'',
        placeholder:'Enter Manager’s Name'
    }, 
    {
        name:'phoneNumber',
        label:'Phone Number',
        className:'col-lg-6',
        type:'',
        placeholder:''
    },
    {
        name:'address',
        label:'Company’s Full Address',
        className:'col-lg-6',
        type:'',
        placeholder:''
    },
   
    {
        name:'country',
        label:'Country',
        className:'col-lg-6',
        type:'',
        placeholder:'Choose Country'
    },
    {
        name:'state',
        label:'State',
        className:'col-lg-6',
        type:'',
        placeholder:'Choose State'
    },
   
   
    



]

export const FormLabelII =()=>{
    return [
   
        {
            name:'firstName',
            label:LanguageName('MFN'),
            className:'col-lg-6',
            type:'',
            placeholder:'Enter Company’s Name'
        },
        {
            name:'email',
            label:LanguageName('EA'),
            className:'col-lg-6',
            type:'',
            placeholder:'Enter Email Address'
        },
        {
            name:'lastName',
            label:LanguageName('MLN'),
            className:'col-lg-6',
            type:'',
            placeholder:'Enter Manager’s Name'
        }, 
        {
            name:'phoneNumber',
            label:LanguageName('PNN'),
            className:'col-lg-6',
            type:'',
            placeholder:''
        },
        {
            name:'address',
            label:LanguageName('CFA'),
            className:'col-lg-6',
            type:'',
            placeholder:''
        },
       
        {
            name:'country',
            label:LanguageName('COTY'),
            className:'col-lg-6',
            type:'',
            placeholder:'Choose Country'
        },
        {
            name:'state',
            label:LanguageName('S'),
            className:'col-lg-6',
            type:'',
            placeholder:'Choose State'
        },
       
       
        
    
    
    
    ]
}
