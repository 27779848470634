import React, { useEffect } from "react";
import { text1 } from "../../utils/styling";
import DropDownComp from "../Ui/DropDown";
import { CalendarIcon, DownArrowBlack,Warning,helpIcon } from "../../utils/svg";
import ButtonComp from "../Ui/Button";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../utils/helper";

export default function HeaderII({
  setPage,
  page
}) {
  // const { i18n } = useTranslation();
  const { t,i18n } = useTranslation();

  
  useEffect(() => {
    const lng = navigator.language;
    // i18n.changeLanguage(lng);
  }, [i18n]);

  return (
    <div className="flex justify-between items-center mb-[50px] rounded-md">
      <div className={`text-black flex items-center gap-2 font-bold  ${text1}`}>
   
      {t(`lang.OV`)}
         <Warning/>
      </div>
      <div>
        <DropDownComp
        dropDownText={<div
        className="flex items-center gap-1 text-[#969696] text-[14px]  border-[1px] px-[18px] py-[14px] "
        ><span className="mr-2"><CalendarIcon/></span><span className="font-medium !text-[#292D32]">30 Days</span> Sept 1, 2023 - Sept 30, 2023 <DownArrowBlack/> </div>}
        />
      </div>
      <div className="bg-[#F6F6F6] px-[12px] py-[8px] flex">
          <ButtonComp onClick={()=>setPage('overview')} text={ t(`lang.OV`)} btnClassName={`h-[35px] px-[27px] text-[14px] ${page==='overview'?'text-white bg-[#44AA43]':''}`}/>
          <ButtonComp onClick={()=>setPage('analytics')} text={t(`lang.Analytics`)}btnClassName={`h-[35px]  px-[27px] rounded-sm  text-[14px] ${page==='analytics'?'text-white bg-[#44AA43]':''}`}/>
          
      </div>
    </div>
  );
}
