import React from "react";
import ScheduleComp from "./ScheduleComp";
import {useGetSchedulesQuery} from '../../store/Schedules/SchedulesApi'
function Schedule() {
  const {data:ScheduleData,isLoading } =useGetSchedulesQuery();
  return (
      <ScheduleComp 
      Schedule={ScheduleData?.data?.schedules}
      scheduleLoader={isLoading}
      />
  );
}

export default Schedule;
