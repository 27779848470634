import React from "react";
import ButtonComp from "../../Components/Ui/Button";
import { LanguageName } from "../../utils/helper";
import {
  DocIcon,
  DocIcon2,
  ImageIcon,
  ImageIcon2,
  VideoIcon,
  VideoIcon2,
} from "../../utils/svg";
import DropDownComp from "../../Components/Ui/DropDown";

export default function SingleMediaComp({
  Medias,
  addMedia,
  name,
  searchTerm,
  handleSearch,
  options,
  handleSortChange,
}) {
  console.log("medias", Medias);

  const imageShowCase = (key) => {
    if (key?.includes("image") || key?.includes("photo")) {
      return <ImageIcon2 />;
    }
    if (key?.includes("audio")) {
      return <VideoIcon2 />;
    }
    if (key?.includes("application") || key?.includes("text")) {
      return <DocIcon2 />;
    }
  };

  function convertBytesToKBOrMB(bytes) {
    if (bytes >= 1024) {
      return (bytes / 1024).toFixed(1) + " KB";
    } else {
      return bytes + " bytes";
    }
  }
  return (
    <div className="container">
      <div className="border-b-2 mb-[71px]">
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem]">
            <div className="text-[25px] font-[700]">{name}</div>
            <div className="ms-2">
              <img src="/icon/info-circle.svg" alt="" />
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
            <div className="flex align-items-center  border-[1px] border-[#4F008A] bg-[#4F008A] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
              <div className="me-2">
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1729 7.34905L8.08374 3.25993L3.99463 7.34905"
                    stroke="white"
                    stroke-width="1.01049"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.08398 14.7121V3.3744"
                    stroke="white"
                    stroke-width="1.01049"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <ButtonComp
                onClick={addMedia}
                className="text-[#FFFFFF] font-[500] text-[16px] "
                text={"Upload"}
              />
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="border-r-2 ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>
            {/* <input className="border-0 mb-3 ">
                   <form
                     className="border-0"
                     placeholder="Search for Call Reports by ID, Name, Date, HCOs"
                     aria-label="Search for Call Reports by ID, Name, Date, HCOs"
                     aria-describedby="basic-addon2"
                   ><form
                 </input> */}
            {/* <input */}
            {/*   className="w-100 text-[16px] font-[400]" */}
            {/*   type="text" */}
            {/*   placeholder={LanguageName("SCR")} */}
            {/* /> */}
            <input
              className="w-100 text-[16px] font-[400]"
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder={LanguageName("SCR")}
            />
          </div>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              {/* <div className="">{LanguageName("SB")}</div>{" "} */}
              <DropDownComp
                dropDownText={LanguageName("SB")}
                options={options()}
              />
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            {/* <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3"> */}
            {/*   <div className=""> */}
            {/*     {" "} */}
            {/*     {LanguageName("CTY")}: {LanguageName("All")} */}
            {/*   </div>{" "} */}
            {/*   <div className=""> */}
            {/*     <img src="/icon/select-arrows.png" alt="" /> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* {Medias?.map((item, i) => (
        <div key={i}>
          <div className="text-[24px] text-[#000000] font-[600] mb-[37px]">
            {item?.name}
          </div> */}

      {Medias?.length == 0 && (
        <div className="col-lg-12">
          <div className="flex justify-center">
            <svg
              width="163"
              height="115"
              viewBox="0 0 163 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="163" height="115" rx="6" fill="#FCFAFF" />
              <g clip-path="url(#clip0_3_24839)">
                <path
                  d="M124 62.3092V19.4271C124 17.0209 122.03 15 119.624 15H96.5186C94.8156 15 93.2687 16.0274 92.5585 17.5752L90.2281 22.6918C89.5179 24.2396 87.971 25.2687 86.2681 25.2687H46.3503C43.9442 25.2687 42.0342 27.1805 42.0342 29.5868V69.261C42.0342 71.6672 43.9442 73.678 46.3503 73.678H119.624C119.739 73.678 119.853 73.612 119.966 73.6032V81.9296H120.029C120.029 83.0298 120.923 83.8799 122.014 83.8799C123.106 83.8799 124 82.9692 124 81.8777V62.3092Z"
                  fill="#A162F7"
                />
                <path
                  d="M122.014 83.8799C120.923 83.8799 120.029 83.0298 120.029 81.9296H119.966V41.0715C119.966 38.6651 118.07 36.6374 115.664 36.6374H42.3899C39.9837 36.6374 38 38.6651 38 41.0715V84.9313C38 87.3375 39.9837 89.2643 42.3899 89.2643H115.664C115.918 89.2643 116.166 89.2533 116.409 89.2115C116.709 89.2531 117.015 89.2821 117.328 89.2821C121.002 89.2821 123.954 86.3062 123.954 82.6315C123.954 82.5336 123.947 82.4375 123.943 82.3407C123.733 83.2146 122.947 83.8799 122.014 83.8799Z"
                  fill="#4F008A"
                />
              </g>
              <defs>
                <clipPath id="clip0_3_24839">
                  <rect
                    width="86"
                    height="74.4478"
                    fill="white"
                    transform="translate(38 15)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div>
            <div className="text-center text-[#000000] text-[12px] mb-[10px]">
              No Files Added yet
            </div>
            <div className="flex justify-center  gap-3">
              <div className="flex items-center gap-2">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 5.5V8.5C11 10.5 10.5 11 8.5 11H3.5C1.5 11 1 10.5 1 8.5V3.5C1 1.5 1.5 1 3.5 1H4.25C5 1 5.165 1.22 5.45 1.6L6.2 2.6C6.39 2.85 6.5 3 7 3H8.5C10.5 3 11 3.5 11 5.5Z"
                    stroke="#635F65"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M4 1H8.5C9.5 1 10 1.5 10 2.5V3.19"
                    stroke="#635F65"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="text-[10px] text-[#635F65]">{0}</div>
              </div>
              <div className="flex items-center gap-2">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z"
                    stroke="#635F65"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.5 5C5.05228 5 5.5 4.55228 5.5 4C5.5 3.44772 5.05228 3 4.5 3C3.94772 3 3.5 3.44772 3.5 4C3.5 4.55228 3.94772 5 4.5 5Z"
                    stroke="#635F65"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.33496 9.4743L3.79996 7.8193C4.19496 7.5543 4.76496 7.5843 5.11996 7.8893L5.28496 8.0343C5.67496 8.3693 6.30496 8.3693 6.69496 8.0343L8.77496 6.2493C9.16496 5.9143 9.79496 5.9143 10.185 6.2493L11 6.9493"
                    stroke="#635F65"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div className="text-[10px] text-[#635F65]">{0}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-5 2xl:gap-7.5 bg-[#FCFAFF] py-[25px] px-[35px] mb-[44px]">
        {Medias?.map((subItem, i) => (
          <div className="col-md-6 col-lg-3 " key={i}>
            <div className="flex flex-col justify-center items-center bg-white rounded-[7px] p-2">
              <div className="text-center bg-[#fcfaff] px-4 py-2 mb-2 rounded-[6px] w-full h-full flex justify-center">
                {imageShowCase(subItem?.type)}
                {/* <svg */}
                {/*   width="163" */}
                {/*   height="115" */}
                {/*   viewBox="0 0 163 115" */}
                {/*   fill="none" */}
                {/*   xmlns="http://www.w3.org/2000/svg" */}
                {/* > */}
                {/*   <rect width="163" height="115" rx="6" fill="#FCFAFF" /> */}
                {/*   <g clip-path="url(#clip0_3_24839)"> */}
                {/*     <path */}
                {/*       d="M124 62.3092V19.4271C124 17.0209 122.03 15 119.624 15H96.5186C94.8156 15 93.2687 16.0274 92.5585 17.5752L90.2281 22.6918C89.5179 24.2396 87.971 25.2687 86.2681 25.2687H46.3503C43.9442 25.2687 42.0342 27.1805 42.0342 29.5868V69.261C42.0342 71.6672 43.9442 73.678 46.3503 73.678H119.624C119.739 73.678 119.853 73.612 119.966 73.6032V81.9296H120.029C120.029 83.0298 120.923 83.8799 122.014 83.8799C123.106 83.8799 124 82.9692 124 81.8777V62.3092Z" */}
                {/*       fill="#A162F7" */}
                {/*     /> */}
                {/*     <path */}
                {/*       d="M122.014 83.8799C120.923 83.8799 120.029 83.0298 120.029 81.9296H119.966V41.0715C119.966 38.6651 118.07 36.6374 115.664 36.6374H42.3899C39.9837 36.6374 38 38.6651 38 41.0715V84.9313C38 87.3375 39.9837 89.2643 42.3899 89.2643H115.664C115.918 89.2643 116.166 89.2533 116.409 89.2115C116.709 89.2531 117.015 89.2821 117.328 89.2821C121.002 89.2821 123.954 86.3062 123.954 82.6315C123.954 82.5336 123.947 82.4375 123.943 82.3407C123.733 83.2146 122.947 83.8799 122.014 83.8799Z" */}
                {/*       fill="#4F008A" */}
                {/*     /> */}
                {/*   </g> */}
                {/*   <defs> */}
                {/*     <clipPath id="clip0_3_24839"> */}
                {/*       <rect */}
                {/*         width="86" */}
                {/*         height="74.4478" */}
                {/*         fill="white" */}
                {/*         transform="translate(38 15)" */}
                {/*       /> */}
                {/*     </clipPath> */}
                {/*   </defs> */}
                {/* </svg> */}
              </div>
              <div>
                <div className="flex flex-col items-center justify-center font-[500] text-[#535763]">
                  <div className="text-center font-[500] text-[#000000] text-[12px] mb-[10px]">
                    {subItem?.name}
                  </div>
                  <p className="text-[9px] mb-0 leading-none">File size</p>
                  <p className="text-[8px] leading-none mb-2">
                    {convertBytesToKBOrMB(subItem?.size)}
                  </p>
                  {/* <div className="flex items-center gap-2"> */}
                  {/*   <svg */}
                  {/*     width="12" */}
                  {/*     height="12" */}
                  {/*     viewBox="0 0 12 12" */}
                  {/*     fill="none" */}
                  {/*     xmlns="http://www.w3.org/2000/svg" */}
                  {/*   > */}
                  {/*     <path */}
                  {/*       d="M11 5.5V8.5C11 10.5 10.5 11 8.5 11H3.5C1.5 11 1 10.5 1 8.5V3.5C1 1.5 1.5 1 3.5 1H4.25C5 1 5.165 1.22 5.45 1.6L6.2 2.6C6.39 2.85 6.5 3 7 3H8.5C10.5 3 11 3.5 11 5.5Z" */}
                  {/*       stroke="#635F65" */}
                  {/*       stroke-miterlimit="10" */}
                  {/*     /> */}
                  {/*     <path */}
                  {/*       d="M4 1H8.5C9.5 1 10 1.5 10 2.5V3.19" */}
                  {/*       stroke="#635F65" */}
                  {/*       stroke-miterlimit="10" */}
                  {/*       stroke-linecap="round" */}
                  {/*       stroke-linejoin="round" */}
                  {/*     /> */}
                  {/*   </svg> */}
                  {/**/}
                  {/*   <div className="text-[10px] text-[#635F65]"> */}
                  {/*     {/* {item?.files?.filter((item) => item?.type != "photo") */}
                  {/*         ?.length || 0} */}
                  {/*   </div> */}
                  {/* </div> */}
                  {/* <div className="flex items-center gap-2"> */}
                  {/*   <svg */}
                  {/*     width="12" */}
                  {/*     height="12" */}
                  {/*     viewBox="0 0 12 12" */}
                  {/*     fill="none" */}
                  {/*     xmlns="http://www.w3.org/2000/svg" */}
                  {/*   > */}
                  {/*     <path */}
                  {/*       d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z" */}
                  {/*       stroke="#635F65" */}
                  {/*       stroke-linecap="round" */}
                  {/*       stroke-linejoin="round" */}
                  {/*     /> */}
                  {/*     <path */}
                  {/*       d="M4.5 5C5.05228 5 5.5 4.55228 5.5 4C5.5 3.44772 5.05228 3 4.5 3C3.94772 3 3.5 3.44772 3.5 4C3.5 4.55228 3.94772 5 4.5 5Z" */}
                  {/*       stroke="#635F65" */}
                  {/*       stroke-linecap="round" */}
                  {/*       stroke-linejoin="round" */}
                  {/*     /> */}
                  {/*     <path */}
                  {/*       d="M1.33496 9.4743L3.79996 7.8193C4.19496 7.5543 4.76496 7.5843 5.11996 7.8893L5.28496 8.0343C5.67496 8.3693 6.30496 8.3693 6.69496 8.0343L8.77496 6.2493C9.16496 5.9143 9.79496 5.9143 10.185 6.2493L11 6.9493" */}
                  {/*       stroke="#635F65" */}
                  {/*       stroke-linecap="round" */}
                  {/*       stroke-linejoin="round" */}
                  {/*     /> */}
                  {/*   </svg> */}
                  {/**/}
                  {/*   <div className="text-[10px] text-[#635F65]"> */}
                  {/*     {/* {item?.files?.filter((item) => item?.type == "photo") */}
                  {/*         ?.length || 0} */}
                  {/*   </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="col-lg-2"></div>
        <div className="col-lg-2"></div>
        <div className="col-lg-2"></div>
      </div>
      {/* </div>
      ))} */}
    </div>
  );
}
