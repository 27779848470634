import React from "react";
import ButtonComp from "../../Components/Ui/Button";
import Pagination from "react-bootstrap/Pagination";
import { TopHeaderCount, columnsData, tableData } from "./Data";
import TopHeaderCounts from "../../Components/Common/TopHeaderCounts";
import { useState } from "react";
import { TableComp } from "../../Components/Common/TableComp";
import DropDownComp from "../../Components/Ui/DropDown";
import { Dots } from "../../utils/svg";
import { LanguageName } from "../../utils/helper";

export default function ScheduleComp({ Schedule, scheduleLoader }) {
  const [headerSelected, setHeaderSelected] = useState("Total Schedules");
  const [searchSchedule, setSearchSchedule] = useState("");
  //md:flex-col lg:flex-row flex-col
  return (
    <div className="">
      <div className="container">
        <div className="flex  justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem]">
            <div className="text-[25px] font-[700]">
              {" "}
              {LanguageName("Schedule")}
            </div>
            <div className="ms-2">
              <img src="/icon/info-circle.svg" alt="" />
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
            <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
              <div className="me-1">
                <img src="/Schedule/document-download.png" alt="" />
              </div>
              <ButtonComp
                className="text-[#44AA43] font-[500] text-[16px] "
                text={LanguageName("DR")}
              />
            </div>
            <div className="flex align-items-center border-1 border-[#4F0084]  bg-[#4F008A] rounded px-3 py-2 ms-2">
              <div className="me-1">
                <img src="/Schedule/radar-2.png" alt="radar" />
              </div>
              <ButtonComp
                className="text-[#FFFFFF] font-[500] text-[16px] "
                text={LanguageName("TMR")}
              />
            </div>
          </div>
        </div>
        {/* <DropDownComp
      dropDownText={  <Dots color="#7572EA" />}
      options={[{name:'Track Med Rep'}]}
      /> */}
        {/*  */}
        <div className="flex overflow-x-scroll !xl:overflow-x-hidden lg:overflow-hidden  lg:mb-[20px] md:mb-[45px] mb-[45px] customScrollHorizontal">
          {TopHeaderCount(Schedule)?.map(({ name, count,findBy }, i) => (
            <TopHeaderCounts
              key={i}
              name={name}
              count={count}
              isActive={headerSelected === name}
              setHeaderSelected={setHeaderSelected}
              Data={()=>{
                setSearchSchedule(findBy)
              }}
            />
          ))}

          <div className="w-[8.5rem] lg:border-b-2">{""}</div>
        </div>
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="border-r-2 ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>

            <input
              className="w-100 text-[16px] font-[400]"
              type="text"
              placeholder={LanguageName("SCR")}
              onChange={(e) => setSearchSchedule(e.target.value)}
            />
          </div>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">{LanguageName("SB")}</div>{" "}
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">
                {" "}
                {LanguageName("CTY")}: {LanguageName("All")}
              </div>{" "}
              <div className="">
                <img src="/icon/select-arrows.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container border border-1 rounded mb-[32px] w-full ">
          <TableComp
            loader={scheduleLoader}
            columnsComp={columnsData()}
            Data={
              Schedule?.filter((item) => {
                const itemName = item?.institutionName?.toLowerCase() || "";
                const itemCompany =
                  item?.medRep?.firstName?.toLowerCase() || "";
                const itemStatus = item?.status?.toLowerCase() || "";
                const search = searchSchedule?.toLowerCase() || "";

                return (
                  itemName.includes(search) ||
                  itemCompany.includes(search) ||
                  itemStatus.includes(search)
                );
              })
              //       .reverse()
            }
          />
        </div>
      </div>
    </div>
  );
}
