import React, { useState, useCallback } from "react";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "react-multi-date-picker/styles/colors/purple.css";

const useMultiDateSelect = () => {
  const [selectedDates, setSelectedDates] = useState([]);

  const getRemainingWeekdaysExcludingSelectedDates = (dates) => {
    // ... logic to calculate remaining weekdays
    const sampleDate = new Date(dates[0]);
    const year = sampleDate.getFullYear();
    const month = sampleDate.getMonth();
    const endOfMonth = new Date(year, month + 1, 0);
    let remainingWeekdays = 0;

    const selectedDatesSet = new Set(
      dates.map((date) => new Date(date).getDate()),
    );

    for (let day = 1; day <= endOfMonth.getDate(); day++) {
      if (selectedDatesSet.has(day)) continue;

      const currentDate = new Date(year, month, day);
      const dayOfWeek = currentDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        remainingWeekdays++;
      }
    }

    return remainingWeekdays;
  };

  const countDuplicateWeekdays = (dates) => {
    const weekdaysCount = new Array(7).fill(0);

    dates.forEach((dateString) => {
      const dayOfWeek = new Date(dateString).getDay();
      weekdaysCount[dayOfWeek]++;
    });

    const duplicateWeekdaysCount = weekdaysCount.filter((count) => count > 1);
    return duplicateWeekdaysCount.reduce((total, count) => total + count, 0);
  };

  const extractDaysFromDateStrings = (dateStrings) => {
    return dateStrings.map((dateString) => new Date(dateString).getDate());
  };

  const getMonthOfFirstSelectedDate = (selectedDates) => {
    if (selectedDates.length > 0) {
      const firstDate = new Date(selectedDates[0]);
      return firstDate.getMonth() + 1; // January is 0, so add 1 to get the correct month number
    }
    return null; // Or a default value if no dates are selected
  };

  const getMonthNameOfFirstSelectedDate = (selectedDates) => {
    if (selectedDates.length > 0) {
      const firstDate = new Date(selectedDates[0]);
      return firstDate.toLocaleString("default", { month: "long" }); // Get the full name of the month
    }
    return null; // Or a default value if no dates are selected
  };

  const handleChange = useCallback((dates) => {
    setSelectedDates(dates.map((date) => date.format("YYYY-MM-DD")));
  }, []);

  const datePickerComponent = (
    <DatePicker
      multiple
      sort
      value={selectedDates.map((dateString) => new Date(dateString))}
      format="YYYY-MM-DD"
      onChange={handleChange}
      className="purple"
      style={{
        width: "500px",
        border: "0px solid",
        padding: "20px",
      }}
      // plugins={[<DatePanel />]}
    />
  );

  // Calculate and log the values
  const days = extractDaysFromDateStrings(selectedDates);
  const callsPerDay = countDuplicateWeekdays(selectedDates);
  const inactiveDays =
    getRemainingWeekdaysExcludingSelectedDates(selectedDates);
  const firstMonth = getMonthOfFirstSelectedDate(selectedDates);
  if (firstMonth !== null) {
    console.log("Month of the first selected date:", firstMonth);
  }
  const firstMonthName = getMonthNameOfFirstSelectedDate(selectedDates);
  if (firstMonthName !== null) {
    console.log("Month of the first selected date:", firstMonthName);
  }

  console.log("calls per month", selectedDates.length);
  console.log("Days", days); // Outputs the days from the date strings
  console.log("calls per day", callsPerDay); // Outputs the count of duplicate weekdays
  console.log("selectedDates", selectedDates);
  console.log("inactive days", inactiveDays); // Outputs the remaining weekdays

  return {
    datePickerComponent,
    selectedDates,
    firstMonth,
    days,
    callsPerDay,
    inactiveDays,
    firstMonthName,
  };
};

export default useMultiDateSelect;
