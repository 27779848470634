import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import {
  columnsData,
  columnsDataII,
  columnsDataIII,
  customStyles,
  tableData,
} from "./Data";
import { ProgressBar } from "react-bootstrap";
import DropDownComp from "../../../Components/Ui/DropDown";
import { ArrowDown } from "../../../utils/svg";
import { TableComp } from "../../../Components/Common/TableComp";
import SelectCompI from "../../../Components/Ui/SelectCompI";
import DropDownIII from "../../../Components/Ui/DropDownIII";
import { useGetMedRepQuery } from "../../../store/MedRep/MedRepApi";
import AboutMedRep from "./submodules/AboutMedRep";
import { useNavigate } from "react-router-dom";
import { LanguageName, storage } from "../../../utils/helper";
import { useGetPlansQuery } from "../../../store/Plans/PlansApi";
import PlanMedRepsComp from "../PlanMedReps/PlanMedRepsComp";
import InstitutionsComp from "../PlanMedReps/Instituation";
import ButtonComp from "../../../Components/Ui/Button";

export default function HisGracePharmaComp({ Data, CycleData }) {
  const navigate = useNavigate();
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();
  const [plan, setPlan] = useState("Med Reps");
  const [subTitle, setSubTitle] = useState(LanguageName("HCO"));
  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const { data: MedRepData, isLoading } = useGetMedRepQuery(companyIde, {
    skip: !companyIde,
  });

  console.log(MedRepData?.data?.medreps, "MedRepData");

  const TopList = [
    {
      name: LanguageName("Overview"),
      path: "/plan_overview",
    },
    {
      name: LanguageName("MR"),
      // path: <PlanMedRepsComp />,
      // path: <PlanMedRepsComp Data={PlansData?.data} />,
    },
    // {
    //   name: "Institutions",
    // path: <InstitutionsComp Data={PlansData?.data?.plans} />,
    // },
  ];
  const TopListII = [
    {
      name: LanguageName("HCO"),
      table: (
        <TableComp
          columnsComp={columnsData()}
          Data={Data}
          customStylesI={customStyles}
        />
      ),
      // path: <Overview />,
    },
    // {
    //   name: "Departments",
    //   table: (
    //     <TableComp
    //       columnsComp={columnsDataII()}
    //       Data={Data}
    //       customStylesI={customStyles}
    //     />
    //   ),
    //   // path: <PlanMedRepsComp />,
    // },
    // columnsDataIII
    {
      name: LanguageName("CYP"),
      path: "",
      table: (
        <TableComp
          columnsComp={columnsDataIII()}
          Data={CycleData}
          customStylesI={customStyles}
        />
      ),
    },
  ];
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <div className="container">
      <div>
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div className="flex justify-between items-center w-full">
              <div>
                <div className="text-[25px] font-[700]">
                  {LanguageName("PLAN")}
                </div>
                <div className="text-[#777777] text-[14px]">
                  {LanguageName("MR")}/{LanguageName("Institutions")}/
                  {LanguageName("CPN")}
                </div>
              </div>
              <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="border-r-2 ps-2 pe-3">
                  <img className="" src="/icon/search.png" alt="search" />
                </div>

                <input
                  className="w-100 text-[16px] font-[400]"
                  type="text"
                  placeholder={LanguageName("SCR")}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>

        <div className="flex justify-between items-center    border-b-[0.5px] flex-wrap">
          <div className="flex  lg:flex-row">
            {TopList?.map((item, index) => (
              <div
                onClick={() => setPlan(item?.name)}
                key={index}
                className=" p-[10px]  pe-3 cursor-pointer"
              >
                <div
                  onClick={() => navigate(item?.path)}
                  className={`text-[18px] ${
                    item?.name === plan
                      ? "text-[#A162F7] border-b-[#A162F7] border-b-[1px] px-2"
                      : "text-[#333333]"
                  } font-[500] `}
                >
                  {item?.name}
                </div>
              </div>
            ))}
          </div>
          <div className="flex align-items-center  border-[1px] border-[#A162F7] bg-[#A162F7] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <ButtonComp
              onClick={() => navigate("/plan/add")}
              className="text-[#FFFFFF] font-[500] text-[16px] "
              text={"Add Cycle Plan"}
            />
            <div className="ml-4">
              <img src="/Media/cross.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center "></div> */}
      <div className="row bg-[#FCFAFF]">
        <div className="col-xl-9">
          <div className=" flex justify-between items-center mt-[48px] mb-[31px]">
            <div className="text-[24px] font-medium">
              {TopListII?.find((item, i) => item?.name === subTitle)?.name}
            </div>
            {/* <div className="bg-white px-[10px] py-[10px] text-[18px]"> */}
            {/*   {LanguageName("Institution")}: General Hospital */}
            {/* </div> */}
          </div>

          <div className="border-b-[#E5E5EF] border-b-[1px] pb-2 mb-5 flex justify-between">
            <div className="flex items-center gap-2">
              {TopListII?.map((item, i) => (
                <div
                  onClick={() => setSubTitle(item?.name)}
                  className={`${
                    item?.name === subTitle ? "bg-[#f3ebfe]" : "text-[#555555]"
                  } cursor-pointer px-[10px] text-[14px] rounded-[6px] py-[5px] `}
                  key={i}
                >
                  {item?.name}
                </div>
              ))}
            </div>

            <div className="flex items-center gap-4">
              <div>
                <DropDownIII
                  dropDownText={
                    <div className="flex items-center gap-2 text-[#555555]">
                      {LanguageName("MR")} <ArrowDown />
                    </div>
                  }
                  options={MedRepData?.data?.medreps}
                  name="firstName"
                  renderItem={(item) => `${item?.firstName} ${item?.lastName}`}
                  handleClick={(item) => alert(item?._id)}
                />
              </div>
              {/* <div> */}
              {/*   <DropDownComp */}
              {/*     dropDownText={ */}
              {/*       <div className="flex items-center gap-2 text-[#555555]"> */}
              {/*         {LanguageName("Institution")} <ArrowDown /> */}
              {/*       </div> */}
              {/*     } */}
              {/*   /> */}
              {/* </div> */}
            </div>
          </div>
          <div className="table-container border border-1 rounded mb-[32px] w-full  text-[12px]">
            {TopListII?.find((item, i) => item?.name === subTitle)?.table}
          </div>
        </div>
        <div className="col-xl-3 h-full">
          <AboutMedRep />
        </div>
      </div>
    </div>
  );
}
