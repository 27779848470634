import { createApi, } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";

export const MedRepApi = createApi({
  reducerPath: "MedRepApi",
  baseQuery: baseQuery,
  tagTypes:['medReps'],

  endpoints: (builder) => ({
    getMedRep: builder.query({
      query: (companyId) => ({
        url: `/admin/company/${companyId}/med-reps`,
        method: "GET",
      }),
      providesTags:['medReps'],
    }), 
    createMedRepMain: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/med-rep/create`,
        method: "POST",
        body: payload,
      }),
    
      // transformResponse: (response) => response.data,
      invalidatesTags:['medReps']
    }),
    getSingleMedRep: builder.query({
      query: (medRepId) => ({
        url: `/user/medrep/${medRepId}`,
        method: "GET",
      }),
      // providesTags:['medReps'],
    }), 
    getMedRepActivities: builder.query({
      query: (medRepId) => ({
        url: `/user_activity/medrep/${medRepId}`,
        method: "GET",
      }),
      // providesTags:['medReps'],
    }), 
  }),
});
//admin/company/med-rep/create
//hcp/hcos

export const { useGetMedRepQuery,useCreateMedRepMainMutation,useGetSingleMedRepQuery,useGetMedRepActivitiesQuery } = MedRepApi;
