import React, { useEffect, useRef, useState } from "react";
import FormUi from "../../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import InputComp from "../../../../Components/Ui/InputComp";
import { FormLabel, FormLabelII } from "./Data";
import { LanguageName, storage } from "../../../../utils/helper";
import { useGetAllProfileQuery, useUpdateCompanyMutation, useUpdateManagerMutation, useUploadFileMutation } from "../../../../store/User/userApi";
import ButtonComp from "../../../../Components/Ui/Button";
import { useTranslation } from "react-i18next";

export default function PersonalInformation({
}) {
  const {
    control,
    handleSubmit,
    reset,
   setValue,
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      email: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      country: "",
      state: "Oyo",
      image:'',
      companyId:""
    },
  });
  const companyId = storage["localStorage"].get("user_details_profile")?.user
    ?.id;
    const { t } = useTranslation();
  // console.log(companyId, "companyId");
  const [selectedImage, setSelectedImage] = useState(null);
  const [convertImageToLink,{isLoading:CILLoader,}]=useUploadFileMutation()
  const { data: CompanyData, isLoading: CLoader } = useGetAllProfileQuery(
    companyId,
    {
      skip: !companyId,
    }
  );
  const [updateCompanyProfile,{isLoading:UCLoader}]=useUpdateManagerMutation()
  const CompanyDetails=CompanyData?.data;
  const fileInputRef = useRef(null);

  const handleFileInputChange = async(e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
   const response  = await convertImageToLink(formData);
   if(response?.data){
    const imageUrl = URL.createObjectURL(file);
    setSelectedImage(imageUrl);
    setValue('image',response?.data?.file_link)
   }
   else{
    toast.error('Please Check your image format');
   }
  //  console.log(response?.data?.file_link,'response')
     
    }
  };
  const handleSelectImage = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
      setValue('firstName',CompanyDetails?.firstName);
      setValue('email',CompanyDetails?.email);

      setValue('lastName',CompanyDetails?.lastName);

      setValue('phoneNumber',CompanyDetails?.phoneNumber);

      setValue('address',CompanyDetails?.address);
      setValue('contactPerson',CompanyDetails?.contactPerson);
      setValue('country',CompanyDetails?.country);
      setValue('state',CompanyDetails?.state);
      setValue('id',CompanyDetails?.id)


  }, [CompanyData?.data?.firstName])

  const handleUpdateCompany = async(data)=>{
    const payload ={
      ...data,
      name:`${data?.firstName} ${data?.lastName}`,
      contactPhoneNo:data?.phone
    }
   const response = await updateCompanyProfile(payload);
   if(response?.data){
      // reset();
      toast.success('Company Update Successful');
   }
  console.log(data,'handleUpdateCompany')
}

  return (
    <div className="relative px-3">
      {/* */}
      <div className="relative">
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
        <img src="/Admin/background.png" className="w-full" />
        <div onClick={handleSelectImage} className="relative">
        <div className="absolute bottom-[-50px] left-[40px] ">
          <div className="bg-[#0000003a] absolute left-0 right-0 top-0 bottom-0 rounded-full overflow-hidden"></div>
          <img  src={selectedImage||"/Admin/user.png"} className="w-[100px]  h-[100px] rounded-full object-cover" />
        </div>
        </div>
      </div>
      <div className="h-[8vh]"/>
      <div>
      <div className="text-[#333333] text-[18px] font-[500] ">
        {LanguageName('PI')}
      </div>
      <div className="text-[#555555] text-[16px] font-normal pb-[30px] border-b-[#EDE5F3] border-b-[1px] mb-[30px]">
      {LanguageName('UPPD')}
      </div>
      </div>

      <div className="row gy-4 gx-5">
       
        {/* {FormLabel?.map((item,i)=> */}
            <FormUi 
            formData={FormLabelII()}
            // la={item?.label}
            control={control}
            containerClassName={"h-[35px] bg-[#FCFAFF] border-1"}
            labelClassName="!text-[14px] mb-1 text-[#4F008A]"
            />
             <div className="flex justify-end mt-5">
        <ButtonComp disabled={CILLoader||UCLoader} loader={CILLoader||UCLoader} onClick={handleSubmit(handleUpdateCompany)} btnClassName="flex text-white font-semibold align-items-center border-1 border-[#4F0084]  bg-[#4F008A] rounded px-5 py-2 ms-2" text={'Update'}/>

        </div>
        {/* )} */}
    
        </div> 
     
    </div>
  );
}
