import React, { useState } from "react";
import { columnsData, tableData } from "./Data";
import ButtonComp from "../../Components/Ui/Button";
import HeaderII from "../../Components/Common/HeaderII";
import { TableComp } from "../../Components/Common/TableComp";
import CallReportCard from "./subModules/CallReportCard";
import Skeleton from "react-loading-skeleton";
import OverviewDashboard from "./subModules/Overview";
import Analysis from "./subModules/Analysis";
import { useTranslation } from "react-i18next";

export default function OverviewComp({ Overview, OMLoader }) {
  const [page, setPage] = useState("overview");
  const { t } = useTranslation();

  return (
    <div className="px-3">
      <HeaderII page={page} setPage={setPage} />
      <div className="row  mb-[32px]">
        <div className="col-lg-4">
          <div className="flex flex-col bg-[#4F008A] p-[33px] rounded">
            <div className="flex justify-content-between align-items-center ">
              <div className="">
                <img src="/Overview/overview-calendar.svg" alt="" />{" "}
              </div>
              <div className="">
                <img src="/icon/more-details.svg" alt="" />{" "}
              </div>
            </div>
            <div className="font-instrument-sans text-[#FFFFFF] text-[18px] font-[700] pt-[29px] pb-[33px]">
              {t(`lang.TCS`)}
            </div>
            <div className="font-instrument-sans text-[#FFFFFF] text-[24px] font-[600]">
              {OMLoader ? <Skeleton width={50} /> : Overview?.totalSchedules}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="col-lg-4">
          <div className="flex flex-col  border border-1 rounded px-2 lg:my-[0rem] md:my-[2rem] my-[2rem]">
            <div className="flex justify-content-between align-items-center border-b border-b-1  px-[20px] pt-[40px] pb-[26px]">
              <div className="font-[500]  text-[18px] text-[#444444] font-instrument-sans">
                {t(`lang.THCO`)}
              </div>
              <div className="text-[24px] font-[600] text-[#060D1C]">
                {" "}
                {OMLoader ? <Skeleton width={50} /> : Overview?.totalHcos}
              </div>
            </div>
            <div className="flex flex-col  pb-[22px] ps-[20px]">
              <div className="font-[500] mb-[20px] text-[18px] text-[#444444] pt-[24px] font-instrument-sans">
                {t(`lang.THN`)}
              </div>
              <div className="flex justify-content-between align-items-center">
                <div className="text-[24px] font-[600] text-[#060D1C]">
                  {OMLoader ? <Skeleton width={50} /> : Overview?.totalHcps}
                </div>
                <div className="flex align-items-center border border-1 py-[7px] ps-[21px] pe-[12px] rounded-full text-[16px] text-[#656468]">
                  <div className="me-2">
                    <img
                      className="font-instrument-sans w-[20px]"
                      src="/icon/up.png"
                      alt=""
                    />
                  </div>
                  -
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 ">
          <div className="flex flex-col border border-1 rounded px-2">
            <div className="flex justify-content-between align-items-center border-b border-b-1  px-[20px] pt-[40px] pb-[26px]">
              <div className="font-[500]  text-[18px] text-[#444444] font-instrument-sans">
                {t(`lang.TP`)}
              </div>
              <div className="text-[24px] font-[600] text-[#060D1C]">
                {" "}
                {OMLoader ? <Skeleton width={50} /> : Overview?.totalPlans}
              </div>
            </div>
            <div className="flex flex-col  pb-[22px] ps-[20px]">
              <div className="font-[500] mb-[20px] text-[18px] text-[#444444] pt-[24px] font-instrument-sans">
                {t(`lang.TN`)}
              </div>
              <div className="flex justify-content-between align-items-center">
                <div className="text-[24px] font-[600] text-[#060D1C]">
                  {OMLoader ? (
                    <Skeleton width={50} />
                  ) : (
                    Overview?.totalCompletedPlans
                  )}
                </div>
                <div className="flex align-items-center border border-1 py-[7px] ps-[21px] pe-[12px] rounded-full text-[16px] text-[#656468]">
                  <div className="me-2">
                    <img
                      className="font-instrument-sans w-[20px]"
                      src="/icon/down.png"
                      alt=""
                    />
                  </div>
                  -
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      {/*  */}
      <div>{page === "overview" ? <OverviewDashboard /> : <Analysis />}</div>
    </div>
  );
}
