import React, { useState } from "react";
import ButtonComp from "../../../Components/Ui/Button";
import PlanMedRepsComp from "../PlanMedReps/PlanMedRepsComp";
import Overview from "./submodules/Overview";
import InstitutionsComp from "../PlanMedReps/Instituation";
import {
  useGetPlansAnalyticsQuery,
  useGetPlansQuery,
} from "../../../store/Plans/PlansApi";
import { LanguageName } from "../../../utils/helper";
import { useSelector } from "react-redux";
import {
  selectAllCurrentUserData,
  selectCurrentUserData,
} from "../../../store/auth";
import { useNavigate } from "react-router-dom";

export default function PlanComp() {
  const [plan, setPlan] = useState("Overview");
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();
  const { data: PlansAnalyticsData, isLoading: PALoader } =
    useGetPlansAnalyticsQuery();
  const navigate = useNavigate();
  const getUserAll = useSelector(selectAllCurrentUserData);
  const getUser = useSelector(selectCurrentUserData);
  console.log("alll", getUserAll);
  console.log("user", getUser);
  console.log(PlansData, "PlansData");
  console.log("PlansData", PlansAnalyticsData);
  const TopList = [
    {
      name: LanguageName("Overview"),
      path: <Overview Data={PlansAnalyticsData?.data} />,
    },
    {
      name: LanguageName("MR"),
      path: <PlanMedRepsComp Data={PlansData?.data} />,
    },
    // {
    //   name: "Institutions",
    //   path: <InstitutionsComp Data={PlansData?.data?.plans} />,
    // },
  ];
  return (
    <div className="container">
      <div>
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div className="flex justify-between items-center w-full">
              <div>
                <div className="text-[25px] font-[700]">
                  {LanguageName("PLAN")}
                </div>
                <div className="text-[#777777] text-[14px]">
                  {LanguageName("MR")}/{LanguageName("Institutions")}/
                  {LanguageName("CPN")}
                </div>
              </div>
              <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="border-r-2 ps-2 pe-3">
                  <img className="" src="/icon/search.png" alt="search" />
                </div>

                <input
                  className="w-100 text-[16px] font-[400]"
                  type="text"
                  placeholder={LanguageName("SCR")}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>

        <div className="flex justify-between items-center lg:mb-[62px] mb-[34px]     border-b-[0.5px] flex-wrap">
          <div className="flex  lg:flex-row   ">
            {TopList?.map((item, index) => (
              <div
                onClick={() => setPlan(item?.name)}
                key={index}
                className=" p-[10px]  pe-3 cursor-pointer"
              >
                <div
                  className={`text-[18px] ${
                    item?.name === plan
                      ? "text-[#A162F7] border-b-[#A162F7] border-b-[1px] px-2"
                      : "text-[#333333]"
                  } font-[500] `}
                >
                  {item?.name}
                </div>
              </div>
            ))}
          </div>
          {plan === "Overview" && (
            <div className="flex items-center space-x-2 ">
              <ButtonComp
                onClick={() => {
                  navigate("/plan/summary");
                }}
                className="text-[#FFFFFF] font-[500] text-[18px] bg-[#A162F7] rounded-[5px] py-[8px] px-[24px]"
                // text={LanguageName("VPL")}
                text={"View Plans"}
              />
            </div>
          )}
        </div>
      </div>
      {TopList?.find((item, i) => item?.name === plan)?.path}
    </div>
  );
}
