import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const PlansApi = createApi({
  reducerPath: "PlansApi",
  baseQuery: baseQuery,
  //plans/medRep/:medRepId
  endpoints: (builder) => ({
    createPlan: builder.mutation({
      query: (payload) => ({
        url: `/plans`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    getPlans: builder.query({
      query: () => ({
        url: `/plans/company/medreps`,
        method: "GET",
      }),
    }),
    getPlansSummary: builder.query({
      query: () => ({
        url: `/plans/hcps/summary`,
        method: "GET",
      }),
    }),
    getPlansOverview: builder.query({
      query: (id) => ({
        url: `/plans/overview?medRepId=${id}`,
        method: "GET",
      }),
    }),
    getPlansAnalytics: builder.query({
      query: () => ({
        url: "/analytics/admin/overview",
        method: "GET",
      }),
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
    }),
    getPlanHOC: builder.query({
      query: () => ({
        url: `/plans/hco?hco=institution&date=2023-10-21`,
        method: "GET",
      }),
    }),
    createHCP: builder.mutation({
      query: (payload) => ({
        url: `/hcp`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
    getMedRepDetails: builder.query({
      query: (id) => ({
        url: `/plans/medRep/${id}`,
        method: "GET",
      }),
    }),
    getMedRepDetailsHCp: builder.query({
      query: (id) => ({
        url: `/hcp/med-rep/${id}`,
        method: "GET",
      }),
    }),
    getMedRepCyclePlan: builder.query({
      query: (id) => ({
        url: `/plans/cycle?medRepId=${id}&date=2023-10-02`,
        method: "GET",
      }),
    }),
    getHCPViaMedRep: builder.query({
      query: (id) => ({
        url: `/plans/medrep/hcps/${id}`,
        method: "GET",
      }),
    }),
    getSinglePlan: builder.query({
      query: (id) => ({
        url: `/plans/${id}`,
        method: "GET",
      }),
    }),
  }),
});

//plans/medrep/hcps/:medrepId
//plans/cycle?medRepId=64cbb8f1af9faf444f0cc5d4&date=2023-10-02

//plans/medRep/64cbb8f1af9faf444f0cc5d4
//hcp/hcos

export const {
  useCreatePlanMutation,
  useGetPlansQuery,
  useGetPlansOverviewQuery,
  useGetPlansAnalyticsQuery,
  useCreateHCPMutation,
  useGetPlanHOCQuery,
  useGetPlansSummaryQuery,
  useLazyGetMedRepDetailsQuery,
  useGetMedRepDetailsQuery,
  useGetMedRepCyclePlanQuery,
  useGetMedRepDetailsHCpQuery,
  useGetHCPViaMedRepQuery,
  useGetSinglePlanQuery,
} = PlansApi;
