import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { store } from "../src/store";
import "./App.css";
import { ProtectedRoute } from "./Hoc";
import DefaultLayout from "./Layout/DefaultLayout";
import Admin from "./Pages/Admin/Admin";
import CallReports from "./Pages/CallReports/CallReports";
import DashBoard from "./Pages/DashBoard";
import Events from "./Pages/Events/Events";
import HealthCareOrganization from "./Pages/HCP/HCO/HealthCareOrganization";
import HCPList from "./Pages/HCP/HCPList/HCPList";
import HisGracePharmacy from "./Pages/HCP/HGP/HisGracePharmacy";
import HealthCare from "./Pages/HCP/HealthCare/HealthCare";
import MedRepMain from "./Pages/HCP/MedRep/MedRepMain";
import NewHealthCare from "./Pages/HCP/NHC/NewHealthCare";
import NewHealthCareMedRep from "./Pages/HCP/NHCMedRep/NewHealthCareMedRep";
import NewHealthCareii from "./Pages/HCP/NHCii/NewHealthCareii";
import NewHealthCareiii from "./Pages/HCP/NHCiii/NewHealthCareiii";
import Login from "./Pages/Login";
import Media from "./Pages/Media";
import SingleMedia from "./Pages/Media/singleMedia";
import Overview from "./Pages/Overview/Overview";
import PanadolEfficacy from "./Pages/PanadolEfficacy/PanadolEfficacy";
import CyclePlan from "./Pages/Plans/CyclePlan/CyclePlan";
import Department from "./Pages/Plans/Department/Department";
import AddPlan from "./Pages/Plans/HisGracePharma/AddPlan";
import PlanEdit from "./Pages/Plans/HisGracePharma/EditPlan";
import HisGracePharma from "./Pages/Plans/HisGracePharma/HisGracePharma";
import PlanSummary from "./Pages/Plans/HisGracePharma/PlanSummary";
import Plan from "./Pages/Plans/Plan/Plan";
import PlanCol from "./Pages/Plans/PlanCol/PlanCol";
import PlanMedReps from "./Pages/Plans/PlanMedReps/PlanMedReps";
import Schedule from "./Pages/Schedule/Schedule";
import ShowMap from "./Pages/Show";
import TrackMedRep from "./Pages/TrackMedRep/TrackMedRep";

function App() {
  // const [user] =useState(getToken())
  // const navigate =useNavigate();

  // useEffect(() => {
  //   if(user){
  //     navigate('/')
  //   }
  // }, [navigate, user])
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<Login />} />

        {/* <Route path="/" element={<DashBoard />} /> */}
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Overview />} />

          <Route path="/overview" element={<Overview />} />
          <Route path="/activities/schedule" element={<Schedule />} />
          <Route path="/activities/call_reports" element={<CallReports />} />
          <Route path="/activities/events" element={<Events />} />
          <Route path="/track" element={<TrackMedRep />} />
          <Route path="/account/hcp" element={<HealthCare />} />
          <Route path="/account/med_rep" element={<MedRepMain />} />
          <Route
            path="/account/hcp_list/:createdById/:id"
            element={<HCPList />}
          />
          <Route
            path="/account/health_care_organization"
            element={<HealthCareOrganization />}
          />
          <Route path="/his_grace_pharmacy" element={<HisGracePharmacy />} />
          <Route
            path="/add_new_health_care_practitioner/:id"
            element={<NewHealthCare />}
          />
          <Route
            path="/add_new_health_care_practitioner_2"
            element={<NewHealthCareii />}
          />
          <Route
            path="/add_new_health_care_practitioner_3"
            element={<NewHealthCareiii />}
          />
          <Route
            path="/add_new_health_care_practitioner_med_rep"
            element={<NewHealthCareMedRep />}
          />
          <Route path="/media" element={<Media />} />
          <Route path="/media/:id" element={<SingleMedia />} />
          <Route path="/panadol_efficacy" element={<PanadolEfficacy />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/plan_overview" element={<Plan />} />
          <Route path="/plan_overview/:page" element={<Plan />} />
          <Route path="/plan_med_reps" element={<PlanMedReps />} />
          <Route path="/plan_col" element={<PlanCol />} />
          <Route path="/plan_col/:medRepId" element={<PlanCol />} />
          <Route path="/plan_his_grace_pharmacy" element={<HisGracePharma />} />
          <Route path="/plan/:hcp" element={<HisGracePharma />} />
          <Route path="/plan/summary" element={<PlanSummary />} />

          <Route path="/plan/edit/:plan_id" element={<PlanEdit />} />
          <Route path="/plan/add" element={<AddPlan />} />

          <Route path="/plan_department" element={<Department />} />
          <Route path="/cycle_plan" element={<CyclePlan />} />
          <Route path="/show/:medRepUserId/:scheduleId" element={<ShowMap />} />

          <Route element={<ProtectedRoute user={{}} />}>
            <Route path="/" element={<DashBoard />} />
          </Route>
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Route>
      </Routes>
    </Provider>
  );
}

const Home = () => {
  return <h2>Home (Protected: authenticated user required)</h2>;
};

export default App;
