import React from "react";
import { ProgressBar } from "react-bootstrap";

export default function AboutMedRep({ Data }) {
  return (
    <div className={` border-l-[#E5E5EF] border-l-[1px]`}>
      <div className="m-[5px] bg-white h-full p-2 pb-5 mb-3">
        <div className="text-black mb-3">#12343</div>

        <div className=" text-center text-[#555555] font-2">
          <div className="flex justify-center">
            <img
              src="/Images/noImage.png"
              className="w-[64px] h-[64px] object-cover"
            />
          </div>
          <div className="text-[18px]">
            {Data?.firstName || "Olakunbi"} {Data?.lastName || `Olabode 0.`}{" "}
          </div>
          <div className="text-[14px] mb-[5px]">
            {Data?.email || `olakunbiolabode@gmail.com`}
          </div>
          <div className="text-[14px]">Ikorodu</div>
          <div className="text-[10px] mb-5">
            Last Visit: April 5, 2023 at 10:25AM
          </div>

          <div className="flex justify-around">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="36"
                height="36"
                rx="18"
                fill="#A162F7"
                fill-opacity="0.1"
              />
              <path
                d="M26.3082 23.2751C26.3082 23.5751 26.2415 23.8834 26.0998 24.1834C25.9582 24.4834 25.7748 24.7667 25.5332 25.0334C25.1248 25.4834 24.6748 25.8084 24.1665 26.0167C23.6665 26.2251 23.1248 26.3334 22.5415 26.3334C21.6915 26.3334 20.7832 26.1334 19.8248 25.7251C18.8665 25.3167 17.9082 24.7667 16.9582 24.0751C15.9998 23.3751 15.0915 22.6001 14.2248 21.7417C13.3665 20.8751 12.5915 19.9667 11.8998 19.0167C11.2165 18.0667 10.6665 17.1167 10.2665 16.1751C9.8665 15.2251 9.6665 14.3167 9.6665 13.4501C9.6665 12.8834 9.7665 12.3417 9.9665 11.8417C10.1665 11.3334 10.4832 10.8667 10.9248 10.4501C11.4582 9.92508 12.0415 9.66675 12.6582 9.66675C12.8915 9.66675 13.1248 9.71675 13.3332 9.81675C13.5498 9.91675 13.7415 10.0667 13.8915 10.2834L15.8248 13.0084C15.9748 13.2167 16.0832 13.4084 16.1582 13.5917C16.2332 13.7667 16.2748 13.9417 16.2748 14.1001C16.2748 14.3001 16.2165 14.5001 16.0998 14.6917C15.9915 14.8834 15.8332 15.0834 15.6332 15.2834L14.9998 15.9417C14.9082 16.0334 14.8665 16.1417 14.8665 16.2751C14.8665 16.3417 14.8748 16.4001 14.8915 16.4667C14.9165 16.5334 14.9415 16.5834 14.9582 16.6334C15.1082 16.9084 15.3665 17.2667 15.7332 17.7001C16.1082 18.1334 16.5082 18.5751 16.9415 19.0167C17.3915 19.4584 17.8248 19.8667 18.2665 20.2417C18.6998 20.6084 19.0582 20.8584 19.3415 21.0084C19.3832 21.0251 19.4332 21.0501 19.4915 21.0751C19.5582 21.1001 19.6248 21.1084 19.6998 21.1084C19.8415 21.1084 19.9498 21.0584 20.0415 20.9667L20.6748 20.3417C20.8832 20.1334 21.0832 19.9751 21.2748 19.8751C21.4665 19.7584 21.6582 19.7001 21.8665 19.7001C22.0248 19.7001 22.1915 19.7334 22.3748 19.8084C22.5582 19.8834 22.7498 19.9917 22.9582 20.1334L25.7165 22.0917C25.9332 22.2417 26.0832 22.4167 26.1748 22.6251C26.2582 22.8334 26.3082 23.0417 26.3082 23.2751Z"
                stroke="#555555"
                stroke-width="1.5"
                stroke-miterlimit="10"
              />
            </svg>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="36"
                height="36"
                rx="18"
                fill="#A162F7"
                fill-opacity="0.1"
              />
              <path
                d="M18.0001 25.0834H13.8334C11.3334 25.0834 9.66675 23.8334 9.66675 20.9167V15.0834C9.66675 12.1667 11.3334 10.9167 13.8334 10.9167H22.1667C24.6667 10.9167 26.3334 12.1667 26.3334 15.0834V17.5834"
                stroke="#555555"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.1666 15.5L19.5582 17.5833C18.6999 18.2667 17.2916 18.2667 16.4332 17.5833L13.8333 15.5"
                stroke="#555555"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M24.0083 20.3083L21.0583 23.2583C20.9416 23.375 20.8333 23.5917 20.8083 23.75L20.6499 24.875C20.5916 25.2833 20.875 25.5667 21.2833 25.5083L22.4083 25.35C22.5666 25.325 22.7916 25.2167 22.9 25.1L25.85 22.15C26.3583 21.6417 26.6 21.05 25.85 20.3C25.1083 19.5583 24.5166 19.8 24.0083 20.3083Z"
                stroke="#555555"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23.5833 20.7334C23.8333 21.6334 24.5333 22.3334 25.4333 22.5834"
                stroke="#555555"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="36"
                height="36"
                rx="18"
                fill="#A162F7"
                fill-opacity="0.1"
              />
              <path
                d="M14.4446 10V12.4"
                stroke="#555555"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.5554 10V12.4"
                stroke="#555555"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.4443 15.6721H25.5554"
                stroke="#555555"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M26 15.2002V22.0002C26 24.4002 24.6667 26.0002 21.5556 26.0002H14.4444C11.3333 26.0002 10 24.4002 10 22.0002V15.2002C10 12.8002 11.3333 11.2002 14.4444 11.2002H21.5556C24.6667 11.2002 26 12.8002 26 15.2002Z"
                stroke="#555555"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.9962 19.36H18.0042"
                stroke="#555555"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7062 19.36H14.7141"
                stroke="#555555"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.7062 21.7601H14.7141"
                stroke="#555555"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="m-[5px] bg-white h-full p-2 pt-4 mb-3">
        <div className="text-center text-[#333333] mb-[25px]">
          {" "}
          Top Visited Institutions
        </div>
        <div className="text-[14px] mb-3">
          <div className="mb-1">General Hospital</div>
          <ProgressBar now={80} />
        </div>
        <div className="text-[14px] mb-3">
          <div className="mb-1">LASUTH</div>
          <ProgressBar now={50} />
        </div>
        <div className="text-[14px] mb-3">
          <div className="mb-1">Graceland Hospital</div>
          <ProgressBar now={90} />
        </div>
        <div className="text-[14px] mb-3">
          <div className="mb-1">May and Baker pharm.Ltd</div>
          <ProgressBar now={20} />
        </div>
      </div>

      <div className="m-[5px] bg-white p-2 pt-4 mb-3 h-[10vh] w-full"></div>
    </div>
  );
}
