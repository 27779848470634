import React from 'react'
import { TableComp } from '../../../Components/Common/TableComp'
import { columnsData, columnsDataII, tableData } from '../Data'
import CallReportCard from './CallReportCard'
import { LanguageName, storage } from '../../../utils/helper';
import { useGetCallReportQuery } from '../../../store/CallReport/CallReportApi';
import { useGetSchedulesQuery } from '../../../store/Schedules/SchedulesApi';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'

export default function OverviewDashboard() {
  const { t } = useTranslation();
  const navigate=useNavigate();
  const companyId = storage["localStorage"].get("companyId")?._id;
  const { data: allCallReport, isLoading: CRloader } = useGetCallReportQuery(companyId,{
    skip: !companyId, 
  });
  const {data:ScheduleData,isLoading } =useGetSchedulesQuery();
  // console.log(allCallReport,'allCallReport')
  return (
    <div>
        {/* <div className="border border-1 rounded p-[22px] mb-[32px]">
        <div className="flex justify-content-between align-items-center mb-[29px]">
          <div className="text-[#1E1B39] font-instrument-sans text-[20px] font-[600]">
            Track Med Reps
          </div>
          <div className="text-[#4F008A] font-instrument-sans text-[14px] font-[500]">
            Open Map
          </div>
        </div>
        <div className="">
          <img src="/Overview/track-med-reps.svg" alt="" />
        </div>
      </div> */}
      {/*  */}

      <div className="border px-3 mb-[32px] shadow-md">
        <div className="flex justify-between items-center py-[20px] px-3">
          <div className="text-[#1E1B39] text-[22px] font-semibold">
            
            {LanguageName('RS')}
          </div>
          <div className="text-[#4F008A] text-[14px] font-medium cursor-pointer" onClick={()=>{
            // activities/Schedule
            navigate(`/activities/Schedule`)
          }}> {LanguageName('VA')}</div>
        </div>
        <TableComp loader={isLoading} columnsComp={columnsDataII()} Data={ScheduleData?.data?.schedules?.slice(0, 10)} />
      </div>

      {/*  */}

      <div className="row border border-1 rounded px-[10px] pb-[15px]">
        <div className="flex justify-content-between align-items-center py-[30px]">
          <div className="text-[20px] font-[600] text-[#1E1B39]">
          {LanguageName('RPR')}
            
          </div>
          <div className="text-[14px] font-[500] text-[#4F008A]">{LanguageName('VA')}</div>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
          {Array?.isArray(allCallReport?.data?.reports)&& allCallReport?.data?.reports.slice(0,3)?.map((item, i) => (
            <CallReportCard item={item} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}
