import moment from "moment";
import { ImageComponent } from "../../../Components/Common/FallbackImage";
import {
  Dots,
  TrackIcon,
  TrashIcon,
  UserProfileIcon,
} from "../../../utils/svg";
import { FaUserAlt } from "react-icons/fa";
import DropDownComp from "../../../Components/Ui/DropDown";
import { useNavigate } from "react-router-dom";
import { LanguageName } from "../../../utils/helper";
// import {}
export const healthData = [
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
];

export const columnsData = (handleDisableHcp) => {
  return [
    {
      name: "Id",
      selector: (row) => <span>#{row._id?.substring(0, 6)}</span>,
      sortable: true,
    },
    {
      name: "HCP Info",
      selector: (row) => (
        <div className="flex items-start gap-2">
          <div>
            <FaUserAlt size={30} />
            {/* <ImageComponent
                src={UserProfileIcon}
                /> */}
          </div>
          <div className="flex-col">
            <div className="whitespace-nowrap font-medium capitalize text-[18px]">
              {row.firstName} {row.lastName}
            </div>
            <div className="text-[10px]">
              <span className="text-[#777777]">{row?.position} at </span>
              <span className="text-[#3DA84A]">{row?.institutionName}</span>
            </div>
          </div>
        </div>
      ),
      width: "250px",
      // sortable: true,
    },
    // {
    //   name: "department",
    //   selector: (row) => row.department,
    //   // sortable: true,
    // },
    {
      name: LanguageName("Specialty"),
      selector: (row) => row.specialty,
      // sortable: true,
    },
    {
      name: LanguageName("PNN"),
      selector: (row) => row.phoneNumber,
      width: "200px",
      // sortable: true,
    },
    {
      name: LanguageName("MR"),
      selector: (row) => (
        <span>
          {row?.createdBy?.firstName} {row?.createdBy?.lastName}
        </span>
      ),
      // sortable: true,
    },
  
    {
      name: LanguageName("Status"),
      selector: (row) => "-",
      // sortable: true,
    },
    {
      name: LanguageName("DA"),
      selector: (row) => moment(row?.createdAt).format("MMMM Do YYYY, h:mm"),
      width: "200px",
      // sortable: true,
    },
    {
      name: LanguageName("Action"),
      // sortable: true,
      cell: (row) => (
        <div className="ms-3">
          <DropDownComp
            dropDownText={<Dots color="#7572EA" />}
            options={OptionDropDown(row,handleDisableHcp)}
          />
        </div>
      ),
      width: "180px",
    },
  ];
}

const OptionDropDown = (row,handleDisableHcp) => {
  const navigate = useNavigate();
  return [
    {
      name: (
        <div
          className="flex gap-1 items-center text-[#656468] text-[12px]"
          onClick={() =>
            navigate(`/account/hcp_list/${row?.createdBy?._id}/${row?._id}`, {
              state: row,
            })
          }
        >
          <TrackIcon />
          View
        </div>
      ),
      // action:;
    },
    {
      name: (
        <div className="flex gap-1 items-center text-[#656468] text-[12px]">
          <TrackIcon />
          Check Profile
        </div>
      ),
      // action:;
    },
    {
      name: (
        <div onClick={()=>handleDisableHcp(row?._id)} className="flex gap-1 items-center text-[#D63434] text-[12px]">
          <TrashIcon />
          De-activate HCP
        </div>
      ),
      // action:;
    },
  ];
};
