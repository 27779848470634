import React,{useEffect} from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { UserProfileIcon } from "../../../utils/svg";
import FormUi from "../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";

export default function UpdateEvent({singleEvent}) {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isValid },
    setError,
  } = useForm({
    defaultValues: {
      name: "Event Name",
      type: "Event Type",
      description: "Event Description",
      institution: "institution",
      location: "location",
      date: "2023-09-11",
      time: "10",
      recurring: true,
    },
  });
  const imageUrl =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAB8CAMAAACcwCSMAAAAWlBMVEX///9UWV1QVVlxdHhKT1Sho6VNUldHTFFARkt6fYDv8PBESk/6+vo7QkdhZWnW19iNkJLLzM2tr7G/wMGBhYdqbnG2uLnn5+haX2OXmpyHi43f4ODFxsc2PUJ9iTJsAAADL0lEQVRoge2a67qqIBBABRFFLt4ztd7/NY+2T9+uThFDg50frBdYDgwDDiRJJBKxU1anrBZpKursVJV7mscuPXLJOF3hTPJj2o07qaueSEruoJL01R7qtODkCbRIQ+vL+UyfqS/68xx08k/506iv8PwUzj3lNvVGPoVyi7fu1S6CqJWLe7OrAPLZyb3aZ3z3JN3chEj0ee+c3au9w3WPL1f3MyhusRXW9f0IR035poC4CSkaRLmBuQkxeO4TMPA1dLw6K0DptkHRZn0EB74uN6yEbx1r2y15iyRPwaO+jnuK4x7h6g2cca88pnzNd5xDlc+Uo016DSqtV3iNIvfJN7SMG/zkA47cx40m/2rk35R/NeFmv6WGc4htmY+c4RQZ6BnqB6ST1Og17EgnWOW3pSL9NU0eoXOs35ZFw+V6QZJ7jDvaqCfJAfCj9oM8YLkTBXUTgviTfgAeZnK8wFeOsL/UI6Y7WUCh51ip/pcMYM8zXHeS9M7bC+ux3Uk5ONY5PgToQ5bEKekoCdIDVcQhdo65wu/s79uAYVqAP2TMOvSUoef5LZWxBJ+b0B33tnix5liB1Q+woNqj/ifzuCZtuNm+o6qNltv1zjrNlHKpTb3HDcsVNXZTnw4raT91405B332BKkv1BW8kshdKjVXXZlM99xfmesrarhpD571q2loQrQvJGL/cIV/ukTljstCaiLptwlxoLt3Mztu1tWVT40ye2dwhHx+r7LjGattNb8o8YySrsOZgyQwDNicYMxlC/KoRUPPVL5oPwz8N2qsXtUH18MlNS2MKb/VFXxjfzkwpHp9GeOil8Fp8B+7VCHqEc/gfazmDGwKvkNCnFKPxSvHnMANqiy2fJdojtAAs+uqMqd44Ox8wG7Tp/kU6rrkFdcivUKeRL00YuXHJeYGY57cwh4tleMPPlfeNQeXRZ3VFv9vlfDrMrrzrRPvc07tT2CtdyMDfhV6GVG/YlhvkEZIP1odL8GchMGyPSErwYxwoljK3BCpuv7DXFT7EdnaPZXPzex0BwfKSIgs/7K9blFEe5VEe5VEe5VEe5Z/JJQ2MfC1vhzQwww5XnpHI/8gfxuA0gYKZOkUAAAAASUVORK5CYII=";
  const FormList = [
    {
      label: "Event Type",
      name: "type",
      className: "col-6",
    },
    {
      label: "Event Title",
      name: "name",
      className: "col-6",
    },
    {
      label: "Description",
      name: "description",
      className: "col-12",
    },
    {
      label: "Choose Institution",
      name: "institution",
      className: "col-6",
    },
    {
      label: "Department",
      name: "h",
      className: "col-6",
    },
    {
      label: "Event Method",
      name: "h",
      className: "col-6",
    },
    {
      label: "Address",
      name: "h",
      className: "col-6",
    },
  ];

  useEffect(() => {
  
    return () => {
        setValue('description',singleEvent?.description);
        setValue('type',singleEvent?.type);
        setValue('institution',singleEvent?.institution);
        setValue('description',singleEvent?.description);
        setValue('description',singleEvent?.description);
    }
  }, [singleEvent?._id])
  

  return (
    <div className="bg-[#FCFAFF]">
      <div className="h-[20px]"></div>
      <div className="flex justify-between items-center mb-[20px]">
        <div className="text-[#242731] text-[24px] font-semibold ">
          Create Event
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-2 2xl:gap-7.5">
          <ButtonComp
            text={"Save as draft"}
            wrapper={""}
            btnClassName={
              "w-100  text-[#8F0808] py-[10px] font-normal whitespace-nowrap"
            }
          />
          <ButtonComp
            text={"Create"}
            wrapper={" "}
            btnClassName={
              "w-100 bg-[#3DA84A] text-white font-normal py-[10px] rounded-md"
            }
          />
        </div>
      </div>
      <div className="flex gap-3 mb-5">
        <img
          className="w-[50px] h-[50px] rounded-full object-cover"
          src={imageUrl}
        />
        <div className="flex-col justify-between ">
          <div className="font-medium">Olakunbi Olabode 0. (ANCHOR)</div>
          <div className="text-[12px]">
            Med Rep at{" "}
            <span className="text-[#3DA84A]">
              May and Baker Pharmaceutical LTD
            </span>
          </div>
        </div>
      </div>

      <div className="mx-2 row gy-4 gx-5 bg-[#FFFFFF] mb-4">
        <div className="col-12 text-[16px] font-semibold">Event  Information</div>
        <FormUi
          formData={FormList?.slice(0,3)}
          control={control}
          containerClassName={"h-[35px] bg-[#FCFAFF] border-0"}
          labelClassName="!text-[14px] mb-1 text-[#555555]"
        />
      </div>
      <div className="mx-4 row gy-4 gx-5 bg-[#FFFFFF] mb-4">
        <div className="col-12 text-[16px] font-semibold">Add Institution </div>
        <FormUi
          formData={FormList?.slice(3,5)}
          control={control}
          containerClassName={"h-[35px] bg-[#FCFAFF] border-0"}
          labelClassName="!text-[14px] mb-1 text-[#555555]"
        />
      </div>

      <div className="mx-4 row gy-4 gx-5 bg-[#FFFFFF] mb-4">
        <div className="col-12 text-[16px] font-semibold">Location </div>
        <FormUi
          formData={FormList?.slice(5,7)}
          control={control}
          containerClassName={"h-[35px] bg-[#FCFAFF] border-0"}
          labelClassName="!text-[14px] mb-1 text-[#555555]"
        />
      </div>
    </div>
  );
}
