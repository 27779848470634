import React from "react";
import InputComp from "./InputComp";
import { Controller } from "react-hook-form";
import SelectComp from "./SelectComp";
import CheckboxComp from "./Checkbox";
import SelectCompI from "./SelectCompI";
import { useTranslation } from "react-i18next";
import useMultiDateSelect from "./UseMultiDateSelect.jsx";

export default function NewFormUI({
  formData = [],
  control,
  setSelectedHCP,
  containerClassName,
  wrapperClassName,
  labelClassName,
}) {
  const { t } = useTranslation();
  return formData?.map((item, i) => {
    if (item?.type === "icon") {
      return <div>{item?.icon}</div>;
    }

    return (
      <Controller
        key={i}
        name={item?.name}
        rules={{
          required: `${item?.label} is required`,
          pattern: item?.pattern,
          minLength: item?.minLength,
        }}
        control={control}
        render={({
          field: { onChange, onBlur, value },
          formState: { errors },
        }) => {
          switch (item?.type) {
            case "select":
              return (
                <SelectCompI
                  label={item?.label}
                  labelClassName={item?.labelClassName || labelClassName}
                  wrapperClassName={wrapperClassName || item?.className}
                  containerClassName={
                    containerClassName || item?.containerClassName
                  }
                  value={{
                    value: item?.value,
                    label: item?.label2,
                  }}
                  onChange={(e) => {
                    console.log("event", e);
                    setSelectedHCP(e?.full);
                    onChange(e);
                  }}
                  error={errors[item?.name]?.message}
                  options={item?.options}
                  placeholder={item?.placeholder}
                />
              );
            case "input":
            case "password":
            case "date":
            case "":
              return (
                <InputComp
                  label={item?.label}
                  labelClassName={labelClassName || item?.labelClassName}
                  wrapperClassName={wrapperClassName || item?.className}
                  containerClassName={
                    containerClassName || item?.containerClassName
                  }
                  value={value || item?.value}
                  onChange={onChange}
                  placeholder={item?.placeholder}
                  error={errors[item?.name]?.message}
                  type={item?.type}
                />
              );
            case "upload":
              return (
                <InputComp
                  label={item?.label}
                  // containerClassName={item}
                  value={value}
                  onChange={(e) => onChange(e.target.files)}
                  error={errors[item?.name]?.message}
                  type={"file"}
                  accept={item?.accept}
                />
              );
            case "checkbox":
              return (
                <CheckboxComp
                  label={item?.label}
                  // containerClassName={item}
                  value={value}
                  onChange={onChange}
                  error={errors[item?.name]?.message}
                />
              );
            case "multiDate":
              return (
                <div>
                  <div className={`mb-[10px] font-medium `}>Select Date</div>
                  <div className="w-full border-2 rounded-[4px]">
                    {item?.component}
                  </div>
                </div>
              );
            default:
              return null;
          }
        }}
      />
    );
  });
}
