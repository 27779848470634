import React from "react";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { ImageComponent } from "../Common/FallbackImage";
import ButtonComp from "./Button";

export default function ModalComp({ show, handleClose,bodyComponents,size }) {
  return (
    <Modal show={show} onHide={handleClose} centered size={size||'md'}>
      {/* <Modal.Header closeButton>
      <Modal.Title>Modal heading</Modal.Title>
    </Modal.Header> */}
      <Modal.Body>
        <div className="flex justify-end absolute left-0 right-[20px]">
          <div className="bg-[#FBEBEB] rounded-full p-1 w-[36px] h-[36px] items-center justify-center flex mb-[20px] cursor-pointer" onClick={handleClose}>
            {" "}
            <IoMdClose size={20} color="#D63434" />
          </div>
        </div>
        <div>{bodyComponents}</div>
      </Modal.Body>
    </Modal>
  );
}
