import React, { useState } from "react";
import ButtonComp from "../../Components/Ui/Button";
import { TopHeaderCount } from "./Data";
import TopHeaderCounts from "../../Components/Common/TopHeaderCounts";
import Skeleton from "react-loading-skeleton";
import { LanguageName } from "../../utils/helper";
import DownloadReport from "../../Components/Common/CsvDownload";

export default function CallReportsComp({
  Data,
  setUserData,
  setModal,
  setOffCanvas,
  CRloader,
}) {
  const [headerSelected, setHeaderSelected] = useState("Total Call Reports");
  const [searchText, setSearchText] = useState("");
  // console.log(Data,'Data')

  function filterLeadsData(Data, searchField) {
    return Data?.filter((item) => {
      const itemName = item?._id?.toLowerCase() || "";
      const itemCompany = item?.medRep?.firstName?.toLowerCase() || "";
      const itemStatus = item?.status?.toLowerCase() || "";
      const search = searchField?.toLowerCase() || "";

      return (
        itemName.includes(search) ||
        itemCompany.includes(search) ||
        itemStatus.includes(search)
      );
    });
  }

  //  const headers = [
  //       { label: 'Id', key: '_id' },
  //       { label: 'Last Name', key: 'details.lastName' },
  //       { label: 'schedule', key: 'schedule' },
  //       { label: 'Personal Information', key: 'personalInformation.' },
  //       { label: 'schedule', key: 'schedule' },
  //     ];

  return (
    <div>
      <div className="container">
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem]">
            <div className="text-[25px] font-[700]">{LanguageName("CR")}</div>
            <div className="ms-2">
              <img src="/icon/info-circle.svg" alt="" />
            </div>
          </div>
          <div className="">
            <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
              <div className="me-1">
                <img src="/Schedule/document-download.png" alt="" />
              </div>
              <DownloadReport data={!CRloader &&
                filterLeadsData(Data, searchText)} filename="Call Report_file">
              <ButtonComp
                className="text-[#44AA43] font-[500] text-[16px] "
                text={LanguageName("DR")}
              />
              </DownloadReport>
            </div>
          </div>
        </div>
        <div className="flex lg:mb-[20px] md:mb-[45px] mb-[45px]">
          {TopHeaderCount(Data)?.map(({ name, count,findBy }, i) => (
            <TopHeaderCounts
              key={i}
              name={name}
              count={count}
              isActive={headerSelected === name}
              setHeaderSelected={setHeaderSelected}
              Data={(status)=>{
                // console.log("Please select",findBy);
                setSearchText(findBy)
              }}
              status={findBy}
            />
          ))}

          <div className="w-[8.5rem] lg:border-b-2">{""}</div>

          <div className="w-[8.5rem] lg:border-b-2">{""}</div>
        </div>
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="border-r-2 ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>
            <input
              className="w-100 text-[16px] font-[400]"
              type="text"
              placeholder={LanguageName("SCR")}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">{LanguageName("SB")}</div>{" "}
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">
                {" "}
                {LanguageName("CTY")}: {LanguageName("All")}
              </div>{" "}
              <div className="">
                <img src="/icon/select-arrows.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#4F008A08] rounded px-2 pt-2 pb-4   mb-[20px]">
          <div className=" rounded px-[10px] pt-[15px]">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
              {CRloader &&
                [1, 2, 3]?.map((item) => (
                  <Skeleton key={item} height={"300px"} />
                ))}

              {!CRloader &&
                filterLeadsData(Data, searchText)?.map((item, i) => (
                  <div className="" key={i}>
                    <div className="flex flex-col rounded py-[15px] lg:px-[15px] px-[20px] bg-[#FFFFFF]  lg:mb-0 mb-[20px]">
                      <div className="flex lg:justify-content-normal justify-content-between ">
                        <div className="">
                          <img src="/Overview/phone.svg" alt="" />
                        </div>
                        <div className="text-[15px] font-[500]  mb-[19px] ">
                        {item?.personalInformation?.hcp?.firstName}   {item?.personalInformation?.hcp?.lastName}                         <br />
                          <span className="text-[10px] font-[400] text-[#777777] pe-[30px]">
                          {item?.personalInformation?.hcp?.position}  at
                            <span className="ps-1 text-[10px] font-[400] text-[#3DA84A]">
                              {item?.personalInformation?.hcp?.institutionName}
                            </span>
                          </span>
                        </div>
                        <div className="">
                          {/* <img src="/Overview/more.svg" alt="" /> */}
                        </div>
                      </div>
                      <div className="">
                        <div className="flex lg:justify-content-normal justify-content-between mb-[25px]">
                          <div className="bg-[#FEECEC80] font-[500] text-[12px] px-2 py-1 rounded-full flex align-items-center me-2">
                            <div className="me-2">
                              <img src="/Overview/person.svg" alt="" />
                            </div>
                            {item?.medRep?.firstName} {item?.medRep?.lastName}

                         </div>
                          <div className="bg-[#F3F1FB] px-2 py-1 rounded-full font-[500] text-[12px]">
                            KOL
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="mb-[17px] text-[13px] font-[500]">
                          <span className="text-[11px] font-[500]  text-[#555555]">
                            {LanguageName("Departments")}
                          </span>{" "}
                          <br />    {item?.personalInformation?.hcp?.department}
                        </div>
                        <div className=" text-[13px] font-[500]">
                          <span className="text-[11px] font-[500]  text-[#555555]">
                            {LanguageName("CO")}
                          </span>{" "}
                          <br />{item?.notes?.objectionNotes}
                        </div>
                      </div>
                      <div className="flex justify-content-between align-items-center mt-[20px]">
                        <ButtonComp
                          onClick={() => {
                            setUserData(item);
                            setOffCanvas(true);
                          }}
                          className="text-[#4F008A] text-[14px] font-[500]  border-[0.57px] rounded border-[#8354CF73] py-[9px] lg:px-[30px] md:px-[30px] px-[20px]"
                          text={LanguageName("VD")}
                        />
                        <ButtonComp
                          className={`text-[#FFFFFF] text-[14px] font-[500]  border-[0.57px] rounded border-[#8354CF73] bg-[#8354CF] py-[9px] lg:px-[30px] md:px-[30px] px-[28px] ${
                            item?.status === "approved" ? "opacity-30" : ""
                          }`}
                          text={LanguageName("Approve")}
                          disabled={item?.status === LanguageName("Approved")}
                          onClick={() => {
                            setUserData(item);
                            setModal(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {!CRloader && filterLeadsData(Data, searchText)?.length === 0 && (
              <div className="text-center py-5">No Call Report Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
