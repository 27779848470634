import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const EventApi = createApi({
  reducerPath: "EventApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
   
    
    getEvent: builder.query({
      query: () => ({
        url: `/events`,
        method: "GET",
      }),
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
    }),
    getHOC: builder.query({
      query: () => ({
        url: `/hcp/hcos`,
        method: "GET",
      }),
    }),
    createHCP: builder.mutation({
      query: (payload) => ({
        url: `/hcp`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags:['getMedia']
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
  }),
});

//hcp/hcos

export const {
useGetEventQuery,
useCreateHCPMutation,
useGetHOCQuery
} = EventApi;
