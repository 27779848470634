import React, { useState } from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { HCOData } from "./Data";
import Skeleton from "react-loading-skeleton";
import { LanguageName } from "../../../utils/helper";

export default function HealthCareOrganizationComp({ Data, isLoading }) {
  const [searchText, setSearchText] = useState("");

  function filterLeadsData(Data, searchField) {
    return Data?.filter((item) => {
      const itemName = item?._id?.toLowerCase() || "";
      const MedFirstName = item?.medRep?.firstName?.toLowerCase() || "";
      const MedLastName = item?.medRep?.firstName?.toLowerCase() || "";
      const itemStatus = item?.status?.toLowerCase() || "";
      const search = searchField?.toLowerCase() || "";
      const HCPName = item?.hcp?.name?.toLowerCase() || "";

      //hcp
      return (
        itemName.includes(search) ||
        MedFirstName.includes(search) ||
        MedLastName.includes(search) ||
        HCPName.includes(search) ||
        itemStatus.includes(search)
      );
    });
  }
  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700] md:text-left text-center">
            {LanguageName("HCO")}
          </div>
          <div className="ms-2">
            <img src="/icon/info-circle.svg" alt="" />
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName("DR")}
            />
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
        <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
          <div className="border-r-2 ps-2 pe-3">
            <img className="" src="/icon/search.png" alt="search" />
          </div>
          {/* <input className="border-0 mb-3 ">
               <form
                 className="border-0"
                 placeholder="Search for Call Reports by ID, Name, Date, HCOs"
                 aria-label="Search for Call Reports by ID, Name, Date, HCOs"
                 aria-describedby="basic-addon2"
               ><form
             </input> */}
          <input
            className="w-100 text-[16px] font-[400]"
            type="text"
            placeholder={LanguageName("SCR")}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
          <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
            <div className="">{LanguageName("SB")}</div>
            <div className="">
              <img src="/icon/sort.png" alt="" />
            </div>
          </div>
          <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
            <div className="">
              {LanguageName("CTY")}: {LanguageName("All")}
            </div>{" "}
            <div className="">
              <img src="/icon/select-arrows.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row !bg-[#4F008A08] pt-[30px] rounded">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
          {isLoading &&
            [1, 2, 3]?.map((item) => <Skeleton key={item} height={"300px"} />)}

          {!isLoading &&
            filterLeadsData(Data, searchText)?.map((item, i) => (
              <div className="bg-[#FFFFFF] rounded mb-[30px] p-3" key={i}>
                <div className="flex justify-content-between align-items-center  mb-[20px]">
                  <div className=" flex">
                    <div className=" me-2">
                      <img src="/HCO/hospital.png" alt="Hospital" />
                    </div>
                    <div className="text-[18px] text-[#000000] font-[600]">
                      {item.institutionName} <br />
                      <span className="text-[12px] text-[#777777] font-[400]">
                        {item.institutionName}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <img src="/icon/more.svg" alt="" />
                  </div>
                </div>
                <div className="flex space-x-2 align-items-center bg-[#FEECEC80] rounded-full py-1 px-3 w-[fit-content]">
                  <div className="">
                    <img src="/HCO/users.png" alt="" />
                  </div>
                  <div className="text-[13px] font-[500] text-[#000000]">
                    {item.medRep?.firstName} {item.medRep?.lastName}
                  </div>
                </div>
                <div className="text-[12px] text-[#555555] font-[500] my-[15px]">
                  {LanguageName("Departments")}
                </div>
                <div className="flex space-x-[0.7rem] mb-[15px]">
                  {item.departments?.map((item, i) => (
                    <div className="text-[12px] text-[#000000] font-[500] bg-[#F3F1FB] rounded-full py-1 px-[0.7rem]">
                      {item}
                    </div>
                  ))}
                </div>
                {/* <div className="flex space-x-[0.7rem] mb-[30px]">
                <div className="text-[12px] text-[#000000] font-[500] w-[fit-content] bg-[#F3F1FB] rounded-full py-1 px-[0.7rem]">
                  {item.opthamology}
                </div>
                <div className="text-[12px] text-[#000000] font-[500] w-[fit-content] bg-[#F3F1FB] rounded-full py-1 px-[0.7rem]">
                  {item.medicine}
                </div>
                <div className=""></div>
              </div> */}
                <div className="flex justify-content-between align-items-center">
                  <div className="">
                    <img src="/HCO/people.png" alt="" />
                  </div>
                  <ButtonComp
                    className="text-[14px] text-[#4F008A] font-[500] border py-1 px-4"
                    text={LanguageName("VAH")}
                  />
                </div>
              </div>
            ))}
        </div>
        {filterLeadsData(Data, searchText)?.length === 0 && (
          <div className="text-center py-5">No HOC Found</div>
        )}
      </div>
    </div>
  );
}
