import React, { useState } from "react";
import BarChartVertical from "../../../Components/Common/Charts/BarChartVertical";
import { BarChart } from "recharts";
import BarChartComp from "../../../Components/Common/Charts/BarChart";
import { LanguageName } from "../../../utils/helper";

export default function Analysis() {
  const [selectedSession, setSelectedSession] = useState("Daily");
  const session = ["Daily", "Monthly", "Annually"];
  return (
    <div>
      <div className="p-[40px]  border-[#4F008A1A] border-[1px] rounded-[10px]">
        <div className="flex justify-between items-center">
          <div>
            <div className="text-[#272B3F] text-[14px] mb-1">
              {LanguageName("TV")}
            </div>
            <div className="text-[24px] text-[#272B3F] font-medium">
              {LanguageName("Departments")}
            </div>
          </div>

          <div className="bg-[#F6F6F6] py-[7px] px-[13px] rounded-lg flex items-center gap-[5px]">
            {session?.map((item, i) => (
              <div
                className={`px-[12px] py-[9px] rounded-sm text-[12px] ${
                  item === selectedSession ? "bg-[#000000] text-white" : ""
                } cursor-pointer`}
                onClick={() => setSelectedSession(item)}
                key={i}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <BarChartVertical />
      </div>

      <div className="row mt-5">
        <div className="col-7">
          <div className="p-[40px]  border-[#4F008A1A] border-[1px] rounded-[10px]">
            <div className="flex justify-between items-center">
              <div>
                <div className="text-[#272B3F] text-[12px] mb-1">
                  % {LanguageName("Coverage")}
                </div>
                <div className="text-[18px] text-[#272B3F] font-medium">
                  {LanguageName("IT")}
                </div>
              </div>

              <div className="bg-[#F6F6F6] py-[7px] px-[13px] rounded-lg flex items-center gap-[5px]">
                {session?.map((item, i) => (
                  <div
                    className={`px-[12px] py-[9px] rounded-sm text-[12px] ${
                      item === selectedSession ? "bg-[#000000] text-white" : ""
                    } cursor-pointer`}
                    onClick={() => setSelectedSession(item)}
                    key={i}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>

            <BarChartComp />
          </div>
        </div>
        <div className="col-5">
          <div className="p-[40px]  border-[#4F008A1A] border-[1px] rounded-[10px]">
            <div className="flex justify-between items-center">
              <div>
                <div className="text-[#272B3F] text-[12px] mb-1">
                  {LanguageName("CBON")}
                </div>
                <div className="text-[18px] text-[#272B3F] font-medium">
                  {LanguageName("MR")}
                </div>
              </div>

              <div className="bg-[#F6F6F6] py-[7px] px-[13px] rounded-lg flex items-center gap-[5px]">
                {session?.map((item, i) => (
                  <div
                    className={`px-[12px] py-[9px] rounded-sm text-[12px] ${
                      item === selectedSession ? "bg-[#000000] text-white" : ""
                    } cursor-pointer`}
                    onClick={() => setSelectedSession(item)}
                    key={i}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>

            {/* <BarChartComp/> */}
          </div>
        </div>
      </div>
    </div>
  );
}
