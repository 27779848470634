import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useFormValidator from "use-form-input-validator";
import InputComp from '../Components/Ui/InputComp';
import { useUserLoginMutation } from '../store/auth/authApi';
import ButtonComp from '../Components/Ui/Button';
import {  toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { setUserData } from '../store/auth';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import { storage } from '../utils/helper';
import { useGetAllProfileQuery, useLazyGetAllProfileQuery } from '../store/User/userApi';

export default function Login() {
  const navigate =useNavigate();
  const dispatch = useDispatch();
  const [showPassword,setShowPassword] =useState(true)
  const [getCompanyId,{isLoading:GGLoader}]= useLazyGetAllProfileQuery(
    
  );
  const [userLogin,{isLoading,isSuccess,data}] =useUserLoginMutation()
  const { values, errors, updateField, isAllFieldsValid,} = useFormValidator({
    email: { checks: "required|email", value: "johndoe2@gmail.com" },
    password: { checks: "required", value: "2B8FB215" },
   
  });

  const handleSaveCompanyId =async (userId) =>{
    const response = await getCompanyId(userId);
    storage.localStorage.set('companyId',response?.data?.data?.company);

    console.log(response?.data?.data?.company
      )
  }


  useEffect(() => {
    if(isSuccess){
    
         toast.success('User Login Successfully...')
         

      dispatch(setUserData(data?.data))
      navigate('/overview')
     
    }
  }, [isSuccess,navigate,dispatch,data])
  

  const handleSubmit = async (e) => {
    
    storage.localStorage.get('IsNewUser')===false?null: storage.localStorage.set('IsNewUser',true)
   
    e.preventDefault();
    // if (isAllFieldsValid() && productCategory && status) {
    if (isAllFieldsValid()) {
      const loginDetails = await userLogin(values);
      const {data,error} =loginDetails;
      if(error?.data?.message){
        toast.error(error?.data?.message)
      }
      handleSaveCompanyId(data?.data?.user?.id)
     
      console.log(data?.data,error,'handleAddProduct')
    }
  }
  const redirectToWhatsApp = () => {
    // Replace the phone number and message with your desired values
    const phoneNumber = '+23409160717495';
    const message = 'Hello, how can I help you?';

    // Generate the WhatsApp URL with the phone number and message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    // Redirect the user to the WhatsApp URL
    window.location.href = url;
  };

  return (
    <div className="flex flex-wrap xl:flex-nowrap ">
        <div className="w-full    xl:w-[50%]  relative d-none d-xl-block">
        <div className=" bg-[#4F008A]  h-[100vh] w-full bg-[url('/public/icon/background1.png')] bg-contain text-center   top-0 bottom-0 flex  items-center justify-center">
          <div className="relative text-center flex flex-col">
            <div className="pt-[30px] flex justify-center align-bottom" onClick={()=>navigate('/privacy')}>
              <img
                src="/login.png"
                alt=""
                className="  w-[60%] object-contain mb-[51px]"
              />
             
            </div>
            <div className='text-[25px] text-white font-medium'>
              Supercharge your medical detailing <br/> effort across different Healthcare<br/> facilities
              </div>
            
          </div>
        </div>
      </div>
      <div className="w-full xl:w-[50%] h-[100vh]  scrollable flex justify-center flex-col items-center">
        <div className=" h-[100vh] xl:h-[100vh] overflow-y-scroll pb-[100px]  flex justify-start items-center">
          {/* <div className="py-[30px] pl-[20px] lg:pl-[72px] border-b-rgba(19, 8, 3, 0.1) border-b-[1px] mb-[60px] ">
            <img alt="" src="/icon/logo.svg" />
          </div> */}
          {/*  */}
          <div className="px-[50px] lg:px-[80px] lg:pl-[72px] flex-1">
            <div className="mb-5 text-center">
              <h3 className="text-[32px] font-semibold mb-[0px]">
              Welcome Back!
              </h3>
              <p className="text-[#333333]">
              Enter your credentials to use your account.             </p>
            </div>
            <form
              className="w-full lg:pr-[119px] row gy-4 "
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <InputComp
                label={"Email"}
                name="email"
                onChange={updateField}
                value={values.email}
                error={errors.email}
                placeholder={'Enter your email address'}
                containerClassName={'border-[#918EF4] border-x-0 border-t-0 rounded-0  !border-b-[1px]'}
              />
              <div className='mb-4'>
              <InputComp
                label={"Password"}
                name="password"
                onChange={updateField}
                type={showPassword?'password':'text'}
                value={values.password}
                error={errors.password}
                containerClassName={'border-[#918EF4] border-x-0 border-t-0 rounded-0  !border-b-[1px]'}
                placeholder={'Enter your password'}
                prefixIcon={
                  <div className='mx-3' onClick={()=>setShowPassword(!showPassword)}>{showPassword?<AiOutlineEyeInvisible  size={25}/>:
                  <AiOutlineEye size={25}/>
                  }</div>
                }
              />
              <div className='flex justify-between mt-3'>
                <div className='flex gap-1 items-center text-[#757575] text-[13px]'><input type='checkbox'/> Remember me</div>
              <div onClick={()=>navigate('/forget_password')} className='text-[#4F008A] text-end text-[14px] mt-[4px] cursor-pointer'>Forgot Password?</div>

              </div>
              </div>
              
             
             
              {/* <TextInputComp label={""} /> */}
              <ButtonComp
              onClick={handleSubmit}
              loader={isLoading}
              text={'Sign In'}
              btnClassName={'bg-[#8652CE] shadow-lg text-white py-[10px] rounded-full w-full text-center font-medium '}
              />
              <div className='text-[#282728] mb-0 pb-0 text-center text-[14px]'>Don’t have an account yet? <span onClick={()=>window.open('https://registersandbox.pharmaserv.ng/','_blank')} className='text-[#4F008A] hover:underline cursor-pointer'>Request for Demo or Start Free Trial</span> </div>
              <div className='text-[#282728] text-center flex justify-center items-center gap-2 text-[14px]'>Get in touch with us via <span onClick={()=>redirectToWhatsApp()} className='text-[#4F008A] hover:underline cursor-pointer flex items-center gap-1'> <span className='text-[#25D366] cursor-pointer'>Whatsapp</span> 
                <img src='/icon/whatsapp.svg'/>
                </span> </div>

            </form>
          </div>
          {/*  */}
        </div>
        <div className='flex justify-between items-center w-full container mb-[50px] text-[14px] text-[#1A002E]'>
          <div>© PharmaServ 2023</div>
          <div>Privacy Terms and Condition</div>
        </div>
      </div>
    
    </div>
  )
}
