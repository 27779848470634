import moment from "moment";
import { Dots, TrackIcon, TrashIcon } from "../../../../utils/svg";
import DropDownComp from "../../../../Components/Ui/DropDown";
import { LanguageName } from "../../../../utils/helper";
import { Tooltip } from "@material-tailwind/react";
import TootTipComp from "../../../../Components/Common/TootTip";

export const tableData = [
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Complete",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Pending",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Complete",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Complete",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Canceled",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Canceled",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
];

export const TopHeaderCount = (Schedule) => {
  return [
    {
      name: "Total Schedules",
      count: Schedule?.length,
    },
    {
      name: "Completed Schedule",
      count: "",
    },
    {
      name: "Canceled Schedules",
      count: "",
    },
    {
      name: "Checked In",
      count: "",
    },
  ];
};
// callFocus
// :
// "Test"
// callType
// :
// "Physical"
// company
// :
// "64cbb75c807926fd39e1d9fb"
// createdAt
// :
// "2023-09-06T11:16:20.003Z"
// date
// :
// "2023-09-04T00:00:00.000Z"
// endTime
// :
// 13
// hcp
// :
// "64cbb99be4a560fa8d95e333"
// institutionArea
// :
// "Ikeja, Lagos."
// institutionDepartment
// :
// "Radiology"
// institutionName
// :
// "Lagos State University Teaaching Hospital"
// medRep
// :
// "64cbb8f1af9faf444f0cc5d4"
// notes
// :
// "Notes"
// startTime
// :
// 9
// status
// :
// "pending"
// updatedAt
// :
// "2023-09-06T11:16:20.003Z"
// _id
// :
// "64f85f830a7862f00ff49f39"

const OptionDropDown = () => [
  {
    name: (
      <div className="flex gap-1 items-center text-[#656468] text-[12px]">
        <TrackIcon />
        Track Med Rep
      </div>
    ),
    // action:;
  },
  {
    name: (
      <div className="flex gap-1 items-center text-[#D63434] text-[12px]">
        <TrashIcon />
        Report Schedule
      </div>
    ),
    // action:;
  },
];

export const columnsData = () => [
  {
    name: <TootTipComp 
    >
    {LanguageName('ID')}
  </TootTipComp> ,
    selector: (row) => <span>#{row?._id?.substring(0, 6)}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: LanguageName('MR'),
    selector: (row) => (
      <div>
        <div className="whitespace-nowrap text-[16px] text-[#000000]">
          {row.firstName}    {row.lastName}
        </div>
        {/* <div className="text-[14px] text-[#444444]">
          {row.institutionDepartment}
        </div> */}
      </div>
    ),
    width: "200px",
    // sortable: true,
  },
  {
    name: "Status",
    selector: (row) => <span>{'-'}</span>,
    width: "200px",
    // row.medRep,
  },
  {
    name: <div>{LanguageName('TRY')}</div>,
    selector: (row) => <span>{row?.state?.map((item,i)=><span>{item},</span>)}</span>,
    width: "200px",
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">{LanguageName('LA')} </div>,
    selector: (row) => "-",
    width: "200px",
    // sortable: true,
  },

  {
    name: "Hcps",
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{'-'}</div>
    ),
    // sortable: true,
  },
  {
    name:LanguageName('PPS'),
    selector: (row) =>   <div className={`${StatusColor(row?.status)} `}>{'-'}</div>,
    width: "200px",

    // sortable: true,
  },
  {
    name: LanguageName('Action'),
    // sortable: true,
    cell: (row) => (
      <div className="ms-3">
        <DropDownComp
          dropDownText={<Dots color="#7572EA" />}
          options={OptionDropDown()}
        />
      </div>
    ),
    width: "150px",
  },
];

const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-md capitalize";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] capitalize";
      break;
    default:
      break;
  }
};
