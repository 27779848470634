import { REGEX_PATTERNS } from "../../../../utils/constants/errors";
import { LanguageName } from "../../../../utils/helper";

export const FormLabel=[
   
    {
        name:'firstName',
        label:'Current Password',
        className:'col-lg-12',
        type:'password',
        placeholder:'Enter Company’s Name'
    },
    {
        name:'firstName',
        label:'New Password',
        className:'col-lg-12',
        type:'password',
        placeholder:'Enter Company’s Name'
    },
    {
        name:'firstName',
        label:'Confirm New Password',
        className:'col-lg-12',
        type:'password',
        placeholder:'Enter Company’s Name'
    },

    
]

export const FormLabelII=()=>{
    return [
   
        {
            name:'firstName',
            label:LanguageName('CC'),
            className:'col-lg-12',
            type:'password',
            placeholder:'Enter Company’s Name'
        },
        {
            name:'firstName',
            label:LanguageName('NP'),
            className:'col-lg-12',
            type:'password',
            placeholder:'Enter Company’s Name'
        },
        {
            name:'firstName',
            label:LanguageName('T'),
            className:'col-lg-12',
            type:'password',
            placeholder:'Enter Company’s Name'
        },
    
        
    
    
    ]
}