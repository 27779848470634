import { LanguageName } from "../../utils/helper";
import CompanySettings from "./submodules/CompanySettings/CompanySettings";
import MedRepresentatives from "./submodules/MedRep/MedRepresentatives";
import Notifications from "./submodules/Notifcations";
import PasswordPage from "./submodules/PasswordPage/PasswordPage";
import PersonalInformation from "./submodules/PersonalInformation/PersonalInformation";
import PrivacyPolicies from "./submodules/PrivacyPolicies";

export const ListAdmin =(control)=> {
    return [
        {
            name:LanguageName('PI'),
            icon:'/Admin/user-edit.png',
            component:<PersonalInformation control={control}/>,
            id:1
        },
        // {
        //     name:'Notifications',
        //     icon:'/Admin/notification-bing.svg',
        //     component:<Notifications control={control}/>
        // },
        {
            name:LanguageName('PW'),
            icon:'/Admin/key-square.png',
            component:<PasswordPage control={control}/>,
            id:2
        },
        {
            name:LanguageName('MR2'),
            icon:'/Admin/people.png',
            component:<MedRepresentatives/>,
            id:3
        },
        {
            name:LanguageName('CPS'),
            icon:'/Admin/user-edit-2.png',
            component:<CompanySettings control={control}/>,
            id:4
        },
        {
            name:LanguageName('PPPP'),
            icon:'/Admin/lock.png',
            component:<PrivacyPolicies/>,
            id:5
        },
    ]
}