import React from "react";
import { useGetMedRepQuery } from "../../../../store/MedRep/MedRepApi";
import { LanguageName, storage } from "../../../../utils/helper";
import { TableComp } from "../../../../Components/Common/TableComp";
import { columnsData } from "./Data";
import { useSelector } from "react-redux";
import { selectAllCurrentUserData } from "../../../../store/auth";

export default function MedRepresentatives() {
  const getUserAll = useSelector(selectAllCurrentUserData);
  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;

  const { data: MedRepData, isLoading: MRLoader } = useGetMedRepQuery(
    companyIde,
    {
      skip: !companyIde,
    },
  );
  const MedRep = MedRepData?.data?.medreps;
  console.log(MedRepData?.data?.medreps, "MedRepData");
  console.log(getUserAll, "get user all");

  const MedRepArray = [
    {
      name: LanguageName("TMR2"),
      data: MedRep?.length,
      // 'Total Med Reps'
    },
    {
      name: LanguageName("AMR"),
      data: MedRep?.length,
    },
    {
      name: LanguageName("IAMR"),
      data: MedRep?.length,
    },
  ];
  return (
    <div className="px-2">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-3 2xl:gap-7.5 mb-5">
        {MedRepArray?.map((item, i) => (
          <div className="border-[1px] border-[#E5E5EF] py-[20px] px-[20px] rounded-sm">
            <div className="mb-[26px] font-semibold">{item?.name}</div>
            <div className="text-[#060D1C] text-[28px] font-semibold">
              {item?.data}
            </div>
          </div>
        ))}
      </div>

      <div>
        <TableComp
          loader={MRLoader}
          columnsComp={columnsData()}
          Data={MedRep}
        />
      </div>
    </div>
  );
}
