import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HealthCareOrganizationComp from "./HealthCareOrganizationComp";
import { useGetHOCQuery } from "../../../store/Event/EventApi";

function HealthCareOrganization() {
  const {data:HcoData,isLoading:HCOLoader}=useGetHOCQuery();
  // console.log(HcoData?.data?.hcos,'hoc')
  return (
    <div>
      {/* <AdminLayout active={1}> */}
        <HealthCareOrganizationComp
        Data={HcoData?.data?.hcos}
        isLoading={HCOLoader}
        />
      {/* </AdminLayout> */}
    </div>
  );
}

export default HealthCareOrganization;
