import React, { useMemo } from "react";
import {
  useGetPlansSummaryQuery,
  useGetSinglePlanQuery,
} from "../../../../store/Plans/PlansApi";
import { useParams } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import { LanguageName } from "../../../../utils/helper";
import NewFormUI from "../../../../Components/Ui/NewFormUi";
import { useForm } from "react-hook-form";
import AboutMedRep from "../submodules/AboutMedRep";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Events from "../../../../utils/Events.js";
import moment from "moment";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";

import Country from "../../../../country.json";
import { useEffect } from "react";

export default function PlanSummary() {
  const { data: PlansSummary, refetch } = useGetPlansSummaryQuery();
  console.log("plasn summary", PlansSummary);
  const [currentMonth, setCurrentMonth] = React.useState({
    month: PlansSummary?.data[0]?.month,
    targets: [],
  });

  const [hcp, setHcp] = React.useState(null);
  const [currentMonthData, setCurrentMonthData] = React.useState(null);
  const [previousMonthData, setPreviousMonthData] = React.useState(null);

  const [selectedDate, setSelectedDate] = React.useState(
    PlansSummary?.data[0]?.selectedDates,
  );

  const now = new Date();

  useEffect(() => {
    refetch();
  }, []);

  const [calenderDates, setCalenderDates] = React.useState(null);

  const currentM = useMemo(() => {
    return PlansSummary?.data?.filter(
      (item) => item?.month === PlansSummary?.data[0]?.month,
    );
  }, [PlansSummary]);

  const otherMonthData = useMemo(() => {
    return PlansSummary?.data?.filter(
      (item) => item?.month !== PlansSummary?.data[0]?.month,
    );
  }, [PlansSummary?.data]);

  console.log(currentMonthData, "currentMonthData");
  console.log(otherMonthData, "otherMonthData");

  const months = useMemo(() => {
    let otherMonth = PlansSummary?.data?.filter(
      (item) => item?.month !== PlansSummary?.data[0]?.month,
    );

    if (otherMonth?.length > 0) {
      const groupedByMonth = otherMonth?.reduce((acc, obj) => {
        // Get the month of the current object
        const key = obj.month;
        // If the key doesn't exist yet, create it
        if (!acc[key]) {
          acc[key] = [];
        }
        // Push the current object into the appropriate array
        acc[key].push(obj);
        // Return the accumulator for the next iteration
        return acc;
      }, {});

      // To convert the object into an array of arrays
      const result = Object.keys(groupedByMonth).map(
        (key) => groupedByMonth[key],
      );

      return result;
    }
  }, [PlansSummary?.data]);

  useEffect(() => {
    setCurrentMonthData(currentM);
    setPreviousMonthData(months);
  }, [currentM, months]);

  useEffect(() => {
    console.log("selected hcp", hcp);
    console.log("months", months);
  }, [hcp, months]);

  function getMonthName(monthNumber) {
    const date = new Date(0, monthNumber - 1); // Month number starts from 0 in JS Date
    return date.toLocaleString("default", { month: "long" });
  }

  const handleHcpChange = (item) => {
    setHcp(item);

    setCalenderDates(
      item?.selectedDates?.map((date) => {
        return {
          id: item?.hcpId,
          title: item?.title + " " + item?.firstName,
          start: new Date(
            Number(date.split("-")[0]),
            Number(date.split("-")[1]) - 1,
            Number(date.split("-")[2]),
            0,
            0,
            0,
          ),
          end: new Date(
            Number(date.split("-")[0]),
            Number(date.split("-")[1]) - 1,
            Number(date.split("-")[2]),
            0,
            0,
            0,
          ),
        };
      }),
    );
  };

  useEffect(() => {
    setHcp(PlansSummary?.data[0]);
    setCalenderDates(
      PlansSummary?.data[0]?.selectedDates?.map((date) => {
        return {
          id: PlansSummary?.data[0]?.hcpId,
          title:
            PlansSummary?.data[0]?.title +
            " " +
            PlansSummary?.data[0]?.firstName,
          start: new Date(
            Number(date.split("-")[0]),
            Number(date.split("-")[1]) - 1,
            Number(date.split("-")[2]),
            0,
            0,
            0,
          ),
          end: new Date(
            Number(date.split("-")[0]),
            Number(date.split("-")[1]) - 1,
            Number(date.split("-")[2]),
            0,
            0,
            0,
          ),
        };
      }),
    );
  }, [PlansSummary?.data]);

  // TODO: add the "go back" button
  return (
    <div className="px-3">
      <div className="flex  h-full justify-content-between align-items-center pb-[45px]">
        <div className="align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700]">
            {/* {LanguageName("createNewPlan")} */}
            Plan Summary
          </div>
        </div>
      </div>
      <div className="border"></div>

      <div className="flex md:flex-row flex-col mt-4">
        <div className="col-2 md:mr-4 overflow-auto md:h-[85vh] w-full md:w-auto h-[30vh]">
          <div className="flex mb-4 p-2 justify-around items-center text-[14px] font-[500] border border-[#EBEBEB]">
            <div>Targets</div>
            <div className="flex flex-col text-[10px] font-[500] p-2 justify-center items-center border border-[#EBEBEB]">
              HCP
            </div>
          </div>
          <div className="mb-4">
            <div className="font-[500] text-[12px]">
              {getMonthName(currentMonth?.month)}{" "}
              {PlansSummary?.data[0]?.targets[0]?.year} (
              {currentMonthData?.length})
            </div>
            {currentMonthData?.map((item, i) => (
              <div
                onClick={() => handleHcpChange(item)}
                className={`flex flex-col p-2 cursor-pointer justify-center items-center
                  hover:bg-[#EBEBEB] bg-[#ffffff]
                 border border-[#EBEBEB]`}
              >
                <div className="font-[500] text-[14px] text-[#4f008a]">
                  {item?.title} {item?.firstName}
                </div>
                <div className="font-[500] text-[10px] text-[#959393]">
                  {item?.plansCreated} plans created
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="font-[500] text-[12px]">Previous</div>
            {previousMonthData?.map((item, i) => (
              <div className="flex p-2 justify-center items-center  border border-[#EBEBEB]">
                <div className="font-[500] text-[14px] bg-[#4f008a] w-[9px] h-[9px] rounded-[100%] mr-2" />
                <div className="font-[500] text-[14px] text-[#4f008a]">
                  {getMonthName(item[i]?.month)} {item[i]?.year} ({item?.length}
                  )
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full">
          <div className="w-full flex flex-col justify-center items-end mb-8">
            {/* <div> */}
            {/*   <div className="font-[400] text-[12px] self-start mb-2"> */}
            {/*     Medrep */}
            {/*   </div> */}
            {/*   <select className="w-[250px] bg-[#fff] h-[40px] border-[1px] border-[#f7f3f9]"> */}
            {/*     <option>Select Medrep</option> */}
            {/*   </select> */}
            {/* </div> */}
          </div>
          {calenderDates && (
            <div className="h-[70vh]">
              <Calendar
                localizer={localizer}
                events={calenderDates}
                startAccessor="start"
                endAccessor="end"
              />
            </div>
          )}
        </div>

        <div className="col-2 w-full md:w-auto p-4">
          <div className="font-[500] text-[14px] mb-4">Summary</div>
          {/* <div className="bg-[#8652CE] w-[100%] h-[40px] text-[#fff] flex items-center justify-center mb-2"> */}
          {/*   October, 2023 */}
          {/* </div> */}
          {/* <div className="text-[#8652CE] w-[100%] h-[40px] bg-[#F6EFFE] flex items-center justify-center mb-2"> */}
          {/*   November, 2023 */}
          {/* </div> */}
          {/* <div className="text-[#8652CE] w-[100%] h-[40px] bg-[#F6EFFE] flex items-center justify-center mb-2"> */}
          {/*   December, 2023 */}
          {/* </div> */}
          {hcp?.targets?.map((item, i) => (
            <div className="bg-[#8652CE] w-[100%] h-[40px] text-[#fff] flex items-center justify-center mb-2">
              {getMonthName(item?.month)}, {item?.year}
            </div>
          ))}

          <div className="mt-[100px]" />

          <div className="mb-4">
            <div className="flex items-center justify-start mb-2">
              <div className="w-[10px] h-[10px] bg-[#8652CE] mr-2" />
              <div className="font-[500] text-[14px]">
                Proposed Days in the Field
              </div>
            </div>
            <div className="text-[#8652CE] w-[100%] h-[40px] bg-[#F6EFFE] flex items-center justify-center mb-2">
              {hcp?.targets[0]?.callsPerMonth}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-start mb-2">
              <div className="w-[10px] h-[10px] bg-[#8652CE] mr-2" />
              <div className="font-[500] text-[14px]">
                Average Calls Per Day
              </div>
            </div>
            <div className="text-[#8652CE] w-[100%] h-[40px] bg-[#F6EFFE] flex items-center justify-center mb-2">
              {Math.ceil(hcp?.targets[0]?.callsPerDay / calenderDates?.length)}
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-start mb-2">
              <div className="w-[10px] h-[10px] bg-[#8652CE] mr-2" />
              <div className="font-[500] text-[14px]">Proposed Goals</div>
            </div>
            <div className="text-[#8652CE] w-[100%] h-[40px] bg-[#F6EFFE] flex items-center justify-center mb-2">
              {hcp?.targets[0]?.callsPerDay * hcp?.targets[0]?.callsPerMonth}
            </div>
          </div>

          <div className="mt-[100px]" />
          {/* <div className="text-[#fff] w-[100%] h-[54px] text-[18px] font-[500] bg-[#3DA84A] flex items-center justify-center mb-2"> */}
          {/*   Save and Submit */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
