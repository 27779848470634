import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const SchedulesApi = createApi({
  reducerPath: "SchedulesApi",
  baseQuery: baseQuery,
  tagTypes:['hcp'],
  endpoints: (builder) => ({
   
    
    getSchedules: builder.query({
      query: () => ({
        url: `/admin/company/schedules`,
        method: "GET",
      }),
    }),
    getMedRep: builder.query({
      query: () => ({
        url: `/admin/company/${companyId}/med-reps`,
        method: "GET",
      }),
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
      providesTags:['hcp']
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
    getMap: builder.query({
      query: ({medRepUserId,scheduleId}) => ({
        url: `/schedules/${medRepUserId}/checkins/`,
        method: "GET",
      }),
    }),
    getMedRepMap: builder.query({
      query: ({medRepUserId,scheduleId,startDate=null,endDate=null}) => ({
        url: `/location/${medRepUserId}/all${startDate ?`?startDate=${startDate}`:''}${startDate ?`&endDate=${endDate}`:''}`,
        method: "GET",
      }),
    }),
    disableHcp: builder.mutation({
      query: (hcp_Id) => ({
        url: `/hcp/deactivate/${hcp_Id}`,
        method: "PUT",
        // body: payload,
      }),
      invalidatesTags:['hcp']
      // transformResponse: (response) => response.data,
      // invalidatesTags:['getMedia']
    }),
  }),
});

//schedules/:medRepUserId/checkins/:scheduleId

export const {
  useGetSchedulesQuery,
  useGetHCPQuery,
  useGetHcpSchedulesQuery,
  useGetMapQuery,
  useGetMedRepMapQuery,
  useDisableHcpMutation
} = SchedulesApi;
