import { fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getAuthToken, getToken, storage } from "../utils/helper";
import { BASE_URL } from "../api/baseUrl";

// Create our baseQuery instance
export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token =  storage["localStorage"].get('user_details_profile')?.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  validateStatus: (status) => {
    if (status.status === 401) {
      storage["localStorage"].remove("user_details_profile");
      // storage["localStorage"].remove("user_type");
      storage["cookieStorage"].remove("user_details_profile");
      // toast.error("Your Token has expired");
      // location.assign("/");
      throw new Error("Unauthorized");
    }
    return status;
    // return status >= 200 && status < 300;
  },
});

export const baseQueryWithRetry = retry(baseQuery, { maxRetries: 1 });
