import React from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { LanguageName } from "../../../utils/helper";

function KeyMessage({ userData }) {
  return (
    <div>
      <div className="text-center opacity-75">
        {Array.isArray(userData?.detailingPrioirtyProduct) &&
          userData?.detailingPrioirtyProduct?.length === 0 &&
          "No Note"}
      </div>
      {userData?.detailingPrioirtyProduct?.map((item, i) => (
        <div className="flex justify-content-between border rounded mb-[32px] p-[35px]">
          <div className="">
            <div className="flex flex-col">
              <div className="font-[500] text-[16px] text-[#555555] mb-[32px]">
                {LanguageName("DPP")}
              </div>
              <div className="font-[500] text-[16px] text-[#000000] mb-[32px]">
                {/* Panadol */}
                -
              </div>
            </div>
            <div className="flex flex-col">
              <div className="font-[500] text-[16px] text-[#555555] mb-[32px]">
                {LanguageName("ER")}
              </div>
              <div className="font-[500] text-[16px] text-[#000000]">
                {item?.efficacyReaction}
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col">
              <div className="font-[500] text-[16px] text-[#555555] mb-[32px]">
                {LanguageName("COR")}
              </div>
              <div className="font-[500] text-[16px] text-[#000000] mb-[32px]">
                {item?.clinicalOverviewReaction}
              </div>
            </div>
            <div className="flex flex-col">
            <div className="font-[500] text-[16px] text-[#555555] mb-[32px]">
                {LanguageName("safetyReaction")}
              </div>
              <div className="font-[500] text-[16px] text-[#555555] mb-[32px]">
                {item?.safetyReaction}
              </div>
            </div>
          </div>

          
        </div>
      ))}
    </div>
  );
}

export default KeyMessage;
