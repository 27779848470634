import moment from "moment";
import { Dots, TrackIcon, TrashIcon } from "../../utils/svg";
import DropDownComp from "../../Components/Ui/DropDown";
import { LanguageName } from "../../utils/helper";

export const TopHeaderCount = (Schedule) => {
  return [
    {
      name: LanguageName("TCR"),
      count: Schedule?.length,
    },
    {
      name: LanguageName("ACR"),
      count: Schedule?.filter((item) => item?.status === "approved")?.length,
      findBy:"approved"
    },
    {
      name: LanguageName("RR"),
      count: "",
    },
  ];
};

// callFocus
// :
// "Test"
// callType
// :
// "Physical"
// company
// :
// "64cbb75c807926fd39e1d9fb"
// createdAt
// :
// "2023-09-06T11:16:20.003Z"
// date
// :
// "2023-09-04T00:00:00.000Z"
// endTime
// :
// 13
// hcp
// :
// "64cbb99be4a560fa8d95e333"
// institutionArea
// :
// "Ikeja, Lagos."
// institutionDepartment
// :
// "Radiology"
// institutionName
// :
// "Lagos State University Teaaching Hospital"
// medRep
// :
// "64cbb8f1af9faf444f0cc5d4"
// notes
// :
// "Notes"
// startTime
// :
// 9
// status
// :
// "pending"
// updatedAt
// :
// "2023-09-06T11:16:20.003Z"
// _id
// :
// "64f85f830a7862f00ff49f39"

const OptionDropDown = () => [
  {
    name: (
      <div className="flex gap-1 items-center text-[#656468] text-[12px]">
        <TrackIcon />
        Track Med Rep
      </div>
    ),
    // action:;
  },
  {
    name: (
      <div className="flex gap-1 items-center text-[#D63434] text-[12px]">
        <TrashIcon />
        Report Schedule
      </div>
    ),
    // action:;
  },
];

export const columnsData = () => [
  {
    name: "ID",
    selector: (row) => <span>#{row?._id.substring(0, 6)}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: "Institution",
    selector: (row) => (
      <div>
        <div className="whitespace-nowrap text-[16px] text-[#000000]">
          {row.institutionName}
        </div>
        <div className="text-[14px] text-[#444444]">
          {row.institutionDepartment}
        </div>
      </div>
    ),
    width: "200px",
    // sortable: true,
  },
  {
    name: "Med Rep",
    selector: (row) => (
      <span>
        {row?.medRep?.firstName} {""} {row?.medRep?.lastName}
      </span>
    ),
    // row.medRep,
  },
  {
    name: <div>Call Method</div>,
    selector: (row) => row.callType,
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">Call Objective</div>,
    selector: (row) => row.notes,
    width: "200px",
    // sortable: true,
  },

  {
    name: "Availability",
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{row.status}</div>
    ),
    // sortable: true,
  },
  {
    name: "Date Added",
    selector: (row) => moment(row?.date).format("MMMM Do YYYY, h:mm"),
    width: "200px",

    // sortable: true,
  },
  {
    name: "Action",
    // sortable: true,
    cell: (row) => (
      <div className="ms-3">
        <DropDownComp
          dropDownText={<Dots color="#7572EA" />}
          options={OptionDropDown()}
        />
      </div>
    ),
    width: "100px",
  },
];

const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-md capitalize";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] capitalize";
      break;
    default:
      break;
  }
};
