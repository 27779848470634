import React from "react";
import Nav from "react-bootstrap/Nav";
import ButtonComp from "../../../Components/Ui/Button";
import moment from "moment";
import { LanguageName } from "../../../utils/helper";

export default function CallReportModal({ userData,extraData }) {
  console.log(userData, "userDatauserData");
  return (
    <div>
      <div className="container">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-2 2xl:gap-7.5 mb-[80px]">
          <div className="">
            <div className="flex flex-col mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("Departments")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
                {userData?.schedule?.institutionDepartment}
              </div>
            </div>
            <div className="flex flex-col mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("Time")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
                {moment(userData?.createdAt).format("h:mm a")}
              </div>
            </div>
            <div className="flex flex-col text-[14px] font-[500] text-[#000000] mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("CF")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
                {userData?.schedule?.callFocus}
              </div>
            </div>
            <div className="flex flex-col mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("CO")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
              {userData?.schedule?.callObjective}

                {/* Continuous medical Education */}
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("IA")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
                {userData?.schedule?.institutionArea}
                {/* 84A Gbagada Express, Ikorodu, Lagos State */}
              </div>
            </div>
            <div className="flex flex-col mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                Date
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
                {moment(userData?.createdAt).format("MMMM Do YYYY")}
              </div>
            </div>
            <div className="flex flex-col text-[14px] font-[500] text-[#000000] mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("CM")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
               
                -{/* Physical */}
              </div>
            </div>
            <div className="flex flex-col mb-[25px]">
              <div className="text-[12px] font-[500] text-[#555555] pb-[10px]">
                {LanguageName("HCPC")}
              </div>
              <div className="text-[14px] font-[500] text-[#000000]">
                {userData?.personalInformation?.category || "-"}
                {/* A/B/C/A1/A2/KBL/KOL/Others */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex space-x-2 mb-[20px]">
          <div className="flex py-2 px-3 rounded-full border-[0.64px] !border-[#8354CF]">
            <div className="me-2">
              <img
                className=""
                src="/CallReports/download-purple.png"
                alt="Download icon"
              />
            </div>
            <ButtonComp className="text-[#282728]" text={"Report"} />
          </div>
          <div className="">
            <ButtonComp
              className="py-2 px-3 rounded-full bg-[#4F008A] text-[#FFFFFF]"
              text={"Add Comment"}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
