import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import { useParams } from "react-router-dom";
import { useGetMapQuery, useGetMedRepMapQuery } from "../../store/Schedules/SchedulesApi";
import DropDownComp from "../../Components/Ui/DropDown";
import { ArrowDown } from "../../utils/svg";
import ListMedRepDetail from "./submodules/ListMedRepDetail";
import moment from "moment";
import { firstDateOfTheMonth } from "../../utils/helper";

const ShowMapComp = () => {
  const { medRepUserId, scheduleId } = useParams();
  const [position,setPosition] =useState(0)
  const [startDate, setStartDate] = useState(firstDateOfTheMonth());
  const [endDate, setEndDate] = useState(new Date());
  const StartDateEnhanced = moment(startDate).format("YYYY-MM-DD");
  const EndDateEnhanced = moment(endDate).add(1, 'days').format("YYYY-MM-DD");

  // console.log(medRepUserId, scheduleId,'medRepUserId, scheduleId')
  const google = window.google;

  const { data: Data, isLoading, isSuccess } = useGetMapQuery({
    medRepUserId,
    scheduleId
  });
  const { data: medRepMapData, isLoading:MapLoader, isSuccess:success,refetch:mapRefetch,isFetching } = useGetMedRepMapQuery({
    // medRepUserId:'64cbb773807926fd39e1da00',
     medRepUserId:medRepUserId,

    scheduleId,
    startDate:moment(startDate).format('YYYY-MM-DD'),
    endDate:moment(endDate).format('YYYY-MM-DD')
  });
  

  const DataMap = isSuccess?Data?.data?.checkins:[];
  const DataMapII = success?medRepMapData?.data?.locations:[];


  const [mapState, setMapState] = useState({
    center: {
      lat: Number(DataMapII[position]?.lat),
      lng: Number(DataMapII[position]?.lng
        ),
    },
    zoom: 1,
  });


  const CustomIcon = () => (
    <div style={{ width: '30px', height: '30px', background: 'red', borderRadius: '50%' }}>
      {/* Your custom design goes here */}
    </div>
  );

  useEffect(() => {
    setMapState({
      center: {
        lat: Number(DataMapII[0]?.lat),
        lng: Number(DataMapII[0]?.lng
          ),
      },
      zoom: 13,
    });
  }, [success,isFetching,DataMapII?.length>0]);

    const onPlaceSelected = (place) => {
      setMapState({
        center: place.geometry.location,
        zoom: 18
      });
    };

  const Auto = (props) => (
    <Autocomplete
      style={{ width: "90%" }}
      onPlaceSelected={(place) => {
        // console.log(place);
        onPlaceSelected(place);
      }}
      types={["(regions)"]}
    />
  );

  if (!success) return <div>Loading...</div>;

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap center={mapState.center} zoom={mapState.zoom}>
      <Polyline
        path={DataMapII.map((item) => ({
          lat: Number(item.lat),
          lng: Number(item.lng),
        }))}
        options={{
          strokeColor: "#FF0000", // Red color
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }}
      />
      {DataMapII?.map((item, i) => (
        <Marker
        key={i}
        position={{
          lat: Number(item?.lat),
          lng: Number(item?.lng),
          text:'JHello'
        }}
        icon={{
          url: i !== 0 ? "/Vector (4).svg" : "/Vector (5).svg",
          scaledSize: new window.google.maps.Size(30, 30),
        }}
      >
        {/* InfoWindow content */}
        <InfoWindow>
          <div>
            <p>{/* Add information to display in the tooltip */}</p>
          </div>
        </InfoWindow>
      </Marker>
      ))}
    </GoogleMap>
  ));

  return (
    <div className="relative">
      <ListMedRepDetail 
      startDate={startDate}
      endDate={endDate} 
      setStartDate={setStartDate}
      loader={MapLoader||isFetching}
      setEndDate={setEndDate}
      mapRefetch={mapRefetch}
      setPosition={setPosition} DataMap={DataMapII}/>
      <div style={{}}>
        {/* <h3>Choose another destination</h3> */}
        <Auto />
      </div>
      {<GoogleMapExample
        containerElement={<div style={{ height: `90vh`, width: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />}
      <div className="absolute bottom-2 h-[40px] bg-white left-[25vw]  right-5  flex justify-around rounded-[5px] items-center text-[14px]">
        <div className="flex gap-2 items-center">
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.566 5.22927C13.7277 1.62341 10.5104 0 7.68426 0C7.68426 0 7.68426 0 7.67628 0C4.85812 0 1.6328 1.61561 0.79454 5.22146C-0.139525 9.24878 2.38325 12.6595 4.66652 14.8059C5.51276 15.6019 6.59851 16 7.68426 16C8.77001 16 9.85576 15.6019 10.694 14.8059C12.9773 12.6595 15.5001 9.25659 14.566 5.22927ZM7.68426 9.13951C6.29514 9.13951 5.16947 8.03902 5.16947 6.68098C5.16947 5.32293 6.29514 4.22244 7.68426 4.22244C9.07338 4.22244 10.199 5.32293 10.199 6.68098C10.199 8.03902 9.07338 9.13951 7.68426 9.13951Z" fill="#959502"/>
</svg>

          Start/Goal</div>
          <div className="flex gap-2 items-center">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6585 5.22927C12.839 1.62341 9.69364 0 6.93072 0C6.93072 0 6.93072 0 6.92291 0C4.16779 0 1.01462 1.61561 0.195105 5.22146C-0.718066 9.24878 1.74828 12.6595 3.98047 14.8059C4.80779 15.602 5.86925 16 6.93072 16C7.99218 16 9.05364 15.602 9.87316 14.8059C12.1054 12.6595 14.5717 9.25659 13.6585 5.22927ZM6.93072 9.13951C5.57267 9.13951 4.47218 8.03903 4.47218 6.68098C4.47218 5.32293 5.57267 4.22244 6.93072 4.22244C8.28876 4.22244 9.38925 5.32293 9.38925 6.68098C9.38925 8.03903 8.28876 9.13951 6.93072 9.13951Z" fill="#0071FB"/>
</svg>

          GPS Tracking</div>
          <div className="flex gap-2 items-center">
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6585 5.22927C12.839 1.62341 9.69364 0 6.93072 0C6.93072 0 6.93071 0 6.92291 0C4.16779 0 1.01462 1.61561 0.195105 5.22146C-0.718066 9.24878 1.74828 12.6595 3.98047 14.8059C4.80779 15.6019 5.86925 16 6.93072 16C7.99218 16 9.05364 15.6019 9.87315 14.8059C12.1053 12.6595 14.5717 9.25659 13.6585 5.22927ZM6.93072 9.13951C5.57267 9.13951 4.47218 8.03902 4.47218 6.68098C4.47218 5.32293 5.57267 4.22244 6.93072 4.22244C8.28876 4.22244 9.38925 5.32293 9.38925 6.68098C9.38925 8.03902 8.28876 9.13951 6.93072 9.13951Z" fill="#3DA84A"/>
</svg>

          Customer Visited</div>
          <div className="flex gap-2 items-center">
          <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.805 5.22927C12.9855 1.62341 9.84013 0 7.0772 0C7.0772 0 7.0772 0 7.06939 0C4.31427 0 1.1611 1.61561 0.341589 5.22146C-0.571581 9.24878 1.89476 12.6595 4.12696 14.8059C4.95427 15.6019 6.01574 16 7.0772 16C8.13866 16 9.20013 15.6019 10.0196 14.8059C12.2518 12.6595 14.7182 9.25659 13.805 5.22927ZM7.0772 9.13951C5.71915 9.13951 4.61866 8.03902 4.61866 6.68098C4.61866 5.32293 5.71915 4.22244 7.0772 4.22244C8.43525 4.22244 9.53574 5.32293 9.53574 6.68098C9.53574 8.03902 8.43525 9.13951 7.0772 9.13951Z" fill="#D63434"/>
</svg>

          Customer Registered Location</div>
      </div>
    </div>
  );
};

export default ShowMapComp;
