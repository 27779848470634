import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../api/baseUrl";
import { baseQuery } from "../api";
import axios from "axios";

// Define a custom base query using Axios
const axiosBaseQuery = ({ baseUrl, prepareHeaders }) => {
  return async ({ url, method, data, headers, params, onUploadProgress }) => {
    try {
      // Use Axios to make the request
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        headers: await prepareHeaders(headers),
        params,
        onUploadProgress,
      });

      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
};

export const userApi = createApi({
  reducerPath: "userApi",
  // baseQuery: axiosBaseQuery,
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      // You can add custom headers here if needed
      return headers;
    },
  }),
  tagTypes: ["allProfile"],

  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: (body) => ({
        url: "/user/profile",
        method: "GET",
        body,
      }),
    }),
    getAllProfile: builder.query({
      query: (userId) => ({
        url: `/admin/company/manager/${userId}/profile`,
        method: "GET",
        // body,
      }),
      providesTags: ["allProfile"],
    }),
    // uploadFile: builder.mutation({
    //   query: (payload) => ({
    //     url: "/file/upload",
    //     method: "POST",
    //     body: payload,
    //   }),
    //   transformResponse: (response) => response.data,
    // }),
    uploadFile: builder.mutation({
      query: ({ formData, onUploadProgress }) => ({
        url: "/file/upload",
        method: "POST",
        data: formData,
        onUploadProgress,
      }),
      transformResponse: (response) => response.data,
    }),
    updateCompany: builder.mutation({
      query: (payload) => ({
        url: `admin/company/${payload?.companyId}/update`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["allProfile"],
    }),
    updateManager: builder.mutation({
      query: (payload) => ({
        url: `admin/company/manager/${payload?.id}/update`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["allProfile"],
    }),
    createMedRep: builder.mutation({
      query: (payload) => ({
        url: `admin/company/${payload?.companyId}/med-reps`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      // invalidatesTags:['allProfile']
    }),

    //company/manager/:userId/update
  }),
});

export const {
  useGetUserProfileQuery,
  useGetAllProfileQuery,
  useLazyGetAllProfileQuery,
  useUploadFileMutation,
  useUpdateCompanyMutation,
  useUpdateManagerMutation,
  useCreateMedRepMutation,
} = userApi;
