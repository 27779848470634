import React, { useMemo } from "react";
import { useGetSinglePlanQuery } from "../../../../store/Plans/PlansApi";
import { useParams } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import { LanguageName } from "../../../../utils/helper";
import FormUi from "../../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import AboutMedRep from "../submodules/AboutMedRep";

import Country from '../../../../country.json';
import { useEffect } from "react";

export default function PlanEdit() {
  const { plan_id } = useParams();
  const {
    control,
    handleSubmit,
    reset,
   setValue,
    setError,
  } = useForm({
    defaultValues: {
      category: "",
      email: "",
      lastName: "",
      phone: "",
      address: "",
      institutionName: "",
      country: "",
      state: "Oyo",
      image:'',
      companyId:""
    },
  });
  const { data, isLoading } = useGetSinglePlanQuery(plan_id, {
    skip: !plan_id,
  });

  const Plan =data?.data?.plan;
  console.log(Plan,'PlanPlan')

  useEffect(() => {
    setValue('category',[{label:Plan?.category,value:Plan?.category}]);
    setValue('institutionName',Plan?.institutionName);
    setValue('institutionDepartment',Plan?.institutionDepartment);
    setValue('location',Plan?.location);
    setValue('status',Plan?.status);
  }, [Plan?._id])
  


  const transformedArray = useMemo(() => {
    return Country.map(item => ({
      label: item.name, // replace with the actual property you want as label
      value: item.name, 
      id:item?.id  // replace with the actual property you want as value
    }));
  }, [Country]);

//   console.log(data,'datadata')

  const EditPlanForm = [
    {
      name: "category",
      label: "Category",
      type: "select",
      placeholder: "Select Category",
      options :[
        {
          value:'HCP',
          label: 'HCP',
        }
      ]
    },
    {
      name: "country",
      label: "Country",
      type: "select",
      placeholder: "Select Nigeria",
      options:transformedArray
    },
    {
      name: "institutionName",
      label: "Institution Name",
      type: "",
      placeholder: "",
    },
    {
      name: "location",
      label: "Location",
      type: "",
      placeholder: "",
    },
    {
      name: "institutionDepartment",
      label: "Department",
      type: "",
      placeholder: "",
    },
    {
      name: "status",
      label: "Status",
      type: "select",
 
      placeholder: "",
      options :[
        {
          value:'pending',
          label: 'pending',
        }
      ]
    
    },
    {
      name: "institutionName",
      label: "Healthcare Practitioner",
      type: "",
      placeholder: "",
    },
    {
      name: "",
      label: "Select Date",
      type: "date",
      placeholder: "",
    },
  ];

  return (
    <div className="px-3">
      <div className="flex  h-full justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700]">
            {" "}
            {LanguageName("editPlan")}
          </div>
          <div className="ms-2">
            <img src="/icon/info-circle.svg" alt="" />
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName("DR")}
            />
          </div>
        </div>
      </div>
      <div className="border"></div>

      <div className="row   mt-4">
        <div className="col-8 ">
          <div className="p-4 bg-white h-[100vh] border-r">
            <div className="flex justify-between items-center mb-[37px]">
              <div>
                <div className="text-[24px] font-bold text-[#000000] mb-[4px]">
                  Edit Plan
                </div>
                <div className="text-[#000000] font-medium">
                  Follow the procedure to edit plan
                </div>
              </div>

              <div>
                <ButtonComp
                  className="text-white bg-[#A162F7] rounded-[5px] font-[500] text-[16px] px-[37px] py-[14px]"
                  text={LanguageName("UC")}
                />
              </div>
            </div>

            <div className="row gy-3">
              <FormUi 
              containerClassName=''
              wrapperClassName={'col-lg-6'}
              formData={EditPlanForm} control={control} />
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="col-4">
            <AboutMedRep Data={Plan?.hcp}/>
        </div>
      </div>
    </div>
  );
}
