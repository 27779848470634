import React from 'react'
import { Tooltip } from "@material-tailwind/react";

export default function TootTipComp({
    children
}) {
  return (
    <div className='z-50'>
<Tooltip 
    animate={{
      mount: { scale: 1, y: 0 },
      unmount: { scale: 0, y: 25 },
    }}
    content="tooltip">
    {children}
  </Tooltip>
    </div>
  )
}
