import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import MediaComp from "./Media";
import ModalComp from "../../Components/Ui/ModalComp";
import ButtonComp from "../../Components/Ui/Button";
import MediaModal from "./MediaModal";
import {
  useCreateMediaFolderMutation,
  useGetMediaQuery,
  useGetSingleMediaQuery,
} from "../../store/Media/MediaApi";
import SingleMediaComp from "./SingleMediaComp";
import { useParams } from "react-router-dom";
import MediaSingleModal from "./MediaSingleModal";

export default function SingleMedia() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("name"); // default sort by name
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  console.log(id);
  const { data, isLoading } = useGetSingleMediaQuery(id, {
    skip: !id,
  });
  const [filteredItems, setFilteredItems] = React.useState(
    data?.data?.folder?.files,
  );

  function sortItems(items, sortOrder) {
    if (!items) return [];

    return items?.slice().sort((a, b) => {
      if (sortOrder === "name") {
        return a.name.localeCompare(b.name);
      } else if (sortOrder === "date") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (sortOrder === "size") {
        return a.size - b.size;
      }
    });
  }

  React.useEffect(() => {
    setFilteredItems(
      sortItems(searchItems(data?.data?.folder?.files, searchTerm), sortOrder),
    );
  }, [searchTerm, data?.data?.folder, sortOrder]);

  // Add a function to handle setting the sort order
  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  function searchItems(items, searchTerm) {
    if (!items) return [];

    console.log("items", items);

    return items?.filter((item) => {
      const searchLower = searchTerm.toLowerCase();
      const nameMatch = item.name.toLowerCase().includes(searchLower);
      const idMatch = item.id.toString().includes(searchLower);
      const typeMatch = item.type.toLowerCase().includes(searchLower);
      const sizeMatch = item.size.toString().includes(searchLower);
      return nameMatch || idMatch || typeMatch || sizeMatch;
    });
  }

  React.useEffect(() => {
    setFilteredItems(searchItems(data?.data?.folder?.files, searchTerm));
  }, [searchTerm, data?.data?.folder?.files]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const OptionDropDown = (row) => [
    {
      name: "Name",
      action: () => handleSortChange("name"),
    },
    {
      name: "Date created",
      action: () => handleSortChange("date"),
    },
    // {
    //   name: "Size",
    //   action: () => handleSortChange("size"),
    // },
  ];

  return (
    <>
      {/* <ButtonComp onClick={() => setShowModal(true)} text={"Show"} /> */}
      <ModalComp
        handleClose={() => setShowModal(false)}
        show={showModal}
        size={"lg"}
        bodyComponents={<MediaSingleModal setShowModal={setShowModal} />}
      />
      <SingleMediaComp
        addMedia={() => setShowModal(true)}
        Medias={filteredItems}
        name={data?.data?.folder?.name}
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        options={OptionDropDown}
      />
    </>
  );
}
