import React from "react";
import Nav from "react-bootstrap/Nav";
import ButtonComp from "../../../Components/Ui/Button";
import { LanguageName } from "../../../utils/helper";

export default function CallReportModalNote({ userData }) {
  return (
    <div>
      <div className="container">
        <div className="mb-[80px]">
          <div className="text-[16px] font-[500] text-[#555555] mb-[10px]">
            {LanguageName("ON")}
          </div>
          <div className="text-[16px] font-[500] text-[#000000] mb-[42px]">
            {userData?.notes?.objectionNotes || "No Objection Notes"}
          </div>
          <div className="text-[16px] font-[500] text-[#555555] mb-[10px]">
            {LanguageName("NSAN")}
          </div>
          <div className="text-[16px] font-[500] text-[#3e3b3b]">
            {userData?.notes?.nextStepActionNotes ||
              "No    Next Step Action Note"}
          </div>
        </div>
        {/* <div className="flex space-x-2 mb-[20px]">
          <div className="flex py-2 px-3 rounded-full border-[0.64px] !border-[#8354CF]">
            <div className="me-2">
              <img
                className=""
                src="/CallReports/download-purple.png"
                alt="Download icon"
              />
            </div>
            <ButtonComp className="text-[#282728]" text={"Report"} />
          </div>
          <div className="">
            <ButtonComp
              className="py-2 px-3 rounded-full bg-[#4F008A] text-[#FFFFFF]"
              text={"Add Comment"}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
