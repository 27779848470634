import React from "react";

export default function TopHeaderCounts({
  name,
  count,
  isActive,
  setHeaderSelected,
  Data,
  status
}) {
  return (
    <div
    onClick={()=>{
      setHeaderSelected(name);
      typeof Data === "function"&&Data(status)
      // Data?.filter((item)=>item?.status === status)
    }}
      className={`flex flex-nowrap whitespace-nowrap cursor-pointer pe-[32px] py-4 align-items-center ${
        isActive ? "border-b-2   !border-[#3DA84A]" : "border-b-2 "
      }`}
    >
      <div className="text-[18px] font-[500] whitespace-nowrap">{name}</div>
      <div className={`rounded-full  h-[30px] w-[30px] flex justify-center items-center text-[14px] font-[600] ms-1 py-1 px-2 ${isActive?'bg-[#3DA84A] text-[#FFFFFF]':'bg-[#F5F0F8] text-[#656468]'}`}>
        {count||0}
      </div>
    </div>
  );
}
