import { Dropdown } from "react-bootstrap";
import { RiDownloadCloud2Fill } from "react-icons/ri";

export default function DropDownIII({
  dropDownText,
  dropDownTextClassName,
  options = [],
  row={},
  handleClick,
  name='name',
  renderItem
  // noDivider=true
}) {
  return (
    <Dropdown className={`border-none ${dropDownTextClassName} mb-0 pb-0`}>
      <Dropdown.Toggle variant="" className="border-0 m-0">
        {dropDownText}
      </Dropdown.Toggle>

      <Dropdown.Menu className="px-2">
        {Array.isArray(options) && (
          options?.map((item, i) => (
            <span key={i}>
              <Dropdown.Item className="text-[14px]" onClick={()=>{
               handleClick && handleClick(item)
              }}>{renderItem(item)}</Dropdown.Item>
              {options?.length===0 &&<Dropdown.Divider className="bg-[#eeeaf100] border-[0.2px] border-[#EEEAF1] mb-3 opacity-5"/>}
            </span>
          ))
        ) }
        
      </Dropdown.Menu>
    </Dropdown>
  );
}
