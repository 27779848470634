import React, { useEffect, useState } from "react";
import ButtonComp from "../../Components/Ui/Button";
import { ListAdmin } from "./Data";
import { useForm } from "react-hook-form";
import { LanguageName, getLanguage } from "../../utils/helper";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

export default function AdminConsole() {
   const { t } = useTranslation();
  const [activePage, setActivePage] = useState(1);

  const {
    control,
    handleSubmit,
   
    setError,
  } = useForm({
    defaultValues: {
      firstName: "Event Name",
      type: "Event Type",
      description: "Event Description",
      institution: "institution",
      location: "location",
      date: "2023-09-11",
      time: "10",
      recurring: true,
    },
  });
  const handleSubmitPersonalInfo=(data)=>{

  }
  return (
    <div className="container">
      <div className="flex flex-row  justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700]">{LanguageName('AC')}</div>
          <div className="ms-2">
            <img src="/icon/info-circle.svg" alt="" />
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName('DR')}
            />
          </div>
          <div className="flex align-items-center border-1 border-[#4F0084]  bg-[#4F008A] rounded px-3 py-2 ms-2">
            <div className="me-1"></div>
            <ButtonComp
              className="text-[#FFFFFF] font-[500] text-[16px] "
              text={LanguageName('UC')}
            />
          </div>
        </div>
      </div>
      <div className="row border-t ">
        <div className="col-lg-4 col-xl-3 col-md-6 lg:border-r pt-[55px] px-3 px-xl-2 hidden lg:block ">
          <div className="">
          {ListAdmin(control)?.map((item, i) => (
            <div
              className={`${
                item?.id === activePage
                  ? "bg-[#4F008A0F] text-black"
                  : "text-[#555555]"
              } gap-[16px] flex space-x-2  py-[13px] px-lg-4 mb-[24px] rounded-sm cursor-pointer`}
              onClick={() => setActivePage(item?.id)}
            >
              <div className="">
                <img src={item?.icon} alt="" />
              </div>
              <div className="text-[16px] ">{item?.name}</div>
            </div>
          ))}
          </div>
        </div>
        <div className="col-12 col-lg-8  pt-[10px] px-2 min-h-[90vh]">
          <div className="lg:hidden overflow-x-scroll">
          <div className="flex">
          {ListAdmin(control)?.map((item, i) => (
            <div
              className={`whitespace-nowrap ${
                item?.id === activePage
                  ? "bg-[#4F008A0F] text-black"
                  : "text-[#555555]"
              } gap-[16px] flex space-x-2  py-[13px] px-lg-4 mb-[24px] rounded-sm cursor-pointer`}
              onClick={() => setActivePage(item?.id)}
            >
              <div className="w-[30px]">
                <img src={item?.icon} className="" alt="" />
              </div>
              <div className="text-[16px] ">{item?.name}</div>
            </div>
          ))}
          </div>
          </div>
          {
            ListAdmin(control)?.find((item, index) => item?.id === activePage)
              ?.component
          }
        </div>
      </div>
    </div>
  );
}
