import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HisGracePharmaComp from "./HisGracePharmaComp";
import { useParams } from "react-router-dom";
import {
  useGetHCPViaMedRepQuery,
  useGetMedRepCyclePlanQuery,
  useGetMedRepDetailsHCpQuery,
  useGetMedRepDetailsQuery,
  useGetPlanHOCQuery,
} from "../../../store/Plans/PlansApi";

function HisGracePharma() {
  const { hcp } = useParams();
  const { data: MedRepDetail, isLoading: GMDLoader } = useGetMedRepDetailsQuery(
    hcp,
    {
      skip: !hcp,
    },
  );
  const { data: MedRepCyclePlan, isLoading: MRCPLoader } =
    useGetMedRepCyclePlanQuery(hcp, {
      skip: !hcp,
    });

  const { data: MedRepHCP, isLoading } = useGetMedRepDetailsHCpQuery(hcp, {
    skip: !hcp,
  });
  const { data: HOCMedRep, isLoading: HMLoader } = useGetHCPViaMedRepQuery(
    hcp,
    {
      skip: !hcp,
    },
  );
  //useGetPlanHOCQuery

  console.log(MedRepDetail, "MedRepCyclePlan");

  const transformDataToArray = (data = {}) => {
    return Object.keys(data)?.map((key) => {
      return {
        label: key,
        value: data[key]?.cycle || 0,
      };
    });
  };

  const transformedArray = transformDataToArray(MedRepCyclePlan?.data) || [];

  return (
    // <AdminLayout active={1}>
    <HisGracePharmaComp
      Data={MedRepDetail?.data?.plans}
      CycleData={transformedArray}
    />
    // </AdminLayout>
  );
}

export default HisGracePharma;
