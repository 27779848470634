import moment from "moment";
import React, { useState } from "react";
import Activities from "./HCPListCompSubmodules/Activities";
import Schedule from "./HCPListCompSubmodules/Schedule";
import Plan from "./HCPListCompSubmodules/Plan";

export default function HCPListComp({
  userSchedule,
  userDetail,
  schedule,
  activities,
  planData
}) {
const [selectHeader,setSelectHeader] =useState('Activities');
  const List=[
    {
      name:'Activities',
      components:<Activities activities={activities}/>
    },
    {
      name:'Schedule',
      components:<Schedule userSchedule={schedule}/>
    },
    {
      name:'Plan',
      components:<Plan planData={planData}/>
    },
  ]

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-5 border-r">
          <div className="flex flex-col">
            <div className="">
              <img src="/HCPList/profile.svg" alt="" />
            </div>
            <div className="text-[28px] text-[#242731] font-[700] my-[30px]">
              MedRep. {userDetail?.firstName} {userDetail?.lastName}
            </div>
            <div className="flex md:space-x-4 md:flex-row flex-col border-b ms-[15px] me-[30px] pb-[30px]">
              <div className="flex align-items-center">
                <div className="me-2">
                  <img className="" src="/HCPList/calendar.png" alt="" />
                </div>
                <div className="text-[#777777] text-[16px] font-[500]">
                  {moment(userDetail?.createdAt).format("Do MMMM  YY")}
                </div>
              </div>
              <div className="flex align-items-center">
                <div className="me-2">
                  <img className="" src="/HCPList/active.png" alt="" />
                </div>
                <div className="text-[#44AA43] text-[16px] font-[500] ">
                  Profile Active
                </div>
              </div>
            </div>
          </div>
          <div className="flex my-[40px]">
            <div className="">
              <img className="me-[30px]" src="/HCPList/sms.png" alt="" />
            </div>
            <div className="text-[18px] font-[400] text-[#333333] ">
              {userDetail?.email ||'-'}
            </div>
          </div>
          <div className="flex mb-[40px]">
            <div className="">
              <img className="me-[30px]" src="/HCPList/call.svg" alt="" />
            </div>
            <div className="text-[18px] font-[400] text-[#333333] ">
              {userDetail?.phoneNumber||'No Number Added yet'}
            </div>
          </div>
          <div className="flex mb-[40px]">
            <div className="">
              <img className="me-[30px]" src="/HCPList/user.png" alt="" />
            </div>
            <div className="text-[18px] font-[400] text-[#4F008A] ">
            {userDetail?.firstName ||'-'} {userDetail?.lastName}
            </div>
          </div>
          <div className="flex mb-[40px]">
            <div className="">
              <img className="me-[30px]" src="/HCPList/signpost.svg" alt="" />
            </div>
            <div className="text-[18px] font-[400] text-[#333333] ">
              {userDetail?.department||'-'}
            </div>
          </div>
          <div className="flex mb-[40px]">
            <div className="">
              <img className="me-[30px]" src="/HCPList/ranking.png" alt="" />
            </div>
            <div className="text-[18px] font-[400] text-[#333333] ">
              {userDetail?.company?.name||'-'}
            </div>
          </div>
          <div className="flex mb-[80px]">
            <div className="">
              <img className="me-[30px]" src="/HCPList/location.png" alt="" />
            </div>
            {userDetail?.address?.map((address)=>
             <div className="text-[18px] font-[400] text-[#333333] mb-[80px]">
             {address}
           </div>
            )}
           {userDetail?.country}
          </div>
        </div>
        <div className="col-lg-7 ">
          <div 
           
          className="flex mb-[65px]">
            {List?.map((item,i)=>
             <div
             onClick={()=>setSelectHeader(item?.name)}
             className={`${selectHeader===item?.name?'border-[#4F008A] text-[#4F008A] ':'text-[#555555]'} cursor-pointer text-[18px] font-[500] pb-[20px] border-b  pe-4`}>
             {item?.name}
           </div>
            )}
           
            <div className="text-[18px] w-[55%] font-[500] text-[#555555] pb-[20px] border-b">
              {""}
            </div>
          </div>
          {List?.find((item,i)=>item?.name===selectHeader)?.components}

        </div>
      </div>
    </div>
  );
}
