import React from 'react'
import { CSVLink, CSVDownload } from "react-csv";

export default function DownloadReport({
 data=[],
    children,
    filename='',
    headers
}) {

    // console.log(data,'datadata')
    // const headers = [
    //     { label: 'First Name', key: 'details.firstName' },
    //     { label: 'Last Name', key: 'details.lastName' },
    //     { label: 'Job', key: 'job' },
    //   ];
      
    //  const  data = [
    //     { details: { firstName: 'Ahmed', lastName: 'Tomi' }, job: 'manager'},
    //     { details: { firstName: 'John', lastName: 'Jones' }, job: 'developer'},
    //   ];
  return (
    <CSVLink data={[]} target="_blank" filename={filename}   headers={headers?headers:false}>
        {children}
    </CSVLink>
  )
}
