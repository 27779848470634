import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import EventsComp from "./EventsComp";
import { useGetEventQuery } from "../../store/Event/EventApi";

function Events() {
  const {data:EventData,isLoading } =useGetEventQuery();
  return (
    <div>
      <>
        <EventsComp 
        EventData={EventData?.data?.events}
        eventLoader={isLoading}
        />
      </>
    </div>
  );
}

export default Events;
