import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import MediaComp from "./Media";
import ModalComp from "../../Components/Ui/ModalComp";
import ButtonComp from "../../Components/Ui/Button";
import MediaModal from "./MediaModal";
import { useCreateMediaFolderMutation, useGetMediaQuery } from "../../store/Media/MediaApi";

export default function Media() {
  const [showModal, setShowModal] = useState(false);
  const  {data,isLoading} =useGetMediaQuery();
  return (
    <>
      {/* <ButtonComp onClick={() => setShowModal(true)} text={"Show"} /> */}
      <ModalComp
        handleClose={() => setShowModal(false)}
        show={showModal}
        bodyComponents={<MediaModal setShowModal={setShowModal} />}
      />
      <MediaComp  addMedia={()=>setShowModal(true)} Medias={data?.data?.folders} />
    </>
  );
}
