import React from "react";
import { StatusColor } from "../../../utils/helper";
import moment from "moment";

export default function ViewEvent({ singleEvent }) {
  console.log(singleEvent, "singleEvent");
  return (
    <div className="p-[16px]">
      <div className="flex justify-between items-center mb-[50px]">
        <div className="text-[#444444] text-[24px] font-bold font-3">
          {singleEvent?.name} #12345
        </div>
        <div className={`${StatusColor(singleEvent?.status)}`}>
          {singleEvent?.status}
        </div>
      </div>

      <div className="flex items-center gap-4  mb-[50px]">
        <div>
          <svg
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="55" height="55" rx="5.72917" fill="#F5F5F5" />
            <path
              d="M21.9141 30.293H23.776V32.1549H21.9141V30.293ZM26.569 30.293H28.431V32.1549H26.569V30.293ZM31.224 30.293H33.0859V32.1549H31.224V30.293Z"
              fill="#3DA84A"
            />
            <path
              d="M38.2064 39.6029V17.7246H34.0169V13.5352H20.9831V17.7246H16.7936V39.6029H13.5352V41.4648H41.4648V39.6029H38.2064ZM22.8451 15.3971H32.1549V24.707H22.8451V15.3971ZM36.3444 39.6029H28.431V35.8789H26.569V39.6029H18.6556V19.5866H20.9831V26.569H34.0169V19.5866H36.3444V39.6029Z"
              fill="#3DA84A"
            />
            <path
              d="M28.431 17.2588H26.569V19.1208H24.707V20.9827H26.569V22.8447H28.431V20.9827H30.293V19.1208H28.431V17.2588Z"
              fill="#3DA84A"
            />
          </svg>
        </div>
        <div className="">
          <div className="text-[20px] font-semibold">
            {singleEvent?.institution}
          </div>
          <div className="text-[14px] text-[#777777] font-normal">
            {singleEvent?.institution}
          </div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Event Type</div>
          <div className="text-[16px] font-medium text-black">
            Product Lunch
          </div>
        </div>

        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Event Title</div>
          <div className="text-[16px] font-medium text-black">{singleEvent?.name}</div>
        </div>

         <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Time</div>
          <div className="text-[16px] font-medium text-black">{singleEvent?.time}</div>
        </div>


        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Date</div>
          <div className="text-[16px] font-medium text-black">{moment(singleEvent?.date).format('LL')}</div>
        </div>


        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Department</div>
          <div className="text-[16px] font-medium text-black">{singleEvent?.department||"-"}</div>
        </div>


        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Med Rep</div>
          <div className="text-[16px] font-medium text-black">{singleEvent?.medRep||"-"}</div>
        </div>



       
      </div>
    </div>
  );
}
