import React from 'react'
import { FormDatedView } from '../../../../utils/helper';

export default function Plan({
  planData
}) {
  const ImageType = (key) => {
    switch (key) {
      case "checkIn":
        return <img src="/HCPList/tick.png" alt="" />;

      case "report":
        return <img src="/HCPList/note.png" alt="" />;

      default:
        return <img src="/HCPList/note.png" alt="" />;
    }
  };
  return (
    <div>
    <div>
     {planData?.loader &&[1,2,3,4]?.map((item,i)=> 
     <ActivitiesLoadingState key={i}/>
     )}
     {!planData?.loader &&planData?.data?.length<1&&<div className="text-center text-lg font-semibold">No Activities Added</div>}
       {!planData?.loader &&planData?.data?.map((item, i) => (
        <div className="flex justify-content-between align-items-center mb-[50px]">
        <div className="flex align-items-center">
          <div className="me-[15px]">
          {ImageType(item?.activityType)}
          </div>
          <div className="text-[16px] text-[#757575] font-[400]">
          {FormDatedView(item?.createdAt)}<br />
            <b className="text-[16px] text-[#292D32] font-[500]">
             Plan
            </b>{" "}
            <span className="text-[16px] text-[#757575] font-[400] me-[3px]">
              for {item?.callFocus||item?.reason}
            </span>
            <b className="text-[16px] text-[#292D32] font-[500]">
             {item?.activity}
            </b>
          </div>
        </div>
        <div className="">
          <img src="/HCPList/more.svg" alt="more icon" />
        </div>
      </div>
       ))}
      
  
     


   </div>
   </div>
  )
}
