import React from "react";
import Form from "react-bootstrap/Form";
import FormUi from "../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import ButtonComp from "../../Components/Ui/Button";
import { useCreateMediaFolderMutation } from "../../store/Media/MediaApi";
import { toast } from "react-toastify";
import { LanguageName } from "../../utils/helper";

export default function MediaModal({ setShowModal }) {
  const [createMediaFolder, { isLoading: CMFLoader }] =
    useCreateMediaFolderMutation();

  const formLabel = () => [
    {
      label: LanguageName("PN"),
      name: "name",
      placeholder: LanguageName("EnterProductName"),
      labelClassName: "text-[18px] mb-3",
    },
    {
      label: LanguageName("PC"),
      name: "category",
      placeholder: LanguageName("EnterProductComposition"),
      labelClassName: "text-[18px] mb-3",
    },
  ];
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
    formState: { errors, isValid },
    setError,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const handleCreateMedia = async (data) => {
    const response = await createMediaFolder(data);
    if (response.data) {
      toast.success(response?.message);
      setShowModal(false);
    }
    // console.log(response?.data)
  };
  return (
    <div className="container">
      {/* <div>Hello</div> */}
      <div className="text-[24px] text-[#292D32] font-[700] mb-[42px]">
        {LanguageName("CNPT")}
      </div>
      <div className="row gy-4">
        <FormUi formData={formLabel()} control={control} />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-2 2xl:gap-7.5 mt-5">
        <div className="">
          <ButtonComp
            onClick={() => setShowModal(falses)}
            text={LanguageName("CNC")}
            btnClassName={
              "w-100 border-[1px] border-[#D63434] py-[10px] rounded-lg text-[16px] text-[#D63434]"
            }
          />
        </div>
        <ButtonComp
          onClick={handleSubmit(handleCreateMedia)}
          loader={CMFLoader}
          text={LanguageName("CNPT")}
          // btnClassName={''}
          btnClassName={
            "w-100 bg-[#3DA84A] border-[1px] border-[#fff] py-[10px] rounded-lg text-[16px] text-[#fff]"
          }
        />
      </div>
    </div>
  );
}
