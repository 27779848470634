import React, { useState } from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HealthCarePractitioner from "./MedRepMain";
import { useGetHCPQuery } from "../../../store/Schedules/SchedulesApi";
import MedRepComp from "./MedRep";
import { useGetMedRepQuery } from "../../../store/MedRep/MedRepApi";
import OffCanvasComp from "../../../Components/Ui/OffCanvas";
import AddFieldRep from "./submodules/addFieldRep";
import { useSelector } from "react-redux";
import { selectAllCurrentUserData } from "../../../store/auth";
import { storage } from "../../../utils/helper";

function MedRepMain() {
  const getUserAll = useSelector(selectAllCurrentUserData);
  const companyId = storage["localStorage"].get("companyId")?._id;
  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  console.log(companyId, "getUserAllgetUserAll");
  const { data: HCPData, isLoading } = useGetMedRepQuery(companyIde, {
    skip: !companyIde,
  });
  const [showCavans, setShowCavans] = useState(false);

  return (
    <div>
      {/* <AdminLayout active={1}> */}
      <MedRepComp
        HCP={HCPData?.data?.medreps}
        hcpLoader={isLoading}
        setShowCavans={setShowCavans}
      />
      <OffCanvasComp
        title={"Field Reps"}
        show={showCavans}
        bodyComponent={
          <AddFieldRep
            // edit={edit}
            // refetch={refetch}
            onClose={() => setShowCavans(false)}
            setShowCavans={setShowCavans}
            // onNext={()=>{
            //   refetch();
            //   setShowCavans(false)
            // }}
          />
        }
        handleClose={() => {
          setShowCavans(false);
          // navigate(`/order_management`);
        }}
      />
      {/* </AdminLayout> */}
    </div>
  );
}

export default MedRepMain;
