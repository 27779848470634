import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const MediaApi = createApi({
  reducerPath: "MediaApi",
  baseQuery: baseQuery,
  tagTypes:['getMedia'],

  endpoints: (builder) => ({
   
   
    getMedia: builder.query({
      query: () => ({
        url: `/media/folders`,
        method: "GET",
      }),
      providesTags:()=>['getMedia']
    }),
    createMediaFolder: builder.mutation({
      query: (payload) => ({
        url: `/media/folders`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags:['getMedia']
    }),
    createSingleMediaFolder: builder.mutation({
      query: (payload) => ({
        url: `/media/folders/${payload?.id}/files`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags:['getMedia']
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
    getSingleMedia: builder.query({
      query: (id) => ({
        url: `/media/folders/${id}`,
        method: "GET",
      }),
      providesTags:()=>['getMedia']
    }),
  
  }),
});

export const {
useGetMediaQuery,
useCreateMediaFolderMutation,
useGetSingleMediaQuery,
useCreateSingleMediaFolderMutation

} = MediaApi;
