import React, { useMemo } from "react";
import {
  useCreatePlanMutation,
  useGetMedRepDetailsHCpQuery,
  useGetSinglePlanQuery,
} from "../../../../store/Plans/PlansApi";
import { useParams, useNavigate } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import { toast } from "react-toastify";
import { LanguageName, storage } from "../../../../utils/helper";
import NewFormUI from "../../../../Components/Ui/NewFormUi";
import { useForm } from "react-hook-form";
import AboutMedRep from "../submodules/AboutMedRep";

import Country from "../../../../country.json";
import { useEffect } from "react";
import useMultiDateSelect from "../../../../Components/Ui/UseMultiDateSelect";
import { useGetHCPQuery } from "../../../../store/Schedules/SchedulesApi";
import { ImageComponent } from "../../../../Components/Common/FallbackImage";
import ModalComp from "../../../../Components/Ui/ModalComp";

export default function AddPlan() {
  const companyIdReal = storage["localStorage"].get("companyId")?._id;
  // 64cbb8f1af9faf444f0cc5d4
  const [createPlan, { isLoading: HCPLoader }] = useCreatePlanMutation();
  const [showModal, setShowModal] = React.useState(false);
  const { data: HCPData, isLoading: loading } = useGetHCPQuery();
  // const { data: MedRepHCP, isLoading: loading } = useGetMedRepDetailsHCpQuery(
  //   companyIdReal,
  //   {
  //     skip: !companyIdReal,
  //   },
  // );
  const {
    datePickerComponent,
    selectedDates,
    firstMonthName,
    inactiveDays,
    days,
    callsPerDay,
  } = useMultiDateSelect();
  const { plan_id } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue, setError } = useForm({
    defaultValues: {
      category: "",
      email: "",
      lastName: "",
      phone: "",
      address: "",
      institutionName: "",
      country: "",
      state: "Oyo",
      image: "",
      companyId: "",
    },
  });
  const { data, isLoading } = useGetSinglePlanQuery(plan_id, {
    skip: !plan_id,
  });

  const Plan = data?.data?.plan;
  console.log(Plan, "PlanPlan");

  const [selectedHCP, setSelectedHCP] = React.useState(null);
  const [addNewPlan, setAddNewPlan] = React.useState(false);

  const savedHCPData = storage["localStorage"].get("planHcpEditData");

  useEffect(() => {
    setValue("category", [{ label: Plan?.category, value: Plan?.category }]);
    setValue("institutionName", Plan?.institutionName);
    setValue("institutionDepartment", Plan?.institutionDepartment);
    setValue("location", Plan?.location);
    setValue("status", Plan?.status);
  }, [Plan?._id]);

  useEffect(() => {
    console.log(HCPData, "hcps");
  }, [HCPData]);

  useEffect(() => {
    console.log(savedHCPData, "savedHCPData");
    console.log(selectedHCP, "selected");
    if (!addNewPlan && savedHCPData) {
      setSelectedHCP(savedHCPData);
    }
    if (addNewPlan) {
      setSelectedHCP(null);
    }
  }, [addNewPlan]);

  const transformedArray = useMemo(() => {
    return Country.map((item) => ({
      label: item.name, // replace with the actual property you want as label
      value: item.name,
      id: item?.id, // replace with the actual property you want as value
    }));
  }, [Country]);

  //   console.log(data,'datadata')
  // selected hcp

  const AddPlanForm = [
    {
      name: "HealthCarePractitioner",
      label: "Health care Practitioner",
      type: "select",
      label2: selectedHCP
        ? selectedHCP?.firstName + " " + selectedHCP?.lastName
        : "",
      value: selectedHCP ? selectedHCP?._id : "",
      placeholder: "Select Health Care Practitioner",
      options: HCPData?.data?.hcps?.map((item) => ({
        value: item?._id,
        label: item?.firstName + " " + item?.lastName,
        full: item,
      })),
    },
    {
      name: "departmint",
      label: "Department",
      type: "input",
      value: selectedHCP ? selectedHCP?.department : "",
      placeholder: "Select Department",
    },
    {
      name: "institution",
      label: "Institution",
      type: "input",
      placeholder: "Select Institution",
      value: selectedHCP ? selectedHCP?.institutionName : "",
    },
    {
      name: "data",
      label: "Select Date",
      type: "multiDate",
      component: datePickerComponent,
      placeholder: "",
    },
  ];

  const handleOnSubmit = async () => {
    // TODO: handle errors
    if (!selectedHCP) {
      return;
    }

    const plan = {
      plans: [
        {
          hcp: selectedHCP?._id,
          category: "HCP",
          location: selectedHCP.state,
          country: selectedHCP.country,
          company: selectedHCP.company,
          institutionName: selectedHCP.institutionName,
          institutionDepartment: selectedHCP.department,
          targets: {
            date: selectedDates[0],
            callsPerDay: callsPerDay,
            callsPerMonth: selectedDates.length,
            inactiveDays: inactiveDays,
          },
          selectedDates: selectedDates,
        },
      ],
    };

    console.log("finale plans", plan);

    // setLoading(true);
    try {
      await createPlan(plan);
      setShowModal(true);
      // setLoading(false);
    } catch (error) {
      console.log(error, "create plan error");
      // setLoading(false);
    }
  };

  // TODO: add the "go back" button

  return (
    <div className="px-3">
      <div className="flex  h-full justify-content-between align-items-center pb-[45px]">
        <div className="align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700]">
            {LanguageName("createNewPlan")}
          </div>
          <div className="text-[#000000] font-medium">
            Follow the prompt to add New Health Care Practitioners
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <ButtonComp
            // onClick={() => navigate("/plan/summary")}
            onClick={handleOnSubmit}
            loader={HCPLoader}
            className="text-white bg-[#3DA84A] rounded-[5px] font-[500] md:text-[16px] text-[14px] px-[10px] py-[10px] md:px-[37px] md:py-[14px]"
            text={LanguageName("proceedToSubmit")}
          />
        </div>
      </div>
      <div className="border"></div>

      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-4">
        <div className="md:col-8 md:mr-4 w-full">
          <div className="p-4 bg-white h-[100vh] border-r">
            <div className="flex justify-between items-center">
              <div className="w-[60%]">
                <NewFormUI
                  containerClassName="mb-4"
                  setSelectedHCP={setSelectedHCP}
                  wrapperClassName={"mb-8"}
                  formData={AddPlanForm}
                  control={control}
                />
              </div>
              <div className="w-[20%]">
                <img src="/icon-steps.png" />
              </div>
            </div>
            <div className="col-6" />
            <div className="flex align-items-center mt-4">
              <div className="flex align-items-center  border-1 rounded-[5px] border-[#A162F7] lg:mb-0 md:mb-0 mb-[1rem] px-3 py-2 lg:me-2 md:me-2 me-0">
                <ButtonComp
                  onClick={() => {
                    storage["localStorage"].set("planHcpEditData", selectedHCP);
                    toast.success("Draft saved Successfully...");
                  }}
                  className="text-[#A162F7] font-[500] text-[16px] "
                  text={"Save as draft"}
                />
              </div>
              <div className="flex ml-4 align-items-center rounded-[5px] bg-[#A162F7] border-1 border-[#A162F7] lg:mb-0 md:mb-0 mb-[1rem] px-3 py-2 lg:me-2 md:me-2 me-0">
                <ButtonComp
                  onClick={() => {
                    // storage["localStorage"].set("planHcpEditData", selectedHCP);
                    // toast.success("Draft saved Successfully...");
                    setAddNewPlan(true);
                    setSelectedHCP(null);
                  }}
                  className="text-[#ffffff] bg-[#A162F7] font-[500] text-[16px] "
                  text={"Add New Plan"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-4 w-full md:w-[500px]">
          {/* <AboutMedRep Data={Plan?.hcp} /> */}
          {firstMonthName ? (
            <div>
              <span className="bg-[#A162F71A] p-[10px] rounded-[8px] text-[24px]">
                {firstMonthName}
              </span>
              <span className="p-[10px] rounded-[8px] text-[24px]">
                {selectedHCP?.firstName} {selectedHCP?.lastName}
              </span>
              <p className="text-[16px] font-[500] mt-[20px] text-[#333333]">
                Days Selected
              </p>
              <div className="flex overflow-x-auto pb-4 items-center justify-content-start my-[20px]">
                {days?.map((item) => (
                  <span className="rounded-[8px] mr-4 border-[#4F008A1A] p-[20px] border">
                    {item}
                  </span>
                ))}
                {/* <span className="rounded-[8px] mr-4 border-[#4F008A1A] p-[20px] border"> */}
                {/*   <svg */}
                {/*     width="22" */}
                {/*     height="22" */}
                {/*     viewBox="0 0 22 22" */}
                {/*     fill="none" */}
                {/*     xmlns="http://www.w3.org/2000/svg" */}
                {/*   > */}
                {/*     <path */}
                {/*       d="M2.69141 5.38184H4.48538H18.8372" */}
                {/*       stroke="#F65E5E" */}
                {/*       stroke-width="1.61458" */}
                {/*       stroke-linecap="round" */}
                {/*       stroke-linejoin="round" */}
                {/*     /> */}
                {/*     <path */}
                {/*       d="M7.17631 5.38189V3.58792C7.17631 3.11213 7.36532 2.65582 7.70175 2.31939C8.03819 1.98295 8.49449 1.79395 8.97028 1.79395H12.5582C13.034 1.79395 13.4903 1.98295 13.8268 2.31939C14.1632 2.65582 14.3522 3.11213 14.3522 3.58792V5.38189M17.0432 5.38189V17.9397C17.0432 18.4155 16.8542 18.8718 16.5177 19.2082C16.1813 19.5447 15.725 19.7337 15.2492 19.7337H6.27932C5.80353 19.7337 5.34723 19.5447 5.01079 19.2082C4.67436 18.8718 4.48535 18.4155 4.48535 17.9397V5.38189H17.0432Z" */}
                {/*       stroke="#F65E5E" */}
                {/*       stroke-width="1.61458" */}
                {/*       stroke-linecap="round" */}
                {/*       stroke-linejoin="round" */}
                {/*     /> */}
                {/*     <path */}
                {/*       d="M8.96973 9.86694V15.2489" */}
                {/*       stroke="#F65E5E" */}
                {/*       stroke-width="1.61458" */}
                {/*       stroke-linecap="round" */}
                {/*       stroke-linejoin="round" */}
                {/*     /> */}
                {/*     <path */}
                {/*       d="M12.5576 9.86694V15.2489" */}
                {/*       stroke="#F65E5E" */}
                {/*       stroke-width="1.61458" */}
                {/*       stroke-linecap="round" */}
                {/*       stroke-linejoin="round" */}
                {/*     /> */}
                {/*   </svg> */}
                {/* </span> */}
              </div>
              <div>
                <div className="flex w-full mb-4">
                  <div className="rounded-l-[8px] w-full flex justify-center border-[#4F008A1A] p-[20px] border">
                    calls per day
                  </div>
                  <div className="rounded-r-[8px] border-[#4F008A1A] p-[20px] border">
                    {callsPerDay}
                  </div>
                </div>
                <div className="flex w-full mb-4">
                  <div className="rounded-l-[8px] w-full flex justify-center border-[#4F008A1A] p-[20px] border">
                    Calls per month
                  </div>
                  <div className="rounded-r-[8px] border-[#4F008A1A] p-[20px] border">
                    {selectedDates.length}
                  </div>
                </div>
                <div className="flex w-full mb-4">
                  <div className="rounded-l-[8px] w-full flex justify-center border-[#4F008A1A] p-[20px] border">
                    Inative Days in the Month
                  </div>
                  <div className="rounded-r-[8px] border-[#4F008A1A] p-[20px] border">
                    {inactiveDays}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-[50%] flex  flex-col justify-center items-center">
              <svg
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M75 137.5C109.5 137.5 137.5 109.5 137.5 75C137.5 40.5 109.5 12.5 75 12.5C40.5 12.5 12.5 40.5 12.5 75C12.5 109.5 40.5 137.5 75 137.5Z"
                  stroke="#CECDCE"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M118.127 31.25L30.6274 118.75"
                  stroke="#CECDCE"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-[#cecdce] mt-4 font-[500] text-[24px] font-[Instrument Sans]">
                No Date Added
              </p>
            </div>
          )}
        </div>
      </div>
      <ModalComp
        show={showModal}
        handleClose={() => setShowModal(false)}
        bodyComponents={
          <div className="flex flex-col items-center py-5">
            <div className="flex justify-center mb-4">
              <ImageComponent src="/icon/timer.svg" width={70} />
            </div>
            <div>
              <div className="text-center text-[#282728] text-[24px] font-semibold mb-3">
                Plan Created Successfully
              </div>
              <p className="text-center text-[#333333] text-[14px] self-center px-[15%] mb-4">
                Le client est très important, le client sera suivi par le
                client. Le bateau flatte, l'élite à eleifend pulvinar, ex ex
                ultris sem, un vestibulum dolor
              </p>
              <div className="flex w-full justify-center">
                <ButtonComp
                  className="bg-[#A162F71A] px-4 text-[#A162F7] font-[500] text-[18px] py-2 rounded-[5px]"
                  // onClick={handleResendPassword}
                  // loader={resendLoader}
                  onClick={() => navigate("/plan_overview")}
                  text={"Go Home"}
                />
                <div className="w-4" />
                <ButtonComp
                  className="text-white px-4 bg-[#A162F7] font-[500] text-[18px] py-2 rounded-[5px]"
                  // onClick={handleResendPassword}
                  onClick={() => navigate("/plan/summary")}
                  // loader={resendLoader}
                  text={"View Plans"}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
