import React from "react";
import { useGetPlansOverviewQuery } from "../../../../store/Plans/PlansApi";
import { useSelector } from "react-redux";
import { selectAllCurrentUserData } from "../../../../store/auth";
import { ProgressBar } from "react-bootstrap";

export default function Overview({ Data }) {
  return (
    <div>
      <div className="row">
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                Customer Universe
              </div>
              <br />
              <div className="text-[32px] text-[#060D1C] font-[600]">
                {Data?.customerCount}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                Territory Adds
              </div>
              <br />
              <div className="flex justify-between items-center">
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.territoriesCount?.current}
                </div>
                <div className="flex items-center bg-[#EFFDF9] rounded-[36px] py-1 px-2">
                  <div className="me-2">
                    <img src="/icon/arrow-up.png" alt="down" />
                  </div>
                  <div className="text-[16px] text-[#3DA84A] font-[700]">
                    {Data?.territoriesCount?.percentageDifference}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                Territory Drops
              </div>
              <br />
              <div className="flex justify-between items-center">
                <div className="text-[32px] text-[#060D1C] font-[600]">0</div>
                <div className="flex items-center bg-[#FDEFEF] rounded-[36px] py-1 px-2">
                  <div className="me-2">
                    <img src="/icon/arrow-down.png" alt="down" />
                  </div>
                  <div className="text-[16px] text-[#D63434] font-[700]">
                    +0%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className=" rounded-[10px] border border-[#E5E5EF] lg:mb-0 mb-[56px] p-[30px]">
            <div className="flex justify-between mb-[26px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                Top Visited Departments
              </div>
              <div className="">
                <img src="/Plan/link-icon.png" alt="" />
              </div>
            </div>
            {Data?.topVisitedDepartments
              ?.map((item, i) => {
                return (
                  <div
                    className={`flex flex-col ${i === 3 ? "" : "mb-[34px]"}`}
                  >
                    <div className="text-[16px] text-[#000000] font-[400] mb-[10px]">
                      {item?._id}
                    </div>
                    <div className="">
                      <ProgressBar now={item?.count * 10} />
                    </div>
                  </div>
                );
              })
              .slice(0, 4)}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="flex flex-col">
            <div className="flex flex-col   rounded-[10px] border border-[#E5E5EF] mb-[56px]">
              <div className="py-[30px] px-[30px]">
                <div className="text-[18px] text-[#000000] font-[600]">
                  Total Plans
                </div>
                <br />
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.totalPlans}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col   rounded-[10px] border border-[#E5E5EF] lg:mb-0 mb-[56px]">
            <div className="py-[30px] px-[30px]">
              <div className="text-[18px] text-[#000000] font-[600]">
                Completed Plans
              </div>
              <br />
              <div className="text-[32px] text-[#060D1C] font-[600]">
                {Data?.completedPlans}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="flex flex-col">
            <div className="flex flex-col   rounded-[10px] border border-[#E5E5EF] mb-[56px]">
              <div className="py-[30px] px-[30px]">
                <div className="text-[18px] text-[#000000] font-[600]">
                  Pending Plans
                </div>
                <br />
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.pendingPlans}
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col   rounded-[10px] border border-[#E5E5EF] lg:mb-0 mb-[56px]"> */}
            {/*   <div className="py-[30px] px-[30px]"> */}
            {/*     <div className="text-[18px] text-[#000000] font-[600]"> */}
            {/*       Out of Plan Visit */}
            {/*     </div> */}
            {/*     <br /> */}
            {/*     <div className="text-[32px] text-[#060D1C] font-[600]">12</div> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
